import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type {
  CancelCurrentTaskFailurePayload,
  CreateTaskRequestPayload,
  CreateTaskSuccessPayload,
  GotActiveTasksPayload,
  Task,
} from '../../types/task.types';

export interface TaskState {
  currentTaskId: string;
  cancellingCurrentTask: boolean;
  /**
   * The active tasks for the current session.
   *
   * `undefined`, if there is no current session.
   */
  activeTasks?: Task[];
}

export const initialState: TaskState = {
  currentTaskId: '',
  cancellingCurrentTask: false,
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    createTaskRequest: {
      reducer: () => {},
      prepare: (payload: CreateTaskRequestPayload) => ({ payload }),
    },
    createTaskFailure: () => {},
    createTaskSuccess: (state: TaskState, { payload }: PayloadAction<CreateTaskSuccessPayload>) => {
      state.currentTaskId = payload.newTaskId;
    },
    gotActiveTasks: (state: TaskState, { payload }: PayloadAction<GotActiveTasksPayload>) => {
      state.activeTasks = payload.activeTasks;
    },
    reset() {
      return initialState;
    },
    cancelCurrentTask: (state: TaskState) => {
      // set the cancellingCurrentTask flag
      state.cancellingCurrentTask = true;
    },
    cancelCurrentTaskFailure: {
      reducer: (state: TaskState) => {
        // reset the cancellingCurrentTask flag
        state.cancellingCurrentTask = initialState.cancellingCurrentTask;
      },
      prepare: (payload: CancelCurrentTaskFailurePayload) => ({ payload }),
    },
    cancelCurrentTaskSuccess: (state: TaskState) => {
      // get current task id
      const id = state.currentTaskId;

      // get active tasks
      const tasks = state.activeTasks;

      // remove the current task from the active tasks
      if (tasks !== undefined) state.activeTasks = tasks.filter((t) => t.id !== id);

      // reset the current task id
      state.currentTaskId = initialState.currentTaskId;

      // reset the cancellingCurrentTask flag
      state.cancellingCurrentTask = initialState.cancellingCurrentTask;
    },
  },
});

export const {
  createTaskRequest,
  createTaskFailure,
  createTaskSuccess,
  gotActiveTasks,
  reset,
  cancelCurrentTask,
  cancelCurrentTaskFailure,
  cancelCurrentTaskSuccess,
} = taskSlice.actions;

export default taskSlice.reducer;
