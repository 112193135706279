import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { ChartSpec } from 'translate_dc_to_echart';
import { AVA_IDS } from '../../../../../constants/dataAssistant';
import { openPopOutModal } from '../../../../../store/actions/popout.actions';
import { selectSession } from '../../../../../store/selectors/session.selector';
import { Renderers } from '../../../../../types/render.types';
import { useChartRender } from '../../../../../utils/hooks/useChartRender.hook';
import ErrorBoundary from '../../../../common/ErrorBoundary/ErrorBoundary';
import { ScreenSizeContext } from '../../../../DataAssistant/DataAssistant';
import DCPlotV2 from '../../../../DisplayPanel/Charts/dcplotV2/DCPlotV2';
import DCPlotV2ErrorBoundary from '../../../../DisplayPanel/Charts/dcplotV2/DCPlotV2ErrorBoundary';
import './ChartMessage.scss';

const MIN_CHART_WIDTH = 500;

interface ChartMessageProps {
  externalName?: string;
  id: string;
  message: { [key: string]: any };
  objectId: string;
  inView: boolean;
  parentObjectId?: string;
}

const ChartMessage: React.FC<ChartMessageProps> = ({
  externalName = '',
  id,
  message,
  objectId,
  inView,
  parentObjectId,
}) => {
  const dispatch = useDispatch();
  const isSmallScreen = useContext(ScreenSizeContext);
  const sessionId = useSelector(selectSession);

  const handleChartClick = () => {
    if (!isSmallScreen) return;
    dispatch(
      openPopOutModal({
        chartObjectId: parentObjectId || objectId,
        isPopoutEditable: false,
      }),
    );
  };

  // Lift the dimensions into state so we can pass them to the hook.
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  // Call the useChartRender hook with the dimensions and any required render parameters.
  const {
    render: chartRender,
    error,
    isLoading,
  } = useChartRender({
    inView, // Only fetch the render if the component is in view
    sessionId,
    insightsBoardId: message.insightsBoardId,
    publicationId: message.publicationId,
    workspaceUuid: message.workspaceUuid,
    renderer: message.renderer || Renderers.SPEC,
    dcChartId: objectId,
  });

  return (
    <ReactResizeDetector
      handleWidth
      handleHeight
      onResize={(w, h) => {
        if (w && h) setDimensions({ width: w, height: h });
      }}
    >
      {({ targetRef }) => (
        <div
          className={classNames('ChartMessage', { SmallScreenSize: isSmallScreen })}
          data-testid={AVA_IDS.MSG_CHART_WRAPPER(id)}
          key={objectId}
          ref={targetRef as React.RefObject<HTMLDivElement>}
          onClick={handleChartClick}
        >
          {inView ? (
            error ? (
              <ErrorBoundary type="echart" hasError error={error as Error} />
            ) : dimensions.width > 0 && chartRender ? (
              <div
                className={classNames('ChartMessage-PlotContainer', {
                  SmallScreenSize: isSmallScreen,
                })}
                style={
                  dimensions.width >= MIN_CHART_WIDTH
                    ? { width: '100%', height: '100%' }
                    : {
                        width: `${(MIN_CHART_WIDTH / dimensions.width) * 100}%`,
                        aspectRatio: 'inherit',
                        transform: `scale(${dimensions.width / MIN_CHART_WIDTH})`,
                        transformOrigin: 'top left',
                      }
                }
              >
                <DCPlotV2ErrorBoundary
                  dcSpec={chartRender as ChartSpec}
                  isLoading={isLoading}
                  objectId={objectId}
                  isExportingCopy={false}
                  setError={() => {}}
                >
                  <DCPlotV2
                    width={dimensions.width}
                    height={dimensions.height}
                    objectId={objectId}
                    externalName={externalName}
                    dcSpec={chartRender as ChartSpec}
                    caption=""
                    setCaption={() => {}}
                    chartEditingMode={false}
                    openCaptionAlert={() => {}}
                    showModalPng={false}
                    updateModalPng={() => {}}
                    keepChanges={false}
                    insightsBoardId={undefined}
                    isPanMode={false}
                    isPopOutChart={false}
                    isFatTable={false}
                    responsive
                  />
                </DCPlotV2ErrorBoundary>
              </div>
            ) : (
              <Skeleton variant="rounded" width="100%" height="100%" />
            )
          ) : (
            <Skeleton variant="rounded" width="100%" height="100%" />
          )}
        </div>
      )}
    </ReactResizeDetector>
  );
};

export default ChartMessage;
