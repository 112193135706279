/* eslint-disable class-methods-use-this */
import Status from 'http-status-codes';
import { ErrorDetails } from '../../../types/errorCodes/errorCodes.types';
import { BaseErrorHandler } from './BaseErrorHandler';

export class TaskAlreadyStartedError extends Error {}

export class TaskAlreadyStartedErrorHandler extends BaseErrorHandler {
  canHandle(errorDetails?: ErrorDetails): boolean {
    return errorDetails?.status === Status.CONFLICT;
  }

  handle(): void {
    throw new TaskAlreadyStartedError();
  }
}
