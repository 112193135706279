import {
  all,
  call,
  cancelled,
  delay,
  getContext,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { CHART_DESTINATIONS } from '../../components/DisplayPanel/constants';
import { SAMPLE_TRIAL_COUNT } from '../../constants';
import { API_SERVICES } from '../../constants/api';
import { SHARE_TO_FACEBOOK_WORKPLACE_URL } from '../../constants/external_urls';
import {
  MODIFY_CHART_REQUEST,
  SAMPLE_SESSIONLESS_DATASET_REQUEST,
  SHARE_CHART_REQUEST,
  modifyChartFailure,
  modifyChartSuccess,
  sampleSessionlessDatasetFailure,
  sampleSessionlessDatasetSuccess,
  shareChartFailure,
  shareChartSuccess,
} from '../actions/chart.actions';
import { sampleDatasetRequest } from '../actions/dataset.actions';
import { selectIsReplaying } from '../selectors/context.selector';
import { selectSession } from '../selectors/session.selector';
import { getDescriptionWorker } from '../slices/pipelinerDatasets/sagas';
import { getDescription } from '../slices/pipelinerDatasets/slice';
import { selectAccessToken, selectSessionlessDatasetStorage } from './selectors';
import { callWithPolling } from './utils/saga_utils';

export function* shareChartWorker({ type, ...props }) {
  try {
    const { objectId, destination } = props;
    const accessToken = yield select(selectAccessToken);
    const sessionId = yield select(selectSession);

    const chartService = yield getContext(API_SERVICES.CHART);
    const response = yield call(chartService.shareChart, {
      accessToken,
      sessionId,
      objectId,
      destination,
    });

    if (response && response.data && 'error' in response.data) {
      const { error } = response.data;
      yield put(shareChartFailure({ error }));
    }

    let { link } = response.data;

    const isReplaying = yield select(selectIsReplaying);

    // Don't copy anything to the clipboard if replaying a workflow
    if (!isReplaying) {
      // Copy the chart link to the clipboard
      // copied from https://codepen.io/dtschust/pen/WGwdVN?editors=0011
      const textField = document.createElement('textarea');
      textField.innerText = `${window.location.protocol}//${window.location.host}${link}`;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();

      // Open the link if sharing to a workplace
      let params = new URLSearchParams(link);
      if (params.has('destination')) {
        const chartDestination = params.get('destination');
        switch (chartDestination) {
          case CHART_DESTINATIONS.Workplace:
            params = `${window.location.protocol}//${window.location.host + link}`;
            link = SHARE_TO_FACEBOOK_WORKPLACE_URL + encodeURIComponent(params);
            break;
          default:
            break;
        }
        yield call(window.open, link);
      }
    }

    yield put(shareChartSuccess({ link }));
  } catch (error) {
    yield put(shareChartFailure({ error }));
  }
}

/**
 * Modify a chart spec
 */
export function* modifyChartWorker({
  aggregate,
  bins,
  caption,
  callback,
  dataSampleLimit,
  insightsBoardId,
  presentation,
  publicationId,
  series,
  transforms,
}) {
  try {
    const accessToken = yield select(selectAccessToken);
    const chartService = yield getContext(API_SERVICES.CHART);
    yield call(
      chartService.modifyChart,
      accessToken,
      aggregate,
      bins,
      caption,
      dataSampleLimit,
      insightsBoardId,
      presentation,
      publicationId,
      series,
      transforms,
    );
    yield put(modifyChartSuccess());
    if (callback) yield call(callback);
  } catch (error) {
    yield put(modifyChartFailure({ error }));
  }
}

export function* sampleSessionlessDatasetWorker({
  callback,
  insightsBoardId,
  isTable,
  numRows,
  referenceString,
  pipelinerDatasetId,
  publicationId,
  workspaceUuid,
}) {
  const datasetStorage = yield select(selectSessionlessDatasetStorage);

  const currentData = { ...datasetStorage[referenceString] };
  const currRowNum = currentData?.rows?.length ?? 0;

  // Determine if we already have the full dataset in the reducer state
  const totalRowCount = currentData?.totalRowCount;
  const hasFullDataset = totalRowCount && currRowNum >= totalRowCount;

  // Determine the number of rows to display for tables
  const tableSampleRowCount = numRows === undefined ? totalRowCount : numRows;

  // If we already the whole dataset or enough data, then we don't need to request
  if (hasFullDataset || currRowNum >= parseInt(numRows, 10)) {
    yield put(
      sampleSessionlessDatasetSuccess({
        isTable,
        pipelinerDatasetId,
        result: currentData,
        ...(isTable && { tableSampleRowCount }),
      }),
    );
    if (callback) callback();
    return;
  }

  // Get an abortController to cancel the api call
  const abortController = new AbortController();

  try {
    // fetch new data
    const accessToken = yield select(selectAccessToken);

    // get dataset service
    const datasetService = yield getContext(API_SERVICES.DATASET);

    // declare claim
    /**
     * @type {import('../../types/dataset.types').PipelinerDatasetClaim | null}
     */
    let claim = null;

    if (insightsBoardId) {
      claim = { kind: 'insightsBoard', insightsBoardId };
    } else if (publicationId) {
      claim = { kind: 'publication', publicationId };
    } else if (workspaceUuid) {
      claim = { kind: 'workspace', workspaceUuid };
    } else {
      throw new Error('missing claim');
    }

    // get data and description
    const { dataResponse, description } = yield all({
      dataResponse: callWithPolling({
        accessToken,
        fn: datasetService.retrievePipelinerDataset,
        signal: abortController.signal,
        args: { numRows, pipelinerDatasetId, insightsBoardId, publicationId, workspaceUuid },
        retryOpts: { enabled: true },
      }),
      description: call(
        getDescriptionWorker,
        getDescription({
          id: pipelinerDatasetId,
          claim,
          params: { totalRowCount: true }, // only request total row count
        }),
      ),
    });

    // get data
    const { data } = dataResponse;

    // set total row count (prefer description, then data, then current value)
    data.totalRowCount = description?.totalRowCount ?? data.totalRowCount ?? totalRowCount;

    yield put(
      sampleSessionlessDatasetSuccess({
        isTable,
        pipelinerDatasetId,
        result: data,
        ...(isTable && { tableSampleRowCount }),
      }),
    );
    if (callback) callback();
  } catch (error) {
    // Get the sampling trial count from the currentData
    const trialCount = isTable
      ? currentData?.tableSamplingTrialCount ?? 0
      : currentData?.samplingTrialCount ?? 0;

    // Retry if we haven't reached the max number of trials
    if (trialCount < SAMPLE_TRIAL_COUNT) {
      yield put(sampleSessionlessDatasetFailure({ isTable, pipelinerDatasetId }));
      yield delay(1000);
      yield put(
        sampleDatasetRequest({
          callback,
          insightsBoardId,
          isTable,
          numRows,
          pipelinerDatasetId,
          publicationId,
        }),
      );
    } else {
      yield put(sampleSessionlessDatasetFailure({ error, isTable, pipelinerDatasetId }));
    }
  } finally {
    if (yield cancelled()) {
      abortController.abort('Operation canceled');
    }
  }
}

export default function* () {
  yield takeEvery(SHARE_CHART_REQUEST, shareChartWorker);
  yield takeEvery(MODIFY_CHART_REQUEST, modifyChartWorker);
  yield takeEvery(SAMPLE_SESSIONLESS_DATASET_REQUEST, sampleSessionlessDatasetWorker);
}
