import { PayloadAction } from '@reduxjs/toolkit';
import { ActionPattern, fork, ForkEffect } from 'redux-saga/effects';
import { cancel, SagaGenerator, take } from 'typed-redux-saga';
import { ChartRenderParams } from '../../../types/render.types';

type Task = { overwrite?: boolean; task: any };
type TaskRecord = Record<string, Task>;

/** Timeout for collecting & grouping render requests */
export const RESET_TIMEOUT = 2000;

/**
 * Custom effect for selecting chart render requests.
 * If we get multiple requests at once, only submit one for each unique render request.
 *
 * Use cases:
 *   - Multiple components requesting the same chart render
 *   - Chart initialization requesting the same render multiple times
 *
 * Refer to an example custom takeLatest() implementation for more information:
 * https://redux-saga.js.org/docs/api/#takelatestpattern-saga-args
 *
 * @param pattern The action pattern
 * @param worker The saga worker that we're calling with this effect
 * @param args The arguments that we called the action with
 */
export function takeEachRender<P extends ActionPattern>(
  pattern: P,
  worker: (action: PayloadAction<ChartRenderParams>) => any,
  ...args: any
): ForkEffect {
  return fork(function* (): SagaGenerator<never, any> {
    let existingTasks: TaskRecord = {};

    // Reset the tasks 2s after the first request
    const startTasksResetTimer = () => {
      if (Object.keys(existingTasks).length === 0) {
        setTimeout(() => {
          existingTasks = {};
        }, RESET_TIMEOUT);
      }
    };

    while (true) {
      const action: PayloadAction<ChartRenderParams> = yield take(pattern);
      const { dcChartId, renderer, width, height, overwrite = false } = action.payload;

      // Unique identifier for render requests
      const id = `${dcChartId}:${renderer}:${width}:${height}`;

      // Previous task information
      const prevTask = existingTasks[id];

      if (!prevTask) {
        // If the request is for a new render, fork it
        startTasksResetTimer();
        const task = yield fork(worker, ...args.concat(action));
        existingTasks[id] = { overwrite, task };
      } else if (overwrite) {
        // If the request is overwriting a previous request, cancel the previous task
        yield cancel(prevTask.task);
        const task = yield fork(worker, ...args.concat(action));
        existingTasks[id] = { overwrite, task };
      }

      // If the request is not overwriting a previous request, do nothing
    }
  });
}
