// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'sql-formatter';
import { SQL_KEYWORDS } from './sql.keywords';

export type SQLQueryPreview = {
  query: string;
  error: Error | null;
};

/**
 * Convert a SQL query to a formatted string with HTML tags for syntax highlighting.
 * It expects to have "sql-keyword" class defined in the CSS.
 * @param sql - The SQL query to format.
 * @returns The formatted SQL query.
 */
export const formatSQLQueryPreview = (query: string): SQLQueryPreview => {
  let highlighted = query;
  let error = null;
  // TODO - instead of manual check, we need to get the dialect after #51193 from the /get_sql endpoint
  // Regex to capture the entire read_csv(...) call that contains the DuckDB-specific syntax.
  const duckDbRegex = /read_csv\([\s\S]*?columns=\{[\s\S]*?\}[\s\S]*?\)/;
  const match = query.match(duckDbRegex);
  if (!match) {
    try {
      highlighted = format(String(query), {
        language: 'sql', // TODO - Need to use the same dialect as in the backend - #51193
      });
    } catch (e) {
      error = e instanceof Error ? e : new Error(String(e));
    }
  }

  SQL_KEYWORDS.forEach((keyword) => {
    // This pattern matches keywords only if they're "standalone":
    //   (^|[()\s])  -> either start of string OR '(' OR ')' OR whitespace
    //   (?=$|[()\s]) -> followed by end of string OR '(' OR ')' OR whitespace
    const pattern = new RegExp(`(^|[()\\s])(${keyword})(?=$|[()\\s])`, 'gi');

    highlighted = highlighted.replace(pattern, (_match, prefix, capturedKeyword) => {
      // Put the prefix (if any) back, then wrap the keyword in <span>.
      return `${prefix}<span class="sql-keyword">${capturedKeyword}</span>`;
    });
  });
  return { query: highlighted, error };
};
