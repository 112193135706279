import { ChartData } from 'api/chartspace.api';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleSpecVersion } from 'translate_dc_to_echart';
import { sampleDatasetRequest } from '../../../../store/actions/dataset.actions';
import {
  selectSessionDatasetStorage,
  selectSessionlessDatasetStorage,
} from '../../../../store/sagas/selectors';
import {
  DateFilterCondition,
  FilterArray,
  FilterCondition,
  PivotColumnValueMap,
  PivotDataItem,
  PivotSpec,
} from '../../../../types/pivotTable.types';
import { useDataFromStorage } from '../../../ChartData/dataUtils';
import { PIVOT_COLUMN_DEFAULT_WIDTH } from './constants';

/**
 * Calculates the total summary for a specific column in the sample data.
 * @param sampleData - The sample data to analyze.
 * @param summaryColumn - The column to summarize.
 * @param summaryType - The type of summary to calculate.
 * @returns An array containing the calculated total summary.
 */
export function getTotalSummary(
  sampleData: ChartData,
  summaryColumn: string[],
  summaryType: string,
): number[] {
  if (!sampleData.columns) {
    return [0];
  }
  const summaryColumnName = summaryColumn[0];
  // Find the index of the summary column in the sampleData.columns array
  const summaryColumnIndex = sampleData.columns.findIndex((col) => col.name === summaryColumnName);

  if (summaryColumnIndex === -1) {
    throw new Error(`Summary column "${summaryColumnName}" not found`);
  }

  // Find the row that matches the summary type and extract the summary value
  const totalSummaryRow = sampleData.rows?.find((row) =>
    row.every((value, index) => index === summaryColumnIndex || value === summaryType),
  );

  if (totalSummaryRow) {
    const totalSummary = totalSummaryRow[summaryColumnIndex];
    // Round the total summary if it's a number
    if (typeof totalSummary === 'number') {
      return [Number(totalSummary.toFixed(3))];
    }
    return [totalSummary];
  }
  return [0];
}

/**
 * Gets the indices of rows where the specified column matches the filter value.
 * @param filterColumn - The column to filter on.
 * @param filterValue - The value to filter for.
 * @param sampleData - The sample data to search.
 * @returns A set of indices where the filter condition is met.
 */
export function getIndices(
  filterColumn: string,
  filterValue: FilterArray | FilterCondition | DateFilterCondition | string | number,
  sampleData: ChartData,
): Set<number> {
  const columnIndex = sampleData.columns?.findIndex((col) => col.name === filterColumn);

  if (columnIndex === undefined || columnIndex === -1) {
    throw new Error(`Column "${filterColumn}" not found in the data`);
  }

  // Find all row indices where the filter condition is met
  return new Set(
    sampleData.rows?.reduce((indices, row, index) => {
      if (row[columnIndex] === filterValue) {
        indices.push(index);
      }
      return indices;
    }, [] as number[]),
  );
}

/**
 * Parses the filter array and applies it to the sample data.
 * @param filters - The array of filters to apply.
 * @param sampleData - The sample data to filter.
 * @returns A tuple containing the set of filtered indices
 *          and the filtered columns.
 */
export function parseFilter(filters: FilterArray, sampleData: ChartData): [Set<number>, string[]] {
  const stack = [...filters];
  let results = new Set<number>();
  const filterColumns: string[] = [];
  let addition = true;

  while (stack.length > 0) {
    const item = stack.pop();
    // Handle different types of filter elements (and/or operators, nested filters, and actual filters)
    if (typeof item === 'string' && item === 'and') {
      addition = false;
    } else if (typeof item === 'string' && item === 'or') {
      addition = true;
    } else if (Array.isArray(item) && item.length > 0 && Array.isArray(item[0])) {
      // Handle nested filters
      // In case of nested filters, add the child filter to the stack
      for (const t of item) {
        if (t !== undefined) {
          stack.push(t);
        }
      }
    } else if (item && Array.isArray(item) && item.length >= 3) {
      // Apply individual filter
      // In case of a filter, get the indices of the filtered data
      const filterColumn = String(item[0]);
      const filterValue = item[2];
      const index = getIndices(filterColumn, filterValue, sampleData);
      filterColumns.push(filterColumn);
      if (results.size === 0) {
        // If results is empty, initialize it with the current index
        results = new Set(index);
      } else if (addition) {
        // In case of 'or' operation, union the indices
        for (const i of index) {
          results.add(i);
        }
      } else {
        // In case of 'and' operation, intersect the indices
        results = new Set([...results].filter((i) => index.has(i)));
      }
    }
  }

  return [results, filterColumns];
}

/**
 * Appends a summary value to a nested object structure.
 * @param results - The object to append the summary to.
 * @param columnPath - The path in the object to append the summary.
 * @param value - The summary value to append.
 */
export function appendSummary(
  results: PivotColumnValueMap,
  columnPath: (string | number)[],
  value: number,
): void {
  let temp: PivotColumnValueMap = results;
  for (let i = 0; i < columnPath.length; i++) {
    const col = String(columnPath[i]);
    if (!(col in temp)) {
      temp[col] = {};
    }
    if (i === columnPath.length - 1) {
      temp[col] = { summary: value };
    } else {
      temp = temp[col] as PivotColumnValueMap;
    }
  }
}

/**
 * Formats the output of the pivot table data.
 * @param pivotMap - The pivot map to format.
 * @param key - The current key being processed.
 * @param summaryType - The type of summary being calculated.
 * @returns The formatted output.
 */
export function formatOutput(
  pivotMap: PivotColumnValueMap,
  key: string,
  summaryType: string,
): PivotDataItem {
  const value = pivotMap[key];

  if (typeof value !== 'object' || value === null) {
    return { key, summary: [Number(value)] };
  }

  if ('summary' in value) {
    return { key, summary: [Number(value.summary) ?? undefined] };
  }

  const items: PivotDataItem[] = [];
  let parentSummary: number | null = null;

  for (const k in value) {
    if (Object.prototype.hasOwnProperty.call(value, k)) {
      const currentValue = value[k];
      if (k === summaryType && typeof currentValue === 'object' && 'summary' in currentValue) {
        parentSummary = currentValue.summary as number;
      } else {
        const item = formatOutput(value, k, summaryType);
        items.push(item);
      }
    }
  }

  return {
    key,
    items: items.length > 0 ? items : undefined,
    summary: parentSummary !== null ? [parentSummary] : [],
  };
}

/**
 * Filters and selects rows from the sample data based on specified columns and summary type.
 * @param sampleData - The sample data to process.
 * @param remainingColumns - Columns to filter on.
 * @param columns - Columns to select.
 * @param summaryType - The type of summary being calculated.
 * @returns The filtered and selected rows.
 */
export function filterAndSelectRows(
  sampleData: ChartData,
  remainingColumns: string[],
  columns: string[],
  summaryType: string,
): (string | number)[][] {
  // Filter rows based on remaining columns and summary type
  const filteredRows = sampleData.rows?.filter((row) =>
    remainingColumns.every((col) => {
      if (!sampleData.columns) {
        return false;
      }
      const colIndex = sampleData.columns.findIndex((c) => c.name === col);
      return row[colIndex] === summaryType;
    }),
  );
  // Select specified columns from filtered rows
  const columnIndices = columns.map((col) => sampleData.columns?.findIndex((c) => c.name === col));

  return filteredRows?.map((row) => columnIndices.map((index) => row[index as number])) ?? [];
}

/**
 * Creates a mapping of column values to their corresponding summaries.
 * @param filteredRows - The filtered rows to process.
 * @returns An object representing the column value mapping.
 */
export function createColumnValueMapping(filteredRows: (string | number)[][]): PivotColumnValueMap {
  const results: PivotColumnValueMap = {};
  for (const row of filteredRows) {
    const value = row[0];
    const columnPath = row.slice(1);
    appendSummary(results, columnPath, Number(value));
  }
  return results;
}

/**
 * Formats the results of the pivot table calculation.
 * @param results - The raw results to format.
 * @param summaryType - The type of summary being calculated.
 * @returns The formatted results.
 */
export function formatResults(results: PivotColumnValueMap, summaryType: string): PivotDataItem[] {
  const formattedData: PivotDataItem[] = [];
  for (const key in results) {
    if (key !== summaryType) {
      formattedData.push(formatOutput(results, key, summaryType));
    }
  }
  return formattedData;
}

/**
 * Filters and processes the cube data for the pivot table.
 * @param sampleData - The sample data to process.
 * @param aggColumnName - The aggregation column names.
 * @param groupColumns - The grouping column names.
 * @param summaryType - The type of summary being calculated.
 * @param filteredColumns - The columns that have been filtered.
 * @returns The processed cube data.
 */
export function filterCube(
  sampleData: ChartData,
  aggColumnName: string[],
  groupColumns: string[] = [],
  summaryType: string = '',
  filteredColumns: string[] = [],
): PivotDataItem[] {
  // Determine columns to process and filter
  const columns = [...aggColumnName, ...groupColumns];
  const remainingColumns =
    sampleData.columns
      ?.map((col) => col.name)
      .filter((col) => !columns.includes(col) && !filteredColumns.includes(col)) ?? [];

  // Filter and select rows, then create column value mapping
  const filteredRows = filterAndSelectRows(sampleData, remainingColumns, columns, summaryType);
  const results = createColumnValueMapping(filteredRows);
  return formatResults(results, summaryType);
}

/**
 * Applies the pivot specification to filter and process the table data.
 * @param sampleData - The sample data to process.
 * @param pivotSpec - The pivot specification to apply.
 * @returns The filtered and processed table data.
 */
export function filterTable(
  sampleData: ChartData,
  pivotSpec: PivotSpec,
): { summary?: number[]; data: PivotDataItem[] } {
  const data: { summary?: number[]; data: PivotDataItem[] } = { data: [] };
  const summaryType = pivotSpec.dcSummaryTpe;
  const groupColumns = pivotSpec.group.map((col) => col.selector);
  const summaryColumn = pivotSpec.groupSummary.map((col) => col.selector);

  if (pivotSpec.totalSummary !== null) {
    data.summary = getTotalSummary(sampleData, summaryColumn, summaryType);
  }

  // Apply filters if specified
  let filteredColumns: string[] = [];
  if (pivotSpec.filter) {
    const [indices, filterCols] = parseFilter(pivotSpec.filter, sampleData);
    sampleData = {
      ...sampleData,
      rows: sampleData.rows?.filter((_, index) => indices.has(index)),
    };
    filteredColumns = filterCols;
  }

  // Remove rows with null values
  const dataWithoutNulls = {
    ...sampleData,
    // ToDo: This operation is not efficient, we should find a better way to handle this
    rows: sampleData.rows?.filter((row) => row.every((cell) => cell !== null)),
  };

  // Handle the row value formatting per spec_version
  const formattedData = handleSpecVersion(dataWithoutNulls, dataWithoutNulls.spec_version);

  // Filter & process the cube data
  data.data = filterCube(formattedData, summaryColumn, groupColumns, summaryType, filteredColumns);

  return data;
}

/**
 * Calculates the maximum data width in the sample data.
 * @param sampleData - The sample data to analyze.
 * @returns The maximum data width.
 */
export function calculateMaxDataWidth(sampleData: ChartData): number {
  if (!sampleData || !sampleData.rows || sampleData.rows.length === 0) {
    return 0;
  }

  const maxWidthInRows = Math.max(
    ...sampleData.rows.map((row) => (row.length > 0 ? String(row[0]).length : 0)),
  );

  const maxWidthInColumns = Math.max(...(sampleData.columns?.map((col) => col.name.length) ?? []));

  return Math.max(maxWidthInRows, maxWidthInColumns);
}

interface UsePivotDataProps {
  pipelinerDatasetId: string;
  insightsBoardId: string;
  publicationId: string;
}

/**
 * Custom hook for managing pivot data.
 * @param accessToken - The access token for API requests.
 * @param pipelinerDatasetId - The ID of the pipeliner dataset.
 * @returns An object containing pivot data management functions and state.
 */
export const usePivotData = ({
  pipelinerDatasetId,
  insightsBoardId,
  publicationId,
}: UsePivotDataProps) => {
  const dispatch = useDispatch();

  const datasetStorage = useSelector(selectSessionDatasetStorage);
  const sessionlessDatasetStorage = useSelector(selectSessionlessDatasetStorage);

  const fetchDataFromAPI = useCallback(() => {
    dispatch(
      sampleDatasetRequest({
        computeSpec: {},
        dcChartId: undefined,
        insightsBoardId,
        isTable: false,
        numRows: undefined, // Fetch all rows
        pipelinerDatasetId,
        publicationId,
      }),
    );
  }, [dispatch, pipelinerDatasetId, insightsBoardId, publicationId]);

  useEffect(() => {
    fetchDataFromAPI();
  }, [fetchDataFromAPI]);

  const {
    data: pivotData,
    isLoading,
    error,
    isFailed,
  } = useDataFromStorage({
    dcChartId: undefined,
    computeSpec: {},
    datasetStorage: insightsBoardId ? sessionlessDatasetStorage : datasetStorage,
    hasMessageData: false,
    isTable: false,
    pipelinerDatasetId,
    usedCompute: false,
  });

  const fetchSampleData = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (pivotData && pivotData.rows && !isLoading && !error) {
        resolve(pivotData);
      } else if (pivotData && error) {
        reject(error);
      } else if (pivotData && !isLoading) {
        // Implement a polling mechanism to handle the pivot library's initial data request
        // This ensures data is available when the library first asks for it, preventing initialization errors
        const checkInterval = setInterval(() => {
          if (!isLoading) {
            clearInterval(checkInterval);
            if (error) {
              reject(error);
            } else if (pivotData && pivotData.rows) {
              resolve(pivotData);
            } else {
              reject(new Error('No data available after loading'));
            }
          }
        }, 100); // Poll every 100ms
      }
    });
  }, [pivotData, error, isLoading]);

  const applyPivotSpec = useCallback(
    (pivotSpec: PivotSpec): Promise<{ summary?: number[]; data: PivotDataItem[] }> => {
      return new Promise((resolve, reject) => {
        if (!isLoading && pivotData) {
          resolve(filterTable(pivotData as ChartData, pivotSpec));
        } else {
          reject(new Error('Sample data not available. Please try again.'));
        }
      });
    },
    [isLoading, pivotData],
  );

  const getMaxDataWidth = useMemo(() => {
    return pivotData ? calculateMaxDataWidth(pivotData as ChartData) : PIVOT_COLUMN_DEFAULT_WIDTH;
  }, [pivotData]);

  return {
    fetchSampleData,
    applyPivotSpec,
    getMaxDataWidth,
    isLoading,
    pivotData,
    error,
    isFailed,
  };
};

export default usePivotData;
