import Container from '@mui/material/Container';
import React, { createContext, type FC, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CONTEXTS } from '../../constants';
import { NodeTypes } from '../../constants/nodes';
import { NavigationItemStatus } from '../../constants/session';
import { selectContext } from '../../store/selectors/context.selector';
import { selectDatasetList } from '../../store/selectors/dataspace.selector';
import { selectCurrentNodes } from '../../store/selectors/nodes.selector';
import { selectCurrentTask } from '../../store/selectors/task.selector';
import { queueQuestion } from '../../store/slices/dataAssistant.slice';
import { CANCELLABLE_TASK_STATUSES } from '../../types/task.types';
import Conversation from '../AskAva/Business/AvaConversation/Conversation';
import AskEmbeddedInput from '../AskAva/Embedded/AskEmbeddedInput';
import CancelButton from './CancelButton/CancelButton';
import './DataAssistant.scss';
import SweepButton from './SweepButton/SweepButton';

const DATA_ASSISTANT_ID = 'DataAssistant';

export interface DataAssistantProps {
  id?: string;
  responseBgColor?: string;
  smallScreenSize?: boolean;
}

/**
 * Determine if our Conversation is only a small portion of the viewport (VW).
 * This governs the ActionMenu collapse behavior, making the charts clickable to popout, etc.
 */
export const ScreenSizeContext = createContext(false);

const DataAssistant: FC<DataAssistantProps> = ({
  id = DATA_ASSISTANT_ID,
  responseBgColor,
  smallScreenSize = false,
}) => {
  const dataAssistantRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const nodes = useSelector(selectCurrentNodes);
  // get all the questions from the conversation
  // useMemo so we only update questions when conversation changes (not on every render)
  const questions: string[] = useMemo(() => {
    return nodes.reduce((filtered: string[], node) => {
      if (node.node_type === NodeTypes.NLQ) {
        const firstMessage = node.messages[0];
        if (firstMessage?.display) filtered.push(firstMessage.display);
      }
      return filtered;
    }, []);
  }, [nodes]);
  const context = useSelector(selectContext);
  const datasets = useSelector(selectDatasetList);
  const currentTask = useSelector(selectCurrentTask);

  /** Whether to show CancelButton. */
  const showCancelButton =
    currentTask && // there is a current task
    CANCELLABLE_TASK_STATUSES.includes(currentTask.taskStatus); // the current task is cancellable

  // For enabling UI - If no work is happening
  const isRestingContext = context === CONTEXTS.REST;

  const submitAction = (questionText: string) => {
    dispatch(queueQuestion(questionText));
  };

  return (
    <ScreenSizeContext.Provider value={smallScreenSize}>
      <div className="DataAssistant" data-testid="DataAssistant" id={id} ref={dataAssistantRef}>
        <Container maxWidth="md" className="DataAssistant-Container">
          <Conversation responseBgColor={responseBgColor} items={nodes} ref={dataAssistantRef} />
          <div className="DataAssistant-Input">
            <SweepButton />
            <AskEmbeddedInput
              id={`${id}-AskEmbeddedInput`}
              submitAction={submitAction}
              disabled={
                // if there are no datasets with active status
                !datasets.some((dataset) => dataset.status === NavigationItemStatus.ACTIVE) ||
                !isRestingContext
              }
              inputHistory={questions}
              helperText={null}
            />
            {showCancelButton && <CancelButton />}
          </div>
        </Container>
      </div>
    </ScreenSizeContext.Provider>
  );
};

export default DataAssistant;
