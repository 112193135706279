import { CONFLICT } from 'http-status-codes';
import { push } from 'redux-first-history';
import {
  all,
  call,
  delay,
  getContext,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { deleteFiles } from '../../api/file_manager.api';
import { getAllUserSessions } from '../../api/session.api';
import { getDatabaseConnectionHeaders, postCopyDBConnection } from '../../api/settings.api';
import { postCopyWorkflow } from '../../api/workflow.api';
import {
  editWorkspaceMetadata,
  listChartBackingSnapshots,
  listExamples,
  listWorkspaceAccessors,
} from '../../api/workspacev2.api';
import { API_SERVICES } from '../../constants/api';
import {
  CONFIRM_BUTTON_KEY,
  confirmDeleteMultiObjectAlert,
} from '../../constants/dialog.constants';
import {
  GLOBAL_USER_EMAIL,
  HOME_OBJECTS,
  HOME_OBJECT_KEYS,
  HOME_SCREEN_REFRESH_INTERVAL,
} from '../../constants/home_screen';
import { isWorkflowEditorPage, paths } from '../../constants/paths';
import { UNNAMED_SESSION } from '../../constants/session';
import { TOAST_ERROR, TOAST_SHORT } from '../../constants/toast';
import { loadDataset, loadDatasets, loadDatasetsKwargs } from '../../constants/utterance_templates';
import { ROOT_FOLDER, WORKSPACE_VISIBILITY } from '../../constants/workspace';
import { authenticate } from '../../utils/authenticate';
import {
  convertDcObjectToHomeScreenObject,
  getQualifiedObjectName,
  getSearchParams,
  isHomeScreen,
  openDialog,
  takeLatestByObjectType,
} from '../../utils/home_screen';
import { HomeObjectKeys, HomeObjects } from '../../utils/homeScreen/types';
import { sanitizeFuseResults } from '../../utils/search';
import { mapHomescreenTypeToWorkspaceType } from '../../utils/workspace';
import {
  DELETE_CONNECTION_FAILURE,
  DELETE_CONNECTION_SUCCESS,
  OPEN_CONNECTION_EDITOR_FAILURE,
  OPEN_CONNECTION_EDITOR_SUCCESS,
  deleteConnectionRequest,
  openConnectionEditorRequest,
} from '../actions/connection.actions';
import { closeDialog, createAlertChannelRequest } from '../actions/dialog.actions';
import { setNameSaveFailed } from '../actions/editor.actions';
import { getExamplesSuccess } from '../actions/examples.action';
import {
  BULK_LOAD_DATASET_REQUEST,
  CANCEL_HOME_OBJECTS_REFRESH,
  CLEAR_OBJECT_FILTERS,
  CREATE_NEW_OBJECT_REQUEST,
  DELETE_OBJECTS_FAILURE,
  DELETE_OBJECTS_REQUEST,
  DELETE_OBJECT_FAILURE,
  DELETE_OBJECT_REQUEST,
  DELETE_OBJECT_SUCCESS,
  GET_CHART_RELATED_SNAPSHOT_UUIDS_REQUEST,
  GET_HOME_SCREEN_OBJECTS_FAILURE,
  GET_HOME_SCREEN_OBJECTS_REQUEST,
  GET_HOME_SCREEN_OBJECTS_SUCCESS,
  OBJECT_OPEN_REQUEST,
  OBJECT_RENAME_REQUEST,
  SEARCH_REQUEST,
  SET_OBJECT_FILTERS,
  SET_TAB,
  WAIT_AND_REFRESH_HOME_OBJECTS,
  cancelHomeObjectsRefresh,
  createNewObjectFailure,
  createNewObjectSuccess,
  deleteObjectFailure,
  deleteObjectRequest,
  deleteObjectSuccess,
  deleteObjectsFailure,
  deleteObjectsSuccess,
  deselectObject,
  getChartBackingSnapshotUUIDsFailure,
  getChartBackingSnapshotUUIDsRequest,
  getChartBackingSnapshotUUIDsSuccess,
  getHomeScreenObjectsFailure,
  getHomeScreenObjectsRequest,
  getHomeScreenObjectsSuccess,
  objectOpenFailure,
  objectOpenSuccess,
  objectRenameFailure,
  objectRenameSuccess,
  searchFailure,
  searchSuccess,
  setSelectedRows,
  setTab,
  submitSearch,
  waitAndRefreshHomeObjects,
} from '../actions/home_screen.actions';
import {
  CREATE_NEW_INSIGHTS_BOARD_FAILURE,
  CREATE_NEW_INSIGHTS_BOARD_REQUEST,
  CREATE_NEW_INSIGHTS_BOARD_SUCCESS,
} from '../actions/insights_board.actions';
import {
  CLOSE_INSIGHTS_BOARD_CREATOR,
  openInsightsBoardCreator,
} from '../actions/settings.actions';
import { addToast } from '../actions/toast.actions';
import { CLOSE_FILE_MODAL, FILE_UPLOAD_SUCCESS, openFileModal } from '../actions/upload.actions';
import {
  CREATE_WORKSPACE_OBJECT_FAILURE,
  CREATE_WORKSPACE_OBJECT_SUCCESS,
  createWorkspaceObjectFailure,
  createWorkspaceObjectRequest,
  setCurrentFolder,
} from '../actions/workspacev2.actions';
import { closeDatasetCreator, openDatasetCreator } from '../reducers/datasetCreator.reducer';
import { createDatasetsComplete, openDatabaseBrowser } from '../slices/dbBrowser.slice';
import { triggerInitialUtterance } from '../slices/initial_utterance.slice';
import {
  exitSessionFailure,
  exitSessionSuccess,
  onAppClick,
  onSessionClick,
  saveSessionNameFailure,
  saveSessionNameRequest,
  saveSessionNameSuccess,
  tryExitSessionRequest,
} from '../slices/session.slice';
import { deleteDatasetHelper } from './dataset.saga';
import { deleteFileHelper } from './file_manager.saga';
import { deleteInsightsBoardRequestHelper } from './insights_board.saga';
import {
  selectAccessToken,
  selectCurrentFolder,
  selectDefaultHomeObjects,
  selectDialogValue,
  selectExamples,
  selectExperimentalFlag,
  selectFuse,
  selectHomeObjects,
  selectHomeScreenObjects,
  selectIsSearchSubmitted,
  selectSearchFilters,
  selectSearchValue,
  selectSelectedTab,
  selectUserID,
} from './selectors';
import {
  createAlertChannel,
  objectAlreadyExistsAlert,
  recipeAlreadyExistsWorker,
} from './utils/alert-channels';
import { callAPIWithRetry } from './utils/retry';
import { deleteWorkspaceObjectHelper, getDescendants } from './workspacev2.saga';

// inject store so we can access redux from this file
// ----------------------------------------------------
let store;
export const injectStoreToHomeScreenSaga = (_store) => {
  store = _store;
};
// ----------------------------------------------------

export function* raceGenerator(successAction, failureAction) {
  const [, failure] = yield race([take(successAction), take(failureAction)]);
  if (failure !== undefined) {
    throw failure.error;
  }
}

/**
  Returns the search results from fuse
  @param {String} value // value to be searched
*/
function* runSearch(value) {
  // Please refer to https://fusejs.io/ for more info
  if (!value) {
    // if there is no search value, just return all the dc objects
    const allHomeObjects = yield select(selectHomeObjects);
    yield put(searchSuccess({ data: [].concat(...Object.values(allHomeObjects)), value }));
  } else {
    const fuse = yield select(selectFuse);
    const searchResults = yield call([fuse, fuse.search], value);
    yield put(searchSuccess({ data: sanitizeFuseResults(searchResults), value }));
  }
}

export function* isObjectShared(uuid) {
  const accessToken = yield select(selectAccessToken);
  const objectUsers = yield call(listWorkspaceAccessors, accessToken, uuid);
  return objectUsers.data && Object.values(objectUsers.data).length > 1;
}

/*
  All home screen objects should be mapped to the HOME_OBJECTS_KEYS from /constants/home_screen:
{
  ...originalObject // Keep the original keys
  [HOME_OBJECT_KEYS.ID]: Integer, // Assign all following keys to standardized names from your object's data
  [HOME_OBJECT_KEYS.NAME]: String,
  [HOME_OBJECT_KEYS.TYPE]: String,
  [HOME_OBJECT_KEYS.OWNER_NAME]: String,
  [HOME_OBJECT_KEYS.SHARED]: Boolean,
  [HOME_OBJECT_KEYS.CREATED]: Date, // Created is supported for Sessions, Workflows, Insights Boards
  [HOME_OBJECT_KEYS.LAST_ACTIVE]: Date, // Last Active is supported for Sessions, Workflows, Insights Boards
  [HOME_OBJECT_KEYS.LAST_MODIFIED]: Date, // Last Modified is supported for Workflows, and Insights Boards
  [HOME_OBJECT_KEYS.VISIBILITY]: String,
}
*/

/**
  Get the list of all active sessions
  @param {String} accessToken // access token of the user
*/

export function* getSessions(accessToken, objectType, sessionType) {
  const response = yield callAPIWithRetry({
    apiFn: getAllUserSessions,
    args: [accessToken, sessionType],
  });
  const userId = yield select(selectUserID);
  const sessionObjects = {};
  response.data.forEach((session) => {
    sessionObjects[session.sessionId] = {
      ...session,
      [HOME_OBJECT_KEYS.ID]: session.sessionId,
      [HOME_OBJECT_KEYS.TABLE_ID]: `${objectType}-${session.sessionId}`,
      [HOME_OBJECT_KEYS.UUID]: session.sessionId,
      [HOME_OBJECT_KEYS.NAME]: session.name.String || UNNAMED_SESSION,
      [HOME_OBJECT_KEYS.TYPE]: objectType,
      [HOME_OBJECT_KEYS.OWNER_NAME]: session.ownerName,
      [HOME_OBJECT_KEYS.OWNER_ID]: session.ownerId,
      [HOME_OBJECT_KEYS.IS_SHARED]: session.ownerId !== userId,
      [HOME_OBJECT_KEYS.CREATED]: new Date(session.created_date),
      [HOME_OBJECT_KEYS.LAST_ACTIVE]: new Date(session.last_active),
      [HOME_OBJECT_KEYS.VISIBILITY]: WORKSPACE_VISIBILITY.VISIBLE,
    };
  });
  return sessionObjects;
}

/**
 * Requests the management service for a user's objects.
 *
 * Filters out experimental objects by their data.
 *
 * @param {string} accessToken
 * @param {string} objectType
 * @param {(obj: import('../../utils/homeScreen/types').HomeObjectKeysTypes) => boolean} [filter]
 * - Filter applied to home screen objects. If this function returns false, the object is excluded.
 */
export function* getDatachatObjects(accessToken, objectType, filter) {
  const experimentFlag = yield select(selectExperimentalFlag);
  const workspaceObjectType = mapHomescreenTypeToWorkspaceType(objectType);
  const workspacev2Service = yield getContext(API_SERVICES.WORKSPACEV2);
  let examples = yield select(selectExamples);

  // Get the list of our example datasets if we don't have them in redux
  if (objectType === HOME_OBJECTS.DATAFILE && examples.length === 0) {
    const examplesResponse = yield call(listExamples, accessToken);
    examples = examplesResponse.data.filter((e) => e.type && e.name && e.description);
    yield put(getExamplesSuccess({ examples }));
  }

  const { data } = yield callAPIWithRetry({
    apiFn: workspacev2Service.listWorkspace,
    args: [accessToken, workspaceObjectType],
  });

  return Object.values(data).reduce((dcObjects, dcObject) => {
    // compute conditions to exclude objects from the home screen

    /**
     * 1. The user is out of dev mode.
     * 2. The object is owned by DataChat.
     * 3. The object is not an example.
     */
    const excludeNonExampleObjectsOwnedByDataChat =
      !experimentFlag &&
      dcObject.OwnerEmail === GLOBAL_USER_EMAIL &&
      examples.every((example) => example.name !== dcObject.Name);

    // Apply a custom filter to the object if provided by the caller
    const keep = filter ? filter(dcObject) : true;

    if (excludeNonExampleObjectsOwnedByDataChat || !keep) return dcObjects;

    // transform the object to a home screen object
    dcObjects[dcObject.Uuid] = {
      ...dcObject,
      ...convertDcObjectToHomeScreenObject(dcObject, objectType),
    };

    return dcObjects;
  }, {});
}

/**
 * Gets all home screen objects by type.
 *
 * Filters out experimental objects by their type.
 *
 * @param {object} o - Destructured parameter.
 * @param {string} o.objectType - Type of home screen objects to get.
 */
export function* getHomeScreenObjectsRequestWorker({ objectType }) {
  try {
    const defaultHomeObjects = yield select(selectDefaultHomeObjects);
    const accessToken = yield select(selectAccessToken);
    let dcObjects;

    if (objectType === HOME_OBJECTS.ALL) {
      // put requests for each object type
      return yield all(
        Array.from(defaultHomeObjects).map((type) =>
          put(getHomeScreenObjectsRequest({ objectType: type })),
        ),
      );
    }

    if (objectType === HOME_OBJECTS.SESSION) {
      // use separate API for sessions
      dcObjects = yield* getSessions(accessToken, HOME_OBJECTS.SESSION);
    } else if (defaultHomeObjects.has(objectType)) {
      dcObjects = yield* getDatachatObjects(accessToken, objectType);
    } else {
      return yield put(getHomeScreenObjectsFailure({ error: 'Invalid Object Type' }));
    }

    if (objectType === HOME_OBJECTS.SNAPSHOT) yield put(getChartBackingSnapshotUUIDsRequest());

    yield put(getHomeScreenObjectsSuccess({ objectType, data: dcObjects }));
    return yield put(waitAndRefreshHomeObjects({ objectType }));
  } catch (error) {
    return yield put(getHomeScreenObjectsFailure({ objectType, error }));
  }
}

/**
 * When home objects are successfully fetched and we have a search,
 * refresh the search.
 */
export function* getHomeScreenObjectsSuccessWorker() {
  const searchValue = yield select(selectSearchValue);
  const isSearchSubmitted = yield select(selectIsSearchSubmitted);
  if (isSearchSubmitted) {
    yield* runSearch(searchValue);
    yield put(submitSearch({ refreshResults: true }));
  }
}

/**
  Get the list of all Home Screen Objects (or) Get list of objects specific to object type
  @param {String} objectType // String represting the type of object
*/
export function* getChartBackingSnapshotUUIDsRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(listChartBackingSnapshots, accessToken);
    yield put(getChartBackingSnapshotUUIDsSuccess({ uuidList: response.data }));
  } catch (error) {
    yield put(getChartBackingSnapshotUUIDsFailure({ error }));
  }
}

/**
  Worker action when any of the home screen object is clicked
  @param {String} objectType // String represting the type of object
  @param {String} args // Additional Arguments for that particular object

*/
export function* createNewObjectRequestWorker({ objectType, args }) {
  try {
    switch (objectType) {
      case HOME_OBJECTS.SESSION: {
        yield put(onAppClick({ showLoadCard: true }));
        break;
      }
      case HOME_OBJECTS.RECIPE: {
        // creates a recipe by duplicating an existing one
        const { objectId: workflowId, overwrite, name } = args;
        const accessToken = yield select(selectAccessToken);
        try {
          yield call(postCopyWorkflow, accessToken, workflowId, name, overwrite);
          yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.RECIPE }));
        } catch (error) {
          if (error.response && error.response.status === CONFLICT) {
            // recipe by that name already exists, prompt user to overwrite
            const userResponse = yield* recipeAlreadyExistsWorker(name);
            if (userResponse.overwrite) {
              yield call(postCopyWorkflow, accessToken, workflowId, name, userResponse.overwrite);
              yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.RECIPE }));
            } else {
              throw error;
            }
          } else {
            // throw error in all other cases
            throw error;
          }
        }
        break;
      }
      case HOME_OBJECTS.INSIGHTS_BOARD: {
        // Create a new Insights Board
        yield put(openInsightsBoardCreator());
        // Check if user submits form
        const [, close] = yield race([
          take(CREATE_NEW_INSIGHTS_BOARD_REQUEST),
          take(CLOSE_INSIGHTS_BOARD_CREATOR),
        ]);
        if (close !== undefined) {
          throw new Error('user closed creator');
        }
        // Check if creation of insights board is successfull
        const [, failure] = yield race([
          take(CREATE_NEW_INSIGHTS_BOARD_SUCCESS),
          take(CREATE_NEW_INSIGHTS_BOARD_FAILURE),
        ]);
        if (failure !== undefined) {
          throw failure.error;
        }
        break;
      }
      case HOME_OBJECTS.CONNECTION: {
        const { objectId: connId, name } = args;
        if (name) {
          const accessToken = yield select(selectAccessToken);
          try {
            // check if connection with same name already exists
            yield call(getDatabaseConnectionHeaders, accessToken, name);
            // if it does not, copy connection
            yield call(postCopyDBConnection, accessToken, connId, name);
            // refresh home screen objects
            yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.CONNECTION }));
          } catch (error) {
            if (error.response && error.response.status === CONFLICT) {
              // connection with the name already exists
              yield* objectAlreadyExistsAlert(objectType, name);
            } else {
              throw error;
            }
          }
        } else {
          yield put(openDatabaseBrowser({ connection: null }));
          yield put(openConnectionEditorRequest({}));
          yield* raceGenerator(OPEN_CONNECTION_EDITOR_SUCCESS, OPEN_CONNECTION_EDITOR_FAILURE);
        }
        break;
      }
      case HOME_OBJECTS.FOLDER: {
        // get the tab the user is currently on
        const selectedTab = yield select(selectSelectedTab);
        // get the current folder the user is in
        const currentFolder = yield select(selectCurrentFolder);
        // if the user is not in the folders tab, the parent folder should be ROOT
        const parentFolder = selectedTab !== HOME_OBJECTS.ALL ? ROOT_FOLDER : currentFolder;
        // open the create workspace objecet dialog
        yield call(openDialog, {
          title: 'Create New Folder',
          clickEvent: () => {
            const state = store.getState();
            const folderName = selectDialogValue(state);
            store.dispatch(createWorkspaceObjectRequest({ name: folderName, parentFolder }));
          },
          objectName: '',
          placeholder: 'Enter folder name here',
          closeEvent: () => {
            store.dispatch(
              createWorkspaceObjectFailure({
                error: new Error('User closed create folder dialog'),
                canceledByUser: true,
              }),
            );
          },
        });
        // handle dialog interactions
        while (true) {
          // wait for a success or failure event
          const { failure } = yield race({
            success: take(CREATE_WORKSPACE_OBJECT_SUCCESS),
            failure: take(CREATE_WORKSPACE_OBJECT_FAILURE),
          });
          // if the createWorkspace worker throws an error
          // then continue and wait for another success/failure event
          // if user cancels reqest, close the dialog and throw error
          // if successful, close the dialog and continue the saga
          if (failure?.canceledByUser) {
            yield put(closeDialog());
            throw failure.error;
          } else if (failure) {
            continue;
          } else {
            yield put(closeDialog());
            break;
          }
        }
        // navigate the user to the my work tab if they are not already there.
        if (selectedTab !== HOME_OBJECTS.ALL) {
          yield put(setTab({ tab: HOME_OBJECTS.ALL }));
        }
        yield put(setCurrentFolder({ folder: parentFolder }));
        // refresh objects in folder tab
        yield put(getHomeScreenObjectsRequest({ objectType }));
        break;
      }
      case HOME_OBJECTS.DATAFILE: {
        yield put(openFileModal());
        let numFilesUploaded = 0;
        while (true) {
          // waits for user to upload a file OR close modal
          const { uploadSuccess, closeModal } = yield race({
            uploadSuccess: take(FILE_UPLOAD_SUCCESS),
            closeModal: take(CLOSE_FILE_MODAL),
          });
          // if user closes modal, exit while loop
          if (closeModal) break;

          // if successful, increment number of files uploaded
          // and refresh home screen
          if (uploadSuccess) {
            numFilesUploaded++;
            yield put(getHomeScreenObjectsRequest({ objectType, refreshing: true }));
          }
        }
        // this runs when a user closes the file upload modal
        if (numFilesUploaded === 0) {
          // if now files were uploaded, throw an error
          throw new Error('No files were uploaded');
        }
        break;
      }
      case HOME_OBJECTS.DATASET: {
        const defaultHomeObjects = yield select(selectDefaultHomeObjects);
        if (!defaultHomeObjects.has(HOME_OBJECTS.DATAFILE)) {
          yield put(openDatasetCreator());
          let success = false;
          while (true) {
            const { uploadSuccess, close } = yield race({
              uploadSuccess: take(FILE_UPLOAD_SUCCESS),
              connectSuccess: take(createDatasetsComplete.type),
              closed: take(closeDatasetCreator.type),
            });
            if (close) break;
            if (uploadSuccess) {
              yield put(
                getHomeScreenObjectsRequest({
                  objectType: HOME_OBJECTS.DATASET,
                  refreshing: true,
                }),
              );
              yield put(
                getHomeScreenObjectsRequest({
                  objectType: HOME_OBJECTS.FOLDER,
                  refreshing: true,
                }),
              );
            }
            // Do not need to refresh for connection, Database Browser handles that
            success = true;
          }
          if (!success) throw new Error('user closed creator');
        } else {
          yield put(openDatabaseBrowser({ connection: null }));
          yield put(openConnectionEditorRequest({}));
          yield* raceGenerator(OPEN_CONNECTION_EDITOR_SUCCESS, OPEN_CONNECTION_EDITOR_FAILURE);
        }
        break;
      }
      default: {
        return yield put(createNewObjectFailure({ error: 'Invalid Object Type' }));
      }
    }
    return yield put(createNewObjectSuccess({ objectType }));
  } catch (error) {
    return yield put(createNewObjectFailure({ objectType, error }));
  }
}

/**
 * Work actions when user tries to bulk load datasets
 *
 * @param {HomeObjectKeysTypes[]} folders // List of folders to load datasets into a new session
 * @param {HomeObjectKeysTypes[]} datasets // List of datasets to load datasets into a new session
 */
export function* bulkLoadDatasetRequestWorker({ folders = [], datasets = [] }) {
  /// Get the nested datasets from the folders
  let childrenDatasets = [];
  for (const folder of folders) {
    const descendants = yield call(getDescendants, folder);

    childrenDatasets = [
      ...childrenDatasets,
      ...descendants.filter((obj) => obj[HomeObjectKeys.TYPE] === HOME_OBJECTS.DATASET),
    ];
  }

  // Combine the datasets from the folders and the datasets selected
  const allSelectedDatasets = [...childrenDatasets, ...datasets];

  if (allSelectedDatasets.length > 0) {
    yield put(onAppClick({}));
    const datasetNames = allSelectedDatasets.map((dataset) => getQualifiedObjectName(dataset));
    const loadUtterance = loadDatasets(datasetNames);
    const datasetObjs = allSelectedDatasets.map((dataset) => ({
      uuid: dataset[HomeObjectKeys.UUID],
      name: dataset[HomeObjectKeys.NAME],
    }));
    const loadDataUtteranceKwargs = loadDatasetsKwargs(datasetObjs);
    yield put(
      triggerInitialUtterance({
        message: loadUtterance,
        utteranceMetadata: loadDataUtteranceKwargs,
      }),
    );
  } else {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: `No datasets to load.`,
      }),
    );
  }
}

/**
  Worker action when any of the home screen object is opened
*/
export function* objectOpenRequestWorker({ object }) {
  const { [HOME_OBJECT_KEYS.TYPE]: objectType, [HOME_OBJECT_KEYS.ID]: objectId } = object;
  const appClickPayload = {
    objectId,
    objectType,
  };

  try {
    switch (objectType) {
      case HOME_OBJECTS.SESSION: {
        // object should contain appId, appViewId, sessionType. ObjectId should be sessionId in this case.
        const { sessionType } = object;
        yield put(onSessionClick({ sessionId: objectId, sessionType }));
        break;
      }
      case HOME_OBJECTS.RECIPE: {
        // TODO: We should still figure out a different approach to replay workflows from the new home screen
        // object should contain : appId, dvFile, shared, name, ownerEmail
        // args should contain stepByStep
        const utterance = `Replay the recipe called <strong>${getQualifiedObjectName(
          object,
        )}</strong> without pausing`;
        yield put(triggerInitialUtterance({ message: utterance }));
        // Pass in the workflow id if the user clicks on the workflow from the dashboard
        yield put(onAppClick(appClickPayload));
        break;
      }
      case HOME_OBJECTS.INSIGHTS_BOARD: {
        // ObjectId should be insightsBoardId in this case.
        yield put(push(paths.insightsBoard(objectId)));
        break;
      }
      case HOME_OBJECTS.SNAPSHOT: {
        const utterance = `Load data from the snapshot <strong>${getQualifiedObjectName(
          object,
        )}</strong>`;
        yield put(triggerInitialUtterance({ message: utterance }));
        yield put(onAppClick(appClickPayload));
        break;
      }
      case HOME_OBJECTS.CONNECTION: {
        yield put(openDatabaseBrowser({ connection: object }));
        break;
      }
      case HOME_OBJECTS.FOLDER: {
        yield put(setCurrentFolder({ folder: object }));
        // remove the opened folder from selected object in redux
        yield put(deselectObject());
        const selectedTab = yield select(selectSelectedTab);
        if (selectedTab !== HOME_OBJECTS.ALL) {
          yield put(setTab({ tab: HOME_OBJECTS.ALL }));
        }
        break;
      }
      case HOME_OBJECTS.DATASET: {
        const dataset = {
          uuid: object[HOME_OBJECT_KEYS.UUID],
          name: object[HOME_OBJECT_KEYS.NAME],
        };
        const utterance = {
          message: loadDataset(getQualifiedObjectName(object)),
          utteranceMetadata: loadDatasetsKwargs([dataset]),
        };
        yield put(triggerInitialUtterance(utterance));
        yield put(onAppClick(appClickPayload));
        break;
      }
      default: {
        return yield put(objectOpenFailure({ object, error: 'Invalid Object Type' }));
      }
    }
    return yield put(objectOpenSuccess({ object }));
  } catch (error) {
    return yield put(objectOpenFailure({ object, error }));
  }
}

/**
  Worker action for deleting the objects
  @param {Object} object // Object representing the home screen object to be deleted
*/
export function* deleteObjectRequestWorker({ object }) {
  const {
    [HomeObjectKeys.TYPE]: objectType,
    [HomeObjectKeys.ID]: objectId,
    [HomeObjectKeys.IS_SHARED]: isShared,
    [HomeObjectKeys.NAME]: objectName,
    [HomeObjectKeys.UUID]: uuid,
  } = object;
  try {
    switch (objectType) {
      case HOME_OBJECTS.SESSION: {
        yield put(tryExitSessionRequest(objectId));
        // Check if there is any failure exiting the session
        const [, failure] = yield race([
          take(exitSessionSuccess.type),
          take(exitSessionFailure.type),
        ]);
        if (failure !== undefined) {
          return yield put(
            deleteObjectFailure({
              object,
              error: 'Unable to exit the session',
            }),
          );
        }
        break;
      }
      case HOME_OBJECTS.RECIPE: {
        // TODO: Refactor this code when we have separate endpoint to just delete workflows
        const fileName = `${isShared ? '[SHARED]' : ''}${objectId}.dcw`;
        const accessToken = yield select(selectAccessToken);

        yield put(closeDialog());
        yield call(deleteFiles, accessToken, [fileName]);

        break;
      }
      case HOME_OBJECTS.INSIGHTS_BOARD: {
        const { [HOME_OBJECT_KEYS.ACCESS_TYPE]: accessType } = object;
        yield* deleteInsightsBoardRequestHelper({ id: objectId, name: objectName, accessType });
        break;
      }
      case HOME_OBJECTS.CONNECTION: {
        yield put(deleteConnectionRequest({ object }));
        yield* raceGenerator(DELETE_CONNECTION_SUCCESS, DELETE_CONNECTION_FAILURE);
        break;
      }
      case HOME_OBJECTS.FOLDER: {
        yield* deleteWorkspaceObjectHelper({ uuid });
        break;
      }
      case HOME_OBJECTS.DATAFILE: {
        yield* deleteFileHelper({ fileId: objectId });
        break;
      }
      case HOME_OBJECTS.DATASET: {
        yield* deleteDatasetHelper({ uuid });
        break;
      }
      default: {
        return yield put(deleteObjectFailure({ object, error: 'Invalid Object Type' }));
      }
    }
    // refreshing set to true so the loader doesn't render when an object is deleted.
    yield put(getHomeScreenObjectsRequest({ objectType, refreshing: true }));
    // wait for the updated list of home screen objects to be fetched
    yield* raceGenerator(GET_HOME_SCREEN_OBJECTS_SUCCESS, GET_HOME_SCREEN_OBJECTS_FAILURE);
    return yield put(deleteObjectSuccess({ object }));
  } catch (error) {
    return yield put(deleteObjectFailure({ object, error }));
  }
}

/**
 * Worker for deleting several objects at once
 * @param {HomeObjectKeysTypes[]} objects
 */
export function* deleteObjectsRequestWorker({ objects }) {
  const containsInsightsBoard = objects.some(
    (object) => object[HomeObjectKeys.TYPE] === HomeObjects.INSIGHTS_BOARD,
  );
  const containsSharedObject = objects.some((object) => object[HomeObjectKeys.IS_SHARED]);

  const alertChannel = yield createAlertChannel(
    confirmDeleteMultiObjectAlert(containsInsightsBoard, containsSharedObject),
  );
  const keyChoice = yield take(alertChannel);
  yield put(closeDialog());
  // Track failed objects
  const failedObjects = {};
  if (keyChoice === CONFIRM_BUTTON_KEY) {
    for (const object of objects) {
      try {
        yield put(deleteObjectRequest({ object }));
        yield* raceGenerator(DELETE_OBJECT_SUCCESS, DELETE_OBJECT_FAILURE);
      } catch (error) {
        failedObjects[object[HomeObjectKeys.NAME]] = { error, uuid: object[HomeObjectKeys.UUID] };
      }
    }
  } else {
    yield put(deleteObjectsFailure({ cancelledObjects: objects }));
  }
  if (Object.keys(failedObjects).length > 0) {
    yield put(deleteObjectsFailure({ failedObjects }));
  }
  return yield put(deleteObjectsSuccess());
}

export function* deleteObjectsFailureWorker({ failedObjects }) {
  const errorMessage = Object.keys(failedObjects)
    .map((objectName) => `${objectName}: ${failedObjects[objectName].error}`)
    .join(', ');
  if (errorMessage) {
    yield put(
      createAlertChannelRequest({
        error: new Error(`Some objects could not be deleted: ${errorMessage}`),
        failedObjects,
      }),
    );
  }
}

function* renameWorkspaceObject({ uuid, objectType, newName }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const dcObjects = yield select(selectHomeScreenObjects, objectType);
    const isDuplicate = dcObjects.some((obj) => obj.Name === newName);
    if (isDuplicate || newName === ROOT_FOLDER[HOME_OBJECT_KEYS.NAME]) {
      // a snapshot is already named this
      const conflictError = new Error(`A(n) ${objectType} with the name ${newName} already exists`);
      conflictError.code = CONFLICT;
      throw conflictError;
    }
    yield call(editWorkspaceMetadata, accessToken, uuid, { name: newName });
  } catch (error) {
    if (error.code === CONFLICT) {
      yield* objectAlreadyExistsAlert(objectType, newName);
    } else {
      yield put(createAlertChannelRequest({ error }));
    }
    throw new Error(error);
  }
}

export function* objectRenameRequestWorker({ object, newName }) {
  const {
    [HOME_OBJECT_KEYS.UUID]: objectUuid,
    [HOME_OBJECT_KEYS.TYPE]: objectType,
    [HOME_OBJECT_KEYS.ID]: objectId,
    [HOME_OBJECT_KEYS.NAME]: objectName,
  } = object;
  try {
    if (objectName !== newName) {
      switch (objectType) {
        case HOME_OBJECTS.SESSION: {
          yield put(saveSessionNameRequest({ sessionId: objectId, sessionName: newName }));
          const [, failure] = yield race([
            take(saveSessionNameSuccess.type),
            take(saveSessionNameFailure.type),
          ]);
          if (failure !== undefined) {
            // SAVE_SESSION_NAME_FAILURE should have an error, re-throw it
            throw failure?.payload?.error;
          }
          break;
        }
        case HOME_OBJECTS.RECIPE:
          if (newName === '') {
            const emptyNameError = new Error(`Empty name is not supported`);
            throw emptyNameError;
          }
          yield* renameWorkspaceObject({ uuid: objectUuid, objectType, newName });
          break;
        case HOME_OBJECTS.INSIGHTS_BOARD:
        case HOME_OBJECTS.SNAPSHOT:
        case HOME_OBJECTS.CONNECTION:
        case HOME_OBJECTS.FOLDER:
        case HOME_OBJECTS.QUERY:
        case HOME_OBJECTS.DATASET:
          yield* renameWorkspaceObject({ uuid: objectUuid, objectType, newName });
          break;
        default:
          throw new Error('Unsupported object type for renaming.');
      }
    }
    yield put(closeDialog());
    yield put(objectRenameSuccess({ object, newName }));
    return yield put(getHomeScreenObjectsRequest({ objectType }));
  } catch (error) {
    if (isWorkflowEditorPage() && objectType === HOME_OBJECTS.RECIPE) {
      yield put(setNameSaveFailed({ setFailed: true }));
    }
    return yield put(objectRenameFailure({ object, newName, error }));
  }
}

/**
  Worker for searching the objects
  @param {String} value // String represting the value entered by the user in the search bar
  The worker sets the results of the fuse to tempFilteredResults
*/
export function* searchRequestWorker({ value }) {
  try {
    const trimmedValue = value.trim();
    yield* runSearch(trimmedValue);
  } catch (error) {
    yield put(searchFailure({ error, value }));
  }
}

// refresh home objects worker
export function* refreshHomescreenObjectWorker(objectType) {
  try {
    // Wait the specified resfresh interval, then refetch the object type
    yield delay(HOME_SCREEN_REFRESH_INTERVAL);
    yield put(getHomeScreenObjectsRequest({ objectType, refreshing: true }));
  } catch (error) {
    yield put(cancelHomeObjectsRefresh());
  }
}

// watch for refresh of home objects.
export function* watchForRefresh({ objectType }) {
  // If a home screen object request was made outside of the home screen
  // (example: when opening the insights board publish menu)
  // then cancel the refresh action.
  if (isHomeScreen()) {
    yield race([
      call(refreshHomescreenObjectWorker, objectType),
      take(CANCEL_HOME_OBJECTS_REFRESH),
    ]);
  } else {
    yield put(cancelHomeObjectsRefresh());
  }
}

// Pushes a new search url path with filters to browser.
export function* setSearchUrl() {
  const selectedTab = yield select(selectSelectedTab);
  // TODO: Add filters to url path for rest of the tabs as well
  // For now we are adding filters to url path for only search page.
  if (selectedTab === HOME_OBJECTS.SEARCH) {
    const searchValue = yield select(selectSearchValue);
    const searchFilters = yield select(selectSearchFilters);
    const searchParamsString = yield call(getSearchParams, searchValue, searchFilters);

    yield put(
      push(
        `${paths.homeScreenTabs}?tab=${HOME_OBJECTS.SEARCH}${
          searchParamsString && `&${searchParamsString}`
        }`,
      ),
    );
  }
}

/**
 * in addition to setting the selected tab in redux, push a the objects path
 * to the url. If the tab is the search tab, also set the query params
 * @param {string} tab tab user is navigating to
 * @param {boolean} pushToUrl this will be false when this action is called
 * on a POP event
 */
export function* setTabWorker({ tab, pushToUrl = true }) {
  if (pushToUrl) {
    if (tab === HOME_OBJECTS.ALL) {
      yield put(setCurrentFolder({ folder: ROOT_FOLDER }));
    }
    if (tab === HOME_OBJECTS.SEARCH) {
      yield* setSearchUrl();
    } else {
      yield put(push(`${paths.homeScreenTabs}?tab=${tab}`));
    }
  }
  yield put(setSelectedRows([]));
}

/**
  Worker to set object filters
  @param {Boolean} pushToUrl // Boolean used to determine whether to push or replace to browser URL path.
*/
export function* setObjectFiltersWorker({ pushToUrl }) {
  if (pushToUrl) yield* setSearchUrl();
}

export function* clearObjectFiltersWorker() {
  yield* setSearchUrl();
}

export default function* () {
  yield takeEvery(
    GET_HOME_SCREEN_OBJECTS_REQUEST,
    authenticate(getHomeScreenObjectsRequestWorker, getHomeScreenObjectsFailure),
  );
  yield takeEvery(CREATE_NEW_OBJECT_REQUEST, createNewObjectRequestWorker);
  yield takeEvery(OBJECT_OPEN_REQUEST, objectOpenRequestWorker);
  yield takeEvery(DELETE_OBJECT_REQUEST, deleteObjectRequestWorker);
  yield takeEvery(OBJECT_RENAME_REQUEST, objectRenameRequestWorker);
  yield takeLatest(SEARCH_REQUEST, searchRequestWorker);
  yield takeLatest(GET_HOME_SCREEN_OBJECTS_SUCCESS, getHomeScreenObjectsSuccessWorker);
  yield takeLatest(SET_OBJECT_FILTERS, setObjectFiltersWorker);
  yield takeLatest(CLEAR_OBJECT_FILTERS, clearObjectFiltersWorker);
  yield takeLatest(SET_TAB, setTabWorker);
  yield takeLatest(
    GET_CHART_RELATED_SNAPSHOT_UUIDS_REQUEST,
    getChartBackingSnapshotUUIDsRequestWorker,
  );
  yield takeLatestByObjectType(WAIT_AND_REFRESH_HOME_OBJECTS, watchForRefresh);
  yield takeLatest(DELETE_OBJECTS_REQUEST, deleteObjectsRequestWorker);
  yield takeLatest(DELETE_OBJECTS_FAILURE, deleteObjectsFailureWorker);
  yield takeEvery(BULK_LOAD_DATASET_REQUEST, bulkLoadDatasetRequestWorker);
}
