import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';
import { Renderers } from '../../types/render.types';
import { PngProducts, Product } from '../slices/chartRenders.slice';

interface SelectParams {
  dcChartId: string;
  renderType: Renderers;
  width?: number;
  height?: number;
}

const selectRenders = (state: RootState) => state.chartRenders;

export const selectRenderProduct = createSelector(
  [selectRenders, (_state: RootState, params: SelectParams) => params],
  (renders, { dcChartId, renderType, width, height }): Product | null => {
    const render = renders[dcChartId]?.[renderType];
    if (!render) return null;

    if (renderType === Renderers.PNG && width && height) {
      return (render as PngProducts)[`${width}:${height}`] ?? null;
    }

    return render as Product;
  },
);

export const selectRender = createSelector(
  [selectRenderProduct],
  (product) => product?.render ?? null,
);

export const selectRenderProgress = createSelector(
  [selectRenderProduct],
  (product) => product?.progress ?? null,
);

export const selectRenderError = createSelector(
  [selectRenderProduct],
  (product) => product?.error ?? null,
);
