/* eslint-disable */
import { CONFLICT } from 'http-status-codes';
import { push } from 'redux-first-history';
import {
  actionChannel,
  all,
  call,
  cancel,
  delay,
  flush,
  fork,
  getContext,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { handleMessageSpecVersion } from 'translate_dc_to_echart';
import { getUtterance } from '../../api/chat.api';
import { getDataFromStorage } from '../../components/ChartData/dataUtils';
import { CLASS, CMD, EXIT_CODE, REPLAY_MESSAGES, SKILL_EVENTS, TIMEOUT } from '../../constants';
import { API_SERVICES } from '../../constants/api';
import {
  CANCEL_BUTTON_KEY,
  avaWorkingAlert,
  goodbyePromptAlert,
} from '../../constants/dialog.constants';
import { SHARE_TO_FACEBOOK_WORKPLACE_URL } from '../../constants/external_urls';
import { isNetworkError } from '../../constants/network_error';
import { MessageSourceType, MessageTypes, NodeTypes } from '../../constants/nodes';
import { paths } from '../../constants/paths';
import { NavigationTabs } from '../../constants/session';
import {
  TOAST_BOTTOM_RIGHT,
  TOAST_ERROR,
  TOAST_LONG,
  getErrorToastConfig,
} from '../../constants/toast';
import { authenticate } from '../../utils/authenticate';
import { handleMessageError } from '../../utils/errorHandling/errorHandlers.messages';
import {
  appendInformationalMessage,
  appendUtteranceHistory,
  clearUtteranceHistory,
  updateChatPanelText,
} from '../actions/chat.actions';
import { openConnectionEditorRequest } from '../actions/connection.actions';
import {
  addVizToDashboard,
  generateDashboardFailure,
  modifyDashboardChart,
  reloadDashboardSuccess,
  setIdToUpdate,
} from '../actions/dashboard.actions';
import { retrieveForgottenDataset } from '../actions/dataset.actions';
import { closeDialog, createAlertChannelRequest, openAlertDialog } from '../actions/dialog.actions';
import { fileDownloadRequest } from '../actions/file_download.actions';
import {
  DESCRIBE_AND_SEND_UTTERANCE_REQUEST,
  FORGET_DATASET_REQUEST,
  GOODBYE_COMMAND,
  RECEIVE_MESSAGES_REQUEST,
  RELOAD_MESSAGES,
  SEND_MESSAGE_REQUEST,
  SEND_MESSSAGE_BYPASS,
  SKILL_FAILED,
  addChartUpdate,
  addUserMessage,
  clearScreen,
  describeAndSendUtteranceFailure,
  describeAndSendUtteranceSuccess,
  forgetDatasetFailure,
  forgetDatasetSuccess,
  handledReceivedMessages,
  receiveMessagesEmpty,
  receiveMessagesFailure,
  receiveMessagesSuccess,
  reloadMessagesFailure,
  reloadMessagesSuccess,
  sendMessageBypass,
  sendMessageFailure,
  sendMessageRequest,
  sendMessageSuccess,
  skillFailed,
  skillQuestion,
} from '../actions/messages.actions';
import { restartPollingMechanism, stopPollingMechanism } from '../actions/poll.actions';
import { closePopOutModal } from '../actions/popout.actions';
import { closeReplayController, openReplayController } from '../actions/replay_controller.actions';
import { serverIsIdle, serverIsWaiting } from '../actions/server_status.action';
import {
  changeSettingsView,
  closeUtteranceTimeoutExtensionForm,
  openSettingsMenu,
  openUtteranceTimeoutExtensionForm,
} from '../actions/settings.actions';
import { addToast } from '../actions/toast.actions';
import { selectContext, selectIsReplaying, selectIsStepwise } from '../selectors/context.selector';
import {
  selectCurrentSessionNavigationTab,
  selectDetailPanelStatus,
  selectSession,
  selectSessionType,
} from '../selectors/session.selector';
import {
  setIsAdding,
  setIsStepwise,
  setLastStep,
  startReplay,
  startUpdatingPendingDuration,
  stopReplay,
  stopUpdatingPendingDuration,
  updatePlaceholderFromServer,
  updateStepwiseMessage,
} from '../slices/context.slice';
import {
  askQuestionFailure,
  askQuestionRequest,
  askQuestionSuccess,
} from '../slices/dataAssistant.slice';
import {
  getNodesFailure,
  getNodesRequest,
  getNodesSuccess,
  insertMessagesFailure,
  insertMessagesRequest,
  insertMessagesSuccess,
} from '../slices/nodes.slice';
import {
  SESSION_TYPES,
  exitSessionFailure,
  exitSessionRequest,
  exitSessionSuccess,
  onAppClick,
  setDataUsage,
  setDetailPanelOpen,
  setLocalDependencyGraph,
  startSessionSuccess,
  tryExitSessionRequest,
} from '../slices/session.slice';
import { cancelCurrentTask } from '../slices/task.slice';
import { askQuestionRequestWorker } from './dataAssistant.saga';
import {
  selectAccessToken,
  selectIsGeneratingDashboard,
  selectServerStatus,
  selectSessionDatasetStorage,
  selectShowUtteranceTimeoutExtForm,
  selectUserConfig,
} from './selectors';
import { createTask } from './task.saga';
import {
  appFailedToStartAlert,
  confirmForgetDatasetAlert,
  createAlertChannel,
} from './utils/alert-channels';
import { setPreTaskContext, setWorkingContext, waitForRestingContext } from './utils/context';
import {
  filterMessages,
  isDashboardItem,
  selectDashboardMessages,
  selectVisualMessages,
} from './utils/messages';

/**
 * This file handles the sending and receiving of messages to and from the server.
 */

/**
 * When a user sends a 'goodbye' message, show a pop up that prompts the user
 * asking if they want to do it. If yes, shows a transition pop up that brings
 * the user to the home screen. If no, closes the pop up.
 */
export function* goodbyeWorker() {
  const status = yield select(selectServerStatus);
  const state = yield select(selectContext);
  let alertChannel;

  if (status.serverWaiting || state === 'working') {
    alertChannel = yield createAlertChannel(avaWorkingAlert());
  } else {
    alertChannel = yield createAlertChannel(goodbyePromptAlert());
  }

  const keyChoice = yield take(alertChannel);
  yield put(closeDialog());
  // User picked Cancel. Do nothing.
  if (keyChoice === CANCEL_BUTTON_KEY) return;

  // const startTime = yield select(selectSessionStartTime);
  // const sessionDuration = Math.floor(millisecondsToMinutes(new Date().getTime() - startTime));
  // User picked Yes.
  // Show goodbye transition pop up and redirect user to home screen.
  // yield createAlertChannel(goodbyeTransitionAlert(numKeystrokes, sessionDuration)); // Show transition pop up.
  yield put(closePopOutModal()); // close popup
  yield put(tryExitSessionRequest()); // Exit session.

  // Waits for timeout to complete and server to acknowledge response
  // before redirecting the user to the home screen.
  yield all([
    race([
      // waits for server to acknowledge exit session request
      take(exitSessionSuccess.type),
      take(exitSessionFailure.type),
    ]),
    delay(TIMEOUT.GOODBYE), // waits for this number of seconds
  ]);
  yield put(push(paths.home)); // return to home screen
  yield put(closeDialog()); // close the dialog
}

/**
 * Sends a message to the server and indicates its response status.
 * @param {Object} message Message to be delivered to server
 * @param {string} sessionID the unique identifier for the session that sent this utterance
 */
export function* sendMessageWorker({ message, sessionID, muted }) {
  const accessToken = yield select(selectAccessToken);
  const userConfig = yield select(selectUserConfig);
  const timeoutValue = userConfig.utteranceTimeout;
  // sessionID is the sessionID for whichever the session from whence the utterance was sent.
  // newSessionID is the sessionID for the current session
  const newSessionID = yield select(selectSession);

  try {
    // Create a new task
    const { success: createTaskSuccess, newTaskId } = yield call(
      createTask,
      `Skill: ${message?.data}`,
    );
    if (!createTaskSuccess) return;

    // only seed messages that are not commands (YES, NO, etc.)
    if (!Object.values(CMD).includes(message.data?.toLowerCase())) {
      yield put(
        insertMessagesRequest([
          {
            type: NodeTypes.GEL,
            message: {
              type: MessageTypes.Text,
              data: message.data,
              src_type: MessageSourceType.Client,
            },
            metadata: { muted },
          },
          { type: NodeTypes.GELResponse, message: [] },
        ]),
      );
      const { success, failure } = yield race({
        success: take(insertMessagesSuccess),
        failure: take(insertMessagesFailure),
      });
      if (failure) {
        yield put(cancelCurrentTask());
        throw failure.payload.error;
      }

      // set the node_id of the last inserted node to the utteranceMetadata,
      // this will tell worker which node it write messages to
      const insertedNodeIds = success.payload;
      if (insertedNodeIds.length > 0)
        message.utteranceMetadata = {
          ...message.utteranceMetadata,
          node_id: insertedNodeIds[insertedNodeIds.length - 1],
        };
    }

    // do nothing if we're trying to send a message in a different session, i.e. if we copy and
    // paste a bunch of utterances and then try to open a new session while those utterances are running
    // See https://github.com/DataChatAI/datachat/issues/18435
    // if sessionID is undefined or sessionID is equal to the current sessionID, then we submit this utterance
    const chatService = yield getContext(API_SERVICES.CHAT);
    if (!sessionID || sessionID === newSessionID) {
      yield call(chatService.postChat, {
        message: message.data,
        accessToken,
        sessionId: newSessionID,
        instruction: message.instruction,
        muted: message.muted,
        display: message.display,
        skipParse: message.skipParse,
        utteranceMetadata: message.utteranceMetadata,
        utteranceTimeout: timeoutValue,
        taskId: newTaskId,
      });
      // A message was successfully sent to the server. Restart polling mechanism to retrieve messages.
      yield all([put(sendMessageSuccess()), put(restartPollingMechanism())]);
    }
  } catch (error) {
    yield put(cancelCurrentTask());
    yield put(sendMessageFailure({ error }));
    yield put(stopUpdatingPendingDuration());

    if (error.response?.status === CONFLICT) {
      // If the worker is busy with another request
      yield put(
        addToast({
          toastType: TOAST_ERROR,
          length: TOAST_LONG,
          position: TOAST_BOTTOM_RIGHT,
          message: "There's currently a data operation in progress. Refresh the page and try again",
        }),
      );
    } else {
      yield put(createAlertChannelRequest({ error }));
    }
  }
}

/**
 * @param {{
 *  payload: import('../slices/dataAssistant.slice').AskQuestionRequestPayload;
 *  type: string;
 * }} action
 */
export function* sendAskSkillWorker(action) {
  const taskObject = yield fork(askQuestionRequestWorker, action);

  const { success, failure } = yield race({
    success: take(askQuestionSuccess),
    failure: take(askQuestionFailure),
  });

  if (success) {
    yield put(restartPollingMechanism());
  } else {
    yield cancel(taskObject);
    yield put(cancelCurrentTask());
    yield put(stopUpdatingPendingDuration());
    if (failure.alert) yield put(createAlertChannelRequest({ error: failure.error }));
  }
}

/**
 * Sends a message to backend.
 * Also record what the corresponding dashboard chart id for future update
 *
 * @param {Object} send - sendMessageRequest
 */
function* sendMessageAndSetUpdateId(send) {
  const { message, dashboardDcChartId, sessionID, muted } = send;
  const sessionType = yield select(selectSessionType);

  // Set Id to update in dashboard
  if (sessionType === SESSION_TYPES.DASHBOARD && dashboardDcChartId !== undefined) {
    yield put(setIdToUpdate({ idToUpdate: dashboardDcChartId }));
  }

  yield call(authenticate(sendMessageWorker, sendMessageFailure), { message, sessionID, muted });
  // Waits for the server to complete a context before sending the next message.
}

function* handleStepwiseMessage(message) {
  const isStepwise = yield select(selectIsStepwise);
  const sessionType = yield select(selectSessionType);
  yield put(updateStepwiseMessage({ data: message.data }));
  yield put(updateChatPanelText({ text: message.data }));
  if (!isStepwise && sessionType === SESSION_TYPES.CHAT) {
    yield put(openReplayController());
  }
  yield put(setIsStepwise(true));
  if (message.local_graph) yield put(setLocalDependencyGraph(message.local_graph));
}

// Generate an utterance from JSON and send it.
function* describeAndSendUtteranceWorker({ message, callback, sendRaw = false, muted }) {
  try {
    const session = yield select(selectSession);
    if (sendRaw) {
      yield put(
        sendMessageRequest({
          message: '',
          sessionId: session,
          utteranceMetadata: { skill: message.skill, kwargs: message.kwargs },
          muted,
        }),
      );
    } else {
      const accessToken = yield select(selectAccessToken);
      const response = yield call(
        getUtterance,
        accessToken,
        session,
        message.skill,
        message.kwargs,
      );
      const utterance = response.data;
      yield put(
        sendMessageRequest({
          message: utterance,
          sessionId: session,
          muted,
        }),
      );
    }

    yield put(describeAndSendUtteranceSuccess());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(describeAndSendUtteranceFailure({ error }));
  }
}

// Worker to confirm and send forget dataset command
function* forgetDatasetWorker({ dataset, callback }) {
  try {
    const message = {
      skill: 'ForgetDatasets',
      kwargs: {
        dataset_names: [dataset],
      },
    };

    yield* confirmForgetDatasetAlert(message, callback);

    yield put(forgetDatasetSuccess());
  } catch (error) {
    yield put(forgetDatasetFailure({ error }));
  }
}

// TODO JSDoc comment me
export function* handleReceivedMessages(messages) {
  // early return if there are no messages
  if (messages.length === 0) {
    yield put(receiveMessagesEmpty());
    return;
  }

  const { replayMessages, cancelMessages, placeholderMessages, generalMessages, isStepwise } =
    filterMessages(messages);

  // Update replay state; Start replay before message has been displayed.
  // This ensures that placeholder messages do not override the textbox during a replay.
  if (replayMessages.length > 0) {
    const latestReplayMessage = replayMessages[replayMessages.length - 1];
    if (latestReplayMessage === REPLAY_MESSAGES.START) {
      yield put(startReplay(isStepwise));
      if (isStepwise) yield put(openReplayController());
    }
  }
  const isGeneratingDashboard = yield select(selectIsGeneratingDashboard);
  const isReplaying = yield select(selectIsReplaying);

  // show the most recent placeholder message from server, otherwise show nothing as a placeholder
  if (placeholderMessages.length > 0) {
    const latestPlaceholderMessage = placeholderMessages[placeholderMessages.length - 1];
    yield put(updatePlaceholderFromServer({ placeholderFromServer: latestPlaceholderMessage }));
  }

  // handle general messages
  if (generalMessages) {
    // Batches together successive server messages, these are broken up by 'instruction' messages
    for (let i = 0; i < generalMessages.length; i++) {
      let message = generalMessages[i];
      switch (message.class) {
        case CLASS.START_FAILURE:
          // App failed to start. Show an alert and direct the user to the homepage.
          yield* appFailedToStartAlert();
          break;
        case CLASS.QUESTION:
          // received a question from the server
          yield put(skillQuestion({ data: message.data }));
          yield put(serverIsWaiting());
          break;
        default:
          break;
      }

      const sessionType = yield select(selectSessionType);
      if (message.data_usage !== undefined) {
        const sessionId = yield select(selectSession);
        yield put(setDataUsage({ sessionId, dataUsage: message.data_usage }));
      }

      switch (sessionType) {
        case SESSION_TYPES.CHART_REFRESH:
        case SESSION_TYPES.WORKFLOW_EDITOR:
        case SESSION_TYPES.INSIGHTS_BOARD:
        case SESSION_TYPES.CHAT:
          // This is a bool field at this point because only replay workflow relies
          // on the signal to trigger front-end action
          if (
            message.class === CLASS.READY &&
            message.src_type === 'Server' &&
            message.skill_event === SKILL_EVENTS.DONE
          ) {
            yield put(serverIsIdle());
          }
          if (message.src_type === 'Client' && !message.muted) {
            if (!message.display) {
              yield put(appendUtteranceHistory({ utterance: message.data }));
            }
            yield put(addUserMessage({ data: message.data, display: message.display }));
          } else if (message.instruction > 0) {
            const sessionID = yield select(selectSession);
            yield put(setIsStepwise(false));
            yield put(sendMessageBypass({ message, sessionID }));
          } else if (message.instruction === -1) {
            // Message Instruction -1 indicates a stepwise replay
            yield* handleStepwiseMessage(message);
          } else if (message.skill_event === SKILL_EVENTS.NAVIGATE) {
            const params = message.data;
            const obj = JSON.parse(params);
            // obj.type is Settings.views, can be general, connections, files, keys
            // obj.value currently is the connectionId parameter in changeSettingsView()
            if (obj.page === 'settings') {
              yield put(openSettingsMenu());
              yield put(changeSettingsView(obj.type));
            } else if (obj.page === 'connection_editor') {
              yield put(openConnectionEditorRequest({ uuid: obj.value, newTab: true }));
            } else if (obj.page === 'utterance_timeout_extension_form') {
              yield put(openUtteranceTimeoutExtensionForm(obj.message, obj.auto_interrupt_time));
            }
          } else if (message.skill_event === SKILL_EVENTS.OPEN_LINK) {
            let link = '';
            let params = new URLSearchParams(message.data);
            if (params.has('redirect_uri')) {
              const redirectUri = new URL(params.get('redirect_uri'));
              if (redirectUri.pathname.startsWith(paths.cloudauth)) {
                params.set(
                  'redirect_uri',
                  `${window.location.protocol}//${window.location.host}${redirectUri.pathname}`,
                );
              }
              link = decodeURIComponent(params.toString());
            } else if (params.has('destination')) {
              // don't open sharing dialogue on replay
              if (isReplaying) {
                continue;
              }
              const destination = params.get('destination');
              switch (destination) {
                case 'workplace':
                  params = `${window.location.protocol}//${window.location.host + message.data}`;
                  link = SHARE_TO_FACEBOOK_WORKPLACE_URL + encodeURIComponent(params);
                  break;
                default:
                  break;
              }
            } else {
              link = message.data;
            }
            yield call(window.open, link);
          } else if (message.skill_event === SKILL_EVENTS.COPY_LINK && !isReplaying) {
            // copied from https://codepen.io/dtschust/pen/WGwdVN?editors=0011
            const textField = document.createElement('textarea');
            textField.innerText = `${window.location.protocol}//${window.location.host}${message.data}`;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
          } else if (
            message.type === 'chart_update' ||
            message.type === 'note_update' ||
            message.type === 'table_update'
          ) {
            // Only table_updates include data to handle
            if (message.type === 'table_update') {
              message.data = handleMessageSpecVersion(message.data);
            }
            yield put(addChartUpdate(message.object_id, message.version, message.data));
          } else if (message.class === CLASS.FAILURE) {
            yield put(
              skillFailed({ error: new Error(message.data), errorDetails: message.error_details }),
            );
          } else {
            // only display unmuted messages
            // vvv For both session stepwise replays and workflow editor vvv
            if (message.data === REPLAY_MESSAGES.ADD) yield put(setIsAdding(true));
            if (
              message.data === REPLAY_MESSAGES.CANCEL_ADD ||
              (message.class === CLASS.READY && message.skill_event === SKILL_EVENTS.DONE) ||
              (sessionType === SESSION_TYPES.CHAT &&
                [REPLAY_MESSAGES.FAIL, REPLAY_MESSAGES.TRY_AGAIN].includes(message.data))
            ) {
              // isAdding flag is reset after...
              // - every ready:done message
              // - explicitly cancelled
              // - when a added action fails during a session
              yield put(setIsAdding(false));
            }

            // only display unmuted messages
            if (!message.muted) {
              message = handleMessageSpecVersion(message);
              yield put(receiveMessagesSuccess({ messages: [message] }));
            }

            // If the dataset was forgotten trigger dataset retrieval
            if (message?.data?.name && message?.data?.version) {
              const datasetName = message.data.name;
              const datasetVersion = message.data.version;
              const datasetStorage = yield select(selectSessionDatasetStorage);
              // Don't apply data formatting because we're only checking 'forgotten'
              const unformattedData = getDataFromStorage({
                datasetStorage,
                isSession: true,
                dName: datasetName,
                dVersion: datasetVersion,
              });
              const isDatasetForgottenOnFE = unformattedData?.forgotten;
              if (isDatasetForgottenOnFE) {
                yield put(retrieveForgottenDataset({ datasetName, version: datasetVersion }));
              }
            }

            // close Utterance Timeout Extension Form if skill has succeeded
            const showUtteranceTimeoutExtForm = yield select(selectShowUtteranceTimeoutExtForm);
            if (
              message.skill_event === SKILL_EVENTS.EXIT_CODE &&
              message.exitCode === EXIT_CODE.SUCCESS &&
              showUtteranceTimeoutExtForm
            ) {
              yield put(closeUtteranceTimeoutExtensionForm());
            }
          }
          break;
        case SESSION_TYPES.DASHBOARD: {
          const sessionID = yield select(selectSession);
          if (message.src_type === 'Client' && !message.muted) {
            yield put(addUserMessage({ data: message.data, display: message.display }));
          } else if (message.instruction) {
            yield put(sendMessageBypass({ message, sessionID }));
          } else if (isGeneratingDashboard && isDashboardItem(message)) {
            message = handleMessageSpecVersion(message);
            yield all([
              put(addVizToDashboard({ newViz: message })),
              put(receiveMessagesSuccess({ messages: [message] })),
            ]);
          } else if (!isGeneratingDashboard && isDashboardItem(message)) {
            yield put(modifyDashboardChart({ data: message }));
          } else if (message.class === 'question') {
            yield put(sendMessageRequest({ message: 'Yes', sessionID }));
          } else if (message.type === 'file' && message.data.method !== 'manual') {
            yield put(fileDownloadRequest({ file: message.data.file }));
          } else if (message.class === 'failure') {
            if (isGeneratingDashboard) {
              yield put(
                generateDashboardFailure({
                  description: message.data,
                }),
              );
            } else {
              yield put(
                openAlertDialog({
                  title: 'An Error Ocurred in execution',
                  descriptions: [message.data],
                }),
              );
              yield put(modifyDashboardChart({ data: undefined }));
            }
          }
          break;
        }
        default:
          break;
      }
    }
  }

  // Update replay state; Stop replay only after message has been displayed.
  // This ensures that the replay state ends only after the messages store is updated.
  for (let i = 0; i < replayMessages.length; i++) {
    if (replayMessages[i] === REPLAY_MESSAGES.END || replayMessages[i] === REPLAY_MESSAGES.FAILED) {
      yield put(stopReplay());
      yield put(closeReplayController());
      yield put(setLastStep(false));
      yield put(updateStepwiseMessage({ data: null }));
    } else if (replayMessages[i] === REPLAY_MESSAGES.LAST_STEP) {
      yield put(setLastStep(true));
    }
  }

  // Cancelling can also stop a replay
  if (isReplaying && cancelMessages.length > 0) {
    yield put(stopReplay());
    yield put(closeReplayController());
    yield put(updateStepwiseMessage({ data: null }));
  }
}

/**
 * Retrieves a message from the server and indicates its response status.
 */
export function* receiveMessagesWorker() {
  try {
    const session = yield select(selectSession);
    if (session === undefined) {
      yield put(receiveMessagesEmpty());
    } else {
      yield put(getNodesRequest());
      const { success, failure } = yield race({
        success: take(getNodesSuccess),
        failure: take(getNodesFailure),
      });
      if (failure) {
        throw failure.payload.error;
      }
      const { messages } = success.payload;
      if (messages === undefined) {
        throw Error;
      }
      yield* handleReceivedMessages(messages);
      yield put(handledReceivedMessages());
    }
  } catch (error) {
    yield put(receiveMessagesFailure({ error }));

    if (!isNetworkError(error)) {
      // Stop polling mechanism to prevent more requests.
      // The user can send a new message to re-initiate the polling mechanism
      // If endpoint is down, keep polling to check for when it is back up.
      yield all([put(stopPollingMechanism()), flush(chan)]);
    }
    // TODO: If it is a 500, don't stop polling. Show a snackbar
    // hanging from the top of the screen to make things less obstruive.
    // The snackbar should only be displayed if the error is received
    // and no snackbar previously existed. (Right now, the 500 pop up gets stacked.)
    // If it is a 403, stop polling, show AlertDialog pop up.
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Adds every send message request to the queue and sends it to the server in sequence.
 * This ensures that the send message requests are received by the server in order.
 */
export function* sendMessageQueue() {
  const sendMessageRequestAction = yield actionChannel(SEND_MESSAGE_REQUEST);
  const askQuestionRequestAction = yield actionChannel(askQuestionRequest);

  while (true) {
    yield call(waitForRestingContext);

    // Wait for either a skill or question submission
    const { skill, question } = yield race({
      skill: take(sendMessageRequestAction),
      question: take(askQuestionRequestAction),
    });

    // Start the pre-task context since we expect work to start soon
    yield call(setPreTaskContext);

    // Handle a skill or question
    if (skill) yield fork(sendMessageAndSetUpdateId, skill);
    else if (question) yield fork(sendAskSkillWorker, question);
  }
}

/**
 * Manages when the send message queue is running or resting.
 */
export function* sendMessageManager() {
  // Start the send message listener when a session is successfully started.
  while (yield race([take(startSessionSuccess.type)])) {
    // Start the send message listener
    const messageQueue = yield fork(sendMessageQueue);
    // Stop listening for send message requests when a user attempts to start or exit a session
    yield race([take(onAppClick.type), take(exitSessionRequest.type)]);
    yield cancel(messageQueue);
  }
}

/**
 * Manages when the receive messages queue is running or resting.
 */
export function* receiveMessagesManager() {
  // Start the receive message listener when a session is successfully started.
  // Since we may resume the previous session after refresh, we need to listen to
  // both START_SESSION and RESUME_SESSION to restart the message queue
  while (yield race([take(startSessionSuccess.type)])) {
    // Start the receive message listener
    const messageQueue = yield fork(receiveMessagesQueue);
    // Stop listening for receive message requests when a user attempts to start or exit a session
    yield race([take(onAppClick.type), take(exitSessionRequest.type)]);
    yield cancel(messageQueue);
  }
}

/**
 * Displays and sends a message directly.
 * @param {Object} message Message object that will be sent.
 * @param {string} sessionID unique identifier for the session that sent this utterance
 */
export function* sendMessageBypassWorker({ message, sessionID }) {
  const newSessionID = yield select(selectSession);
  // if sessionID is undefined or sessionID is equal to the current sessionID, then we submit this utterance
  if (!sessionID || sessionID === newSessionID) {
    yield call(sendMessageAndSetUpdateId, { message });
  }
}

// Reload all messages from an existing session
// For now, support Chat session and dashboard session
// It's possible to specify a specific session id to reload
export function* reloadMessagesWorker({ sessionId = null }) {
  try {
    const session = sessionId || (yield select(selectSession));
    if (sessionId) yield put(clearUtteranceHistory());

    const sessionType = yield select(selectSessionType);
    yield put(getNodesRequest({ sessionId: session }));
    const { success, failure } = yield race({
      success: take(getNodesSuccess),
      failure: take(getNodesFailure),
    });
    if (failure) {
      throw failure.payload.error;
    }
    const { messages: data } = success.payload;
    if (data === undefined) {
      throw Error;
    }
    yield put(clearScreen());
    let messages;
    let sessionName;
    if (
      sessionType === SESSION_TYPES.CHAT ||
      sessionType === SESSION_TYPES.WORKFLOW_EDITOR ||
      sessionId
    ) {
      // Need to reload all the visual messages
      messages = selectVisualMessages(data);
    } else {
      // for dashboard, we only want the messages from "Replay the workflow ..."
      // to "Replay ended"
      // Everything else is the result of refresh or utterance update
      // Besides, we also need to retrieve the session Name from the message history
      const { workflow, dashboardMessages } = selectDashboardMessages(data);
      sessionName = workflow;
      messages = selectVisualMessages(dashboardMessages);
    }

    // Only need to display the latest placeholder in reload
    // Users won't see the process anyway
    let latestPlaceholderMessage;

    // Need the last replay signal to figure out
    // whether we are still in a replay
    let latestReplayMessage;
    let isStepwise = false;

    // readySignal checks whether the BE worker is ready
    // it will be set to true if there is a non new_bubble skill-event message received
    // Very unlikely the server is not ready in session reload
    // But still, user could refresh before the server is ready
    let readySignal = false;

    for (let i = 0; i < messages.length; i++) {
      let message = messages[i];
      // App failed to start. Show an alert and direct the user to the homepage.
      if (message.class === CLASS.START_FAILURE) {
        yield* appFailedToStartAlert();
        return;
      }
      // Don't display muted messages on refresh
      if (message.muted) {
        if (message.instruction === -1) isStepwise = true;
        continue;
      }

      // For Insights Board refreshes
      if (message.class === CLASS.UPDATE || message.class === CLASS.INFORMATIONAL) {
        yield put(appendInformationalMessage({ sessionId, message: message.data }));
      }

      if (message.src_type === 'Server') {
        // For server message, mock the loading behavior as if we have received these messages successfully from server
        if (message.class && message.class === CLASS.UPDATE) {
          // record latest placeholder
          latestPlaceholderMessage = message.data;
          // the 'data' field of this type is for "placeholder"
          // So we shouldn't display it in chat panel. Stop here
          continue;
        }
        // If we receive anything other than a new bubble event,
        // we know server is up.
        if (message.skill_event && message.skill_event !== SKILL_EVENTS.NEW_BUBBLE) {
          readySignal = true;
          // Clear the placeholder record if a skill is done
          if (message.skill_event === SKILL_EVENTS.DONE) {
            latestPlaceholderMessage = undefined;
          }
          // Message containing skill_event can also contains normal conversational content
          // So we continue processing.
        }
        // For replay message, we want to keep track of it since we need to know
        // up to the point reload finishes, whether we are still in a replay mode.
        if (message.data && Object.values(REPLAY_MESSAGES).includes(message.data)) {
          latestReplayMessage = message.data;
          // We also need to display it to give users a clue whether
          // replay has ended. So continue processing.
        }
        if (
          message.type === 'chart_update' ||
          message.type === 'note_update' ||
          message.type === 'table_update'
        ) {
          // Only table_updates include data to handle
          if (message.type === 'table_update') {
            message.data = handleMessageSpecVersion(message.data);
          }
          yield put(addChartUpdate(message.object_id, message.version, message.data));
          continue;
        }
        // A normal message displayed in chat panel/dashboard grid
        if (!message.muted) {
          message = handleMessageSpecVersion(message);
          yield put(receiveMessagesSuccess({ messages: [message] }));
        }
        if (isDashboardItem(message) && sessionType === SESSION_TYPES.DASHBOARD) {
          yield put(addVizToDashboard({ newViz: message }));
        }
      } else if (message.src_type === 'Client' && !message.muted) {
        // For user message, do not need to send them, but need to display them.
        // So issue action to put it in redux store
        if (!message.display) {
          // Do not append verbose message to history (they are truncated anyway)
          yield put(appendUtteranceHistory({ utterance: message.data }));
        }
        yield put(addUserMessage({ data: message.data, display: message.display }));
      }
    }

    // grab the last message
    const lastMessage = messages[messages.length - 1];
    // If we have the readySignal,
    // we know at least the worker is not in start up state
    if (readySignal) {
      // messages cannot be empty, since we have "readySignal"
      // which is the indication of at least one non-new-bubble message.

      if (lastMessage.class === CLASS.QUESTION) {
        // dispatch the required actions to handle the question
        yield put(skillQuestion({ data: lastMessage.data }));
        yield put(serverIsWaiting());
      } else {
        // This means, at the meantime, there is still skill running
        // Then, we need to prepare the context for the running skill

        // 4. Set Context to working
        // This will also help us get rid of the loading screen
        // (as it is no longer STARTUP)
        yield call(setWorkingContext);

        // Here, we don't need to worry about the scenario where the last
        // message is a Question.
        // This is because user has not sent any message yet.
        // So the queue cannot be blocked. Hence, we don't need
        // to issue a "CONTEXT_COMPLETE" to re-activate the queue.

        // 1. if in the middle of a replay, then we need to revisit all the instructions
        // and resend them.
        if (latestReplayMessage === REPLAY_MESSAGES.START) {
          yield put(startReplay(isStepwise));
          if (isStepwise) yield put(openReplayController());
          const sessionID = yield select(selectSession);
          // no need of placeholder if we are in the mid of replay
          latestPlaceholderMessage = undefined;
          // It is okay if we sent duplicated one as server
          // will help us filter those out
          // Also, we only want to send the last "instruction" message
          // as there is at most one "waiting-to-be-sent" utterance.
          // The server will wait for message before it sends any further instruction.
          for (let i = data.length - 1; i >= 0; i--) {
            if (data[i].instruction > 0) {
              yield put(sendMessageBypass({ message: data[i], sessionID }));
              break;
            } else if (data[i].instruction === -1) {
              yield* handleStepwiseMessage(data[i]);
              break;
            }
          }
        }

        // 2. Set the placeholder for textbox
        yield put(updatePlaceholderFromServer({ placeholderFromServer: latestPlaceholderMessage }));

        // 3. Start skill execution timer (though it is apparently not accurate)
        yield put(startUpdatingPendingDuration());
      }
    }
    if (sessionType === SESSION_TYPES.DASHBOARD) {
      // Save session name for future refresh
      // We really don't know the dashboard name of this session as it is not persisted in dc_session
      // So use sessionName for both sessionName and dashboardName (Otherwise, it will be undefined in the UI)
      // TODO: save dashboardName with the associated session in dc_session
      yield put(reloadDashboardSuccess({ sessionName, dashboardName: sessionName }));
    }
    // Polling mechanism is blocked until the reload stage has finished
    yield put(reloadMessagesSuccess());
  } catch (error) {
    yield put(reloadMessagesFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Side effect manager for messages of class CLASS.FAILED
 *
 * @param {object} obj
 * @param {Error} obj.error - Error object
 * @param {import('../../types/errorCodes/errorCodes.types').ErrorDetails} obj.errorDetails
 * @returns
 */
export function* skillFailedWorker({ error, errorDetails }) {
  // select if the data assistant is not open. This is only possible inside the dataspace
  const currentTab = yield select(selectCurrentSessionNavigationTab);
  const isDataAssistantActive = yield select(selectDetailPanelStatus);

  // Display an error toast if the data assistant is not open
  if (currentTab === NavigationTabs.DATA_SPACE && !isDataAssistantActive) {
    // put an error message
    yield put(addToast(getErrorToastConfig(error.message)));
  }

  // Handle custom error codes:
  handleMessageError(error, errorDetails);
}

/** Hooks into other actions & opens the Data Assistant panel */
export function* openDetailPanelWorker() {
  yield put(setDetailPanelOpen());
}

// Spins up the saga watchers
export default function* () {
  yield fork(sendMessageManager);
  yield takeLatest(GOODBYE_COMMAND, goodbyeWorker);
  yield takeLatest(SEND_MESSSAGE_BYPASS, sendMessageBypassWorker);
  yield takeLatest(RELOAD_MESSAGES, reloadMessagesWorker);
  yield takeEvery(DESCRIBE_AND_SEND_UTTERANCE_REQUEST, describeAndSendUtteranceWorker);
  yield takeLatest(SEND_MESSAGE_REQUEST, openDetailPanelWorker);
  yield takeEvery(FORGET_DATASET_REQUEST, forgetDatasetWorker);
  yield takeLatest(SKILL_FAILED, skillFailedWorker);
  // Cancelling this worker could get us into an inconsistent state. Using takeLeading to avoid this.
  yield takeLeading(RECEIVE_MESSAGES_REQUEST, receiveMessagesWorker);
}
