import isEmpty from 'lodash/isEmpty';
import { actionChannel, call, cancel, fork, put, race, select, take } from 'redux-saga/effects';
import { getSessionDependencyGraph } from '../../api/session.api';
import { authenticate } from '../../utils/authenticate';
import {
  GET_GRAPH_UPDATE_REQUEST,
  getGraphUpdateFailure,
  getGraphUpdateSuccess,
} from '../actions/graphMode.actions';
import { selectSession } from '../selectors/session.selector';
import { exitSessionRequest, onAppClick, startSessionSuccess } from '../slices/session.slice';
import { lastUpdateGraphModeSelector, selectAccessToken } from './selectors';

/**
 * Retrieves an update to a session's dependency graph.
 */
export function* receiveGraphUpdateWorker() {
  try {
    const lastUpdate = yield select(lastUpdateGraphModeSelector);
    if (lastUpdate) {
      const accessToken = yield select(selectAccessToken);
      const sessionId = yield select(selectSession);
      const response = yield call(getSessionDependencyGraph, accessToken, sessionId, lastUpdate);
      const { nodes, edges, skills, products } = response.data;
      if (!isEmpty(nodes) || !isEmpty(edges) || !isEmpty(skills) || !isEmpty(products)) {
        yield put(getGraphUpdateSuccess(nodes, edges, skills, products));
        return;
      }
    }
    yield put(getGraphUpdateSuccess([], [], [], []));
  } catch (error) {
    yield put(getGraphUpdateFailure(error));
  }
}

/**
 * Adds every graph update action to a queue and sends it to the server in sequence.
 * This ensures that the requests are received by the server in order.
 */
export function* receiveGraphUpdateQueue() {
  const receiveGraphUpdateChannel = yield actionChannel(GET_GRAPH_UPDATE_REQUEST);
  while (true) {
    const receive = yield take(receiveGraphUpdateChannel);
    yield call(authenticate(receiveGraphUpdateWorker, getGraphUpdateFailure), receive);
  }
}

/**
 * Manages when the receiveGraphUpdateQueue is running or resting.
 */
export function* receiveGraphUpdateManager() {
  // Start the receive graph updates listener when a session is successfully started.
  while (yield race([take(startSessionSuccess.type)])) {
    // Start the receive message listener
    const messageQueue = yield fork(receiveGraphUpdateQueue);
    // Stop listening for recieveGraphUpdateRequests when a user attempts to start or exit a session
    yield race([take(onAppClick.type), take(exitSessionRequest.type)]);
    yield cancel(messageQueue);
  }
}

export default function* apiWatcher() {
  yield fork(receiveGraphUpdateManager);
}
