import { call, put, select, takeEvery } from 'typed-redux-saga';
import { getDatasetDescription } from '../../../api/datasets.api';
import { Description } from '../../../types/dataset.types';
import { selectAccessToken } from '../../sagas/selectors';
import { callWithPolling } from '../../sagas/utils/saga_utils';
import { getDescription, gotDescription } from './slice';

export function* getDescriptionWorker(action: ReturnType<typeof getDescription>) {
  // extract payload
  const { id, claim, params } = action.payload;

  // get access token
  const accessToken: string = yield* select(selectAccessToken);

  // try getting description by polling
  try {
    const result = yield* call(callWithPolling<Description>, {
      accessToken,
      fn: getDatasetDescription,
      signal: new AbortController().signal,
      args: { id, claim, params },
      retryOpts: { enabled: true },
    });

    // @ts-expect-error: typed-redux-saga fails to infer the correct type of result
    const description: Description = result.data;

    // update store with description data
    yield* put(gotDescription({ id, description: { kind: 'data', data: description } }));

    // return description
    return description;
  } catch (err) {
    // get error
    let error: Error;
    if (err instanceof Error) error = err;
    else error = new Error('failed to get description');

    // update store with description error
    yield* put(gotDescription({ id, description: { kind: 'error', error } }));

    // throw error
    throw err;
  }
}

export default function* () {
  yield* takeEvery(getDescription, getDescriptionWorker);
}
