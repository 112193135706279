import { createSelector } from 'reselect';

// Custom type for the dataset state to avoid refactoring
// the reducer to typescript. Will not be needed once the dataset
// reducer is removed!
interface SessionDatasetStorageEntry {
  // TODO: fill in more keys
  columns: { name: string; type: string }[];
  rows: Array<string | number>[];
}
export interface SessionDatasetStorage {
  [dc_dataset_id: string]: SessionDatasetStorageEntry;
}

export type Dataset = {
  name: string;
  version: number;
  timestamp: number;
  renamed_from: string | null;
  renamed_to: string | null;
  forgotten: boolean;
  dataset_id: string;
};

type DatasetList = {
  [datasetName: string]: {
    [version: number]: Dataset;
  };
};
export type DatasetRootState = {
  dataset: {
    selectedDatasetName: string;
    selectedDatasetVersion: number;
    currentDataset: [string, number];
    datasetList: DatasetList;
    hiddenDatasetList: DatasetList;
    showStat: boolean;
    showAnnotations: boolean;
    sessionDatasetStorage: SessionDatasetStorage;
    pivotError: boolean;
    pivotErrorMsg: string;
  };
};

export const selectCurrentDataset = (state: DatasetRootState) => state.dataset.currentDataset;
export const selectDatasetList = (state: DatasetRootState) => state.dataset.datasetList;
export const selectCurrentDatasetInfo = createSelector(
  [selectDatasetList, selectCurrentDataset],
  (datasetList, [currentDataset, currentVersion]) =>
    datasetList?.[currentDataset]?.[currentVersion],
);
export const selectSelectedDatasetVersion = (state: DatasetRootState) =>
  state.dataset.selectedDatasetVersion;
export const selectSelectedDatasetName = (state: DatasetRootState) =>
  state.dataset.selectedDatasetName;
export const selectHiddenDatasetList = (state: DatasetRootState) => state.dataset.hiddenDatasetList;
export const selectDatasetStorage = (state: DatasetRootState) =>
  state.dataset.sessionDatasetStorage;

export const selectShowStat = (state: DatasetRootState) => state.dataset.showStat;

export const selectShowAnnotations = (state: DatasetRootState) => state.dataset.showAnnotations;

export const selectPivotError = (state: DatasetRootState) => state.dataset.pivotError;
export const selectPivotErrorMsg = (state: DatasetRootState) => state.dataset.pivotErrorMsg;
