import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';
import { NavigationItemStatus } from '../../constants/session';
import { Dataset } from '../slices/dataspace.slice';
import {
  Dataset as DatasetDataGrid,
  SessionDatasetStorage,
  selectCurrentDatasetInfo,
  selectCurrentDataset as selectCurrentDatasetPairDataGrid,
  selectDatasetList as selectDatasetListDataGrid,
  selectDatasetStorage,
  selectHiddenDatasetList,
  selectSelectedDatasetName as selectSelectedDatasetNameDataGrid,
  selectSelectedDatasetVersion as selectSelectedDatasetVersionDataGrid,
} from './dataset.selector';
import { selectsIsOnDataChatSessionPage } from './router.selector';

export const selectDatasets = (state: RootState) => state.dataspace.datasets;
export const selectCurrentDcDatasetId = (state: RootState) => state.dataspace.currentDcDatasetId;
export const selectCurrentWorkerDcDatasetId = (state: RootState) =>
  state.dataspace.currentWorkerDcDatasetId;
export const selectCurrentDatasetDataSpace = createSelector(
  [selectDatasets, selectCurrentDcDatasetId],
  (datasets, currentDcDatasetId) => (currentDcDatasetId ? datasets[currentDcDatasetId] : null),
);
export const selectCurrentWorkerDataset = createSelector(
  [selectDatasets, selectCurrentWorkerDcDatasetId],
  (datasets, currentWorkerDcDatasetId) =>
    currentWorkerDcDatasetId ? datasets[currentWorkerDcDatasetId] : null,
);

export const selectDatasetById = (state: RootState, id: string | undefined) => {
  if (!id) return null;
  return state.dataspace.datasets[id] ?? null;
};

export const selectDatasetNameOrder = createSelector(
  [
    (state: RootState) => state.dataspace.datasetOrder,
    // Avoid cyclic dependency from importing session.selector
    (state: RootState) => state.session.session,
  ],
  (datasetOrder, sessionID) => datasetOrder?.[sessionID ?? ''] ?? [],
);

export const datasetsByNameVersion = <T extends Dataset | DatasetDataGrid>(datasets: T[]) =>
  datasets.reduce((acc, dataset) => {
    if (!acc[dataset.name]) {
      acc[dataset.name] = {};
    }
    acc[dataset.name][dataset.version] = dataset;
    return acc;
  }, {} as { [name: string]: { [version: number]: T } });
// Similar in name to DataGrid selectors, but not compatible
export const selectDatasetsByNameVersion = createSelector([selectDatasets], (datasets) =>
  datasetsByNameVersion<Dataset>(Object.values(datasets)),
);

export const selectDatasetList = createSelector(
  [selectDatasetsByNameVersion, selectDatasetNameOrder],
  (datasets, datasetNameOrder) =>
    datasetNameOrder.flatMap((name) => Object.values(datasets?.[name] ?? {})),
);
export const selectActiveDatasets = createSelector(selectDatasetList, (datasets) =>
  datasets.filter((dataset) => dataset.status === NavigationItemStatus.ACTIVE),
);
export const selectVisibleDatasets = createSelector(selectDatasetList, (datasets) =>
  datasets.filter((dataset) => dataset.status !== NavigationItemStatus.HIDDEN),
);
export const selectHasVisibleDatasets = createSelector(
  selectVisibleDatasets,
  (visibleDatasets) => visibleDatasets.length > 0,
);
export const selectHiddenDatasets = createSelector(selectDatasetList, (datasets) =>
  datasets.filter((dataset) => dataset.status === NavigationItemStatus.HIDDEN),
);

export const selectDatasetNamesAndVersions = createSelector(
  [
    // get all datasets
    selectDatasets,
    // allow for a list of dataset ids to be passed in
    (_state, dcDatasetIds: string[]) => dcDatasetIds,
  ],
  (allDatasets, dcDatasetIds) => {
    const availableDatasets = dcDatasetIds.filter((id) => id in allDatasets);
    return availableDatasets.map((id) => ({
      name: allDatasets[id].name,
      version: allDatasets[id].version,
    }));
  },
);

export const selectDuplicateDatasetName = (state: RootState) => state.dataspace.duplicateName;

export const selectIsDataSpaceLoading = (state: RootState) =>
  state.dataspace.loading || !state.dataspace.requested;

/** The message of the error encountered when loading the dataspace. */
export const selectDataspaceError = (state: RootState) => state.dataspace.error;

// Compatability with dataset reducer
const dataSpaceDatasetToDataGrid = (dataSpaceDataset: Dataset): DatasetDataGrid => ({
  name: dataSpaceDataset.name,
  version: dataSpaceDataset.version,
  timestamp: 0,
  renamed_from: null,
  renamed_to: null,
  forgotten: dataSpaceDataset.status === NavigationItemStatus.HIDDEN,
  dataset_id: dataSpaceDataset.pipeliner_dataset_id,
});

// DataGrid's currentDataset maps to DataSpace's
// currentWorkerDataset
export const selectCurrentDataset = createSelector(
  [selectsIsOnDataChatSessionPage, selectCurrentWorkerDataset, selectCurrentDatasetInfo],
  (isDataChatSession, currentWorkerDataset, currentDatasetInfo) => {
    if (!isDataChatSession) return currentDatasetInfo;
    return currentWorkerDataset ? dataSpaceDatasetToDataGrid(currentWorkerDataset) : null;
  },
);

export const selectCurrentDatasetColumns = createSelector(
  [selectDatasetStorage, selectCurrentDataset],
  (datasetStorage: SessionDatasetStorage, currentDataset) =>
    datasetStorage?.[currentDataset?.dataset_id || '']?.columns ?? [],
);

/** Selects the columns from the current dataset in focus in the dataspace */
export const selectCurrectDataspaceDatasetColumns = createSelector(
  [selectDatasetStorage, selectCurrentDatasetDataSpace],
  (datasetStorage, dataspaceDataset) => {
    return datasetStorage?.[dataspaceDataset?.pipeliner_dataset_id ?? '']?.columns ?? [];
  },
);

export const selectCurrentDatasetPair = createSelector(
  [selectsIsOnDataChatSessionPage, selectCurrentWorkerDataset, selectCurrentDatasetPairDataGrid],
  (isDataChatSession, currentWorkerDataset, currentDatasetPairDataGrid) =>
    isDataChatSession
      ? [currentWorkerDataset?.name ?? '', currentWorkerDataset?.version ?? 0]
      : currentDatasetPairDataGrid,
);

// DataGrid's selected dataset name and version
// Map to our currentDataset
export const selectSelectedDatasetName = createSelector(
  [
    selectsIsOnDataChatSessionPage,
    selectCurrentDatasetDataSpace,
    selectSelectedDatasetNameDataGrid,
  ],
  (isDataChatSession, currentDatasetDataSpace, selectedDatasetNameDataGrid) =>
    isDataChatSession ? currentDatasetDataSpace?.name ?? '' : selectedDatasetNameDataGrid,
);
export const selectSelectedDatasetVersion = createSelector(
  [
    selectsIsOnDataChatSessionPage,
    selectCurrentDatasetDataSpace,
    selectSelectedDatasetVersionDataGrid,
  ],
  (isDataChatSession, currentDatasetDataSpace, selectedDatasetVersionDataGrid) =>
    isDataChatSession ? currentDatasetDataSpace?.version ?? 0 : selectedDatasetVersionDataGrid,
);

export const selectVisibleDatasetsByNameVersion = createSelector(
  [selectsIsOnDataChatSessionPage, selectVisibleDatasets, selectDatasetListDataGrid],
  (isDataChatSession, visibleDatasetsDataSpace, datasetListDataGrid) =>
    isDataChatSession
      ? datasetsByNameVersion(
          visibleDatasetsDataSpace.map((dataset) => dataSpaceDatasetToDataGrid(dataset)),
        )
      : datasetListDataGrid,
);

export const selectHiddenDatasetsByNameVersion = createSelector(
  [selectsIsOnDataChatSessionPage, selectHiddenDatasets, selectHiddenDatasetList],
  (isDataChatSession, hiddenDatasetsDataSpace, hiddenDatasetListDataGrid) =>
    isDataChatSession
      ? datasetsByNameVersion(
          hiddenDatasetsDataSpace.map((dataset) => dataSpaceDatasetToDataGrid(dataset)),
        )
      : hiddenDatasetListDataGrid,
);

export const selectSavingActiveDatasetsAs = (state: RootState) =>
  state.dataspace.savingActiveDatasetsAs;

export const selectShowSaveDatasetAs = (state: RootState) => state.dataspace.showSaveDatasetAs;

/** This selector returns the column rename map */
export const selectColumnRenameMap = (state: RootState) =>
  state.dataspace.editDataset.columnRenameMap;

/**
 * This selector checks if the column rename map is empty or not.
 * If the column rename map is empty, then the dataset is not being edited.
 *
 * The conditions in this selector should be updated if we extend editing in the future.
 */
export const selectIsEditingDataset = createSelector([selectColumnRenameMap], (columnRenameMap) => {
  return Object.keys(columnRenameMap).length > 0;
});

/** This selector will return a list of column names and replace the any keys that exist
 * in the columnRenameMap */
export const selectNewColumnNames = createSelector(
  [selectCurrectDataspaceDatasetColumns, selectColumnRenameMap],
  (columns, columnRenameMap) => {
    return columns.map((column) => {
      const newColumnName = columnRenameMap[column.name] || column.name;
      return newColumnName;
    });
  },
);
