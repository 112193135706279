import {
  actionChannel,
  call,
  fork,
  getContext,
  put,
  select,
  take,
  takeLatest,
} from 'typed-redux-saga';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import { selectCurrentNodeId, selectCurrentNodeOffset } from '../selectors/nodes.selector';
import { selectSession } from '../selectors/session.selector';
import {
  GetNodesRequestPayload,
  InsertMessageRequestPayload,
  getNodesFailure,
  getNodesRequest,
  getNodesSuccess,
  insertMessagesFailure,
  insertMessagesRequest,
  insertMessagesSuccess,
} from '../slices/nodes.slice';
import { getAccessToken } from './auth.saga';
import { selectAccessToken } from './selectors';
import { callAPIWithRetry } from './utils/retry';

export function* getNodesWorker(sessionId: string, nodeId: string, nodeOffset: number) {
  const accessToken = yield* call(getAccessToken);
  const nodesService = (yield* getContext(
    API_SERVICES.NODES,
  )) as ReduxSagaContext[API_SERVICES.NODES];
  return yield* callAPIWithRetry({
    apiFn: nodesService.getNodes,
    args: [accessToken, sessionId, nodeId, nodeOffset],
  });
}

export function* getNodesRequestWorker({ payload }: { payload: GetNodesRequestPayload }) {
  const { refreshFromNode } = payload;
  try {
    const sessionId = payload.sessionId || (yield* select(selectSession));
    if (!sessionId) {
      throw new Error('No session id');
    }

    const currentNodeId = payload.nodeId || (yield* select(selectCurrentNodeId));
    // if we are refreshing from a node, we want to start from the beginning
    const currentNodeOffset = refreshFromNode
      ? 0
      : payload.offset || (yield* select(selectCurrentNodeOffset));
    const response = yield* call(getNodesWorker, sessionId, currentNodeId, currentNodeOffset);
    const { currentThread, messages, nodes, offset } = response.data;
    const newNodeId: string = currentThread[currentThread.length - 1];
    yield* put(
      getNodesSuccess({
        currentNodeId: newNodeId,
        currentNodeOffset: offset,
        messages,
        nodes,
        currentThread,
        refreshFromNode,
      }),
    );
  } catch (error) {
    if (error instanceof Error) yield* put(getNodesFailure({ error }));
  }
}

// inserts an array of messages
// if a parent is provided, the message is inserted into that node
// if no parent is provide, a new node is created with the given message.
export function* insertMessagesRequestWorker(action: { payload: InsertMessageRequestPayload[] }) {
  try {
    const nodesService = (yield* getContext(
      API_SERVICES.NODES,
    )) as ReduxSagaContext[API_SERVICES.NODES];
    const accessToken = yield* select(selectAccessToken);
    const sessionId = yield* select(selectSession);
    if (!sessionId) {
      throw new Error('No session id');
    }

    const nodeIds: string[] = [];

    for (let i = 0; i < action.payload.length; i++) {
      const { parent, type, message, metadata } = action.payload[i];
      const response = yield* call(
        nodesService.insertMessage,
        accessToken,
        sessionId,
        type,
        message,
        parent,
        metadata,
      );

      if (response?.data?.node_id) nodeIds.push(response.data.node_id);
    }

    yield* put(insertMessagesSuccess(nodeIds));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(insertMessagesFailure({ error }));
    }
  }
}

function* getNodesRequestWatcher() {
  const getNodesRequestChannel = yield* actionChannel(getNodesRequest);
  while (true) {
    const action = yield* take(getNodesRequestChannel);
    yield* call(getNodesRequestWorker, action);
  }
}

export default function* nodesSaga() {
  yield* fork(getNodesRequestWatcher);
  yield* takeLatest(insertMessagesRequest, insertMessagesRequestWorker);
}
