import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { PiFileSqlDuotone } from 'react-icons/pi';

/**
 * Button to open recipe for a conversation message.
 */
const ViewSQLAction = ({ id, onClick }) => {
  return (
    <IconButton data-testid={`${id}-icon-button`} size="small" onClick={onClick}>
      <PiFileSqlDuotone fontSize="inherit" />
    </IconButton>
  );
};

ViewSQLAction.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ViewSQLAction;
