/**
 * @fileoverview Contains typing for home_screen constants
 * This file is intended to help us slowly migrate ./home_screen.js to TypeScript
 */

import { AppDispatch } from '../configureStore';
import { createNewObjectRequest } from '../store/actions/home_screen.actions';
import { isFileUploadEnabled } from '../utils/env';
import { HomeObjects } from '../utils/homeScreen/types';
import {
  ButtonOptions,
  connectionButtonDefinition,
  uploadButtonDefinition,
} from './buttonDefinitions';

export const BETA_OBJECT_TYPES: { [key: string]: { popperText: string } } = {};

/**
 * Home object types that are in active development and should be hidden from non-development
 * environments
 */
export const EXPERIMENTAL_OBJECTS = new Set([
  HomeObjects.SNAPSHOT,
  HomeObjects.DATAFILE,
  HomeObjects.QUERY,
]);

/**
 * Home object types that will be removed in dev environments.
 * These may be replaced by types in EXPERIMENTAL_OBJECTS
 */
export const EXPERIMENTAL_REMOVED_OBJECTS: Set<HomeObjects> = new Set([]);

/**
 * Home object types that will be removed specifically from the new menu.
 * Entries in EXPERIMENTAL_REMOVED_OBJECTS do not need to be added.
 */
export const EXPERIMENTAL_REMOVED_FROM_NEW_OBJECTS = new Set([HomeObjects.CONNECTION]);

/**
 * Definition for the options in the Home's 'New' button dropdown
 * These define the 'New' button functionality in the Home screen
 * Only the 'Dataset' objectType has a dropdown, but other functionality could be added here
 */
export const homeNewButtonDict: (devMode: boolean) => Record<string, ButtonOptions> = (
  devMode,
) => ({
  [HomeObjects.SESSION]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.SESSION })),
    },
  },
  [HomeObjects.RECIPE]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.RECIPE })),
    },
  },
  [HomeObjects.INSIGHTS_BOARD]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.INSIGHTS_BOARD })),
    },
  },
  [HomeObjects.SNAPSHOT]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.SNAPSHOT })),
    },
  },
  [HomeObjects.CONNECTION]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.CONNECTION })),
    },
  },
  [HomeObjects.ALL]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.ALL })),
    },
  },
  [HomeObjects.FOLDER]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.FOLDER })),
    },
  },
  [HomeObjects.SEARCH]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.SEARCH })),
    },
  },
  [HomeObjects.QUERY]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.QUERY })),
    },
  },
  [HomeObjects.DATAFILE]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.DATAFILE })),
    },
  },
  [HomeObjects.ASK_SESSION]: {
    primary: {
      action: (dispatch: AppDispatch, args: unknown) =>
        dispatch(createNewObjectRequest({ args, objectType: HomeObjects.ASK_SESSION })),
    },
  },
  [HomeObjects.DATASET]: devMode
    ? {
        primary: {
          // Go directly to DB browser if file upload is disabled
          action: isFileUploadEnabled()
            ? connectionButtonDefinition.action
            : (dispatch: AppDispatch, args: unknown) => {
                dispatch(createNewObjectRequest({ args, objectType: HomeObjects.DATASET }));
              },
        },
      }
    : isFileUploadEnabled()
    ? {
        // Choose between connecting to a database or uploading a file
        dropdown: {
          Connect: connectionButtonDefinition,
          Upload: uploadButtonDefinition,
        },
      }
    : {
        // Can only connect
        primary: {
          action: connectionButtonDefinition.action,
        },
      },
});

export const HOME_SCREEN_DISABLED_ACTIONS_TOOLTIP =
  'Please wait until the current action is finished';
