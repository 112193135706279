import { RootState } from 'configureStore';

export const selectContext = (state: RootState) => state.context.context;
export const selectIsReplaying = (state: RootState) => state.context.isReplaying;
export const selectIsStepwise = (state: RootState) => state.context.isStepwise;
export const selectIsInterrupting = (state: RootState) => state.context.isInterrupting;
export const selectIsAdding = (state: RootState) => state.context.isAdding;
export const selectStartTime = (state: RootState) => state.context.startTime;
export const selectPendingDuration = (state: RootState) => state.context.pendingDuration;
export const selectLastStep = (state: RootState) => state.context.lastStep;
export const selectStepwiseMessage = (state: RootState) => state.context.stepwiseMessage;
