// List of recognized keywords
export const SQL_KEYWORDS: string[] = [
  // Keywords
  'ABORT',
  'ACCOUNT',
  'ACTION',
  'ALLOCATE',
  'ALL',
  'ALTER',
  'ALWAYS',
  'ANALYZE',
  'AND',
  'ANY',
  'ARE',
  'ARRAY_CONCAT',
  'ARRAY_LENGTH',
  'ARRAY_TO_STRING',
  'AS',
  'ASC',
  'ASENSITIVE',
  'ASYMMETRIC',
  'AT',
  'ATOMIC',
  'ATTACH',
  'AUTHORIZATION',
  'AUTOINCREMENT',
  'AVG',
  'BEGIN',
  'BETWEEN',
  'BOTH',
  'BY',
  'CALL',
  'CALLED',
  'CASCADED',
  'CASE',
  'CAST',
  'CHECK',
  'CLOSE',
  'COALESCE',
  'COLLATE',
  'COLUMN',
  'COMMENT',
  'COMMIT',
  'CONDITION',
  'CONFLICT',
  'CONNECT',
  'CONSTRAINT',
  'CORRESPONDING',
  'COUNT',
  'CREATE',
  'CROSS',
  'CUBE',
  'CURRENT',
  'CURRENT_CATALOG',
  'CURRENT_DEFAULT_TRANSFORM_GROUP',
  'CURRENT_PATH',
  'CURRENT_ROLE',
  'CURRENT_SCHEMA',
  'CURRENT_TRANSFORM_GROUP_FOR_TYPE',
  'CURRENT_USER',
  'CURSOR',
  'CYCLE',
  'DATABASE',
  'DAY',
  'DECLARE',
  'DEFAULT',
  'DEFERRABLE',
  'DEFERRED',
  'DELETE',
  'DEREF',
  'DESC',
  'DESCRIBE',
  'DETERMINISTIC',
  'DETACH',
  'DISCONNECT',
  'DISTINCT',
  'DO',
  'DROP',
  'DYNAMIC',
  'EACH',
  'ELEMENT',
  'ELSE',
  'END',
  'END-EXEC',
  'ESCAPE',
  'EVERY',
  'EXCEPT',
  'EXCLUDE',
  'EXEC',
  'EXECUTE',
  'EXISTS',
  'EXPLAIN',
  'EXTERNAL',
  'FAIL',
  'FALSE',
  'FETCH',
  'FILTER',
  'FIRST',
  'FOLLOWING',
  'FOR',
  'FOREIGN',
  'FREE',
  'FROM',
  'FULL',
  'FUNCTION',
  'GENERATED',
  'GET',
  'GLOB',
  'GLOBAL',
  'GRANT',
  'GROUP',
  'GSCLUSTER',
  'HAVING',
  'HOLD',
  'HOUR',
  'IDENTITY',
  'IGNORE',
  'ILIKE',
  'IMMEDIATE',
  'IN',
  'INDEX',
  'INDEXED',
  'INDICATOR',
  'INITIALLY',
  'INNER',
  'INOUT',
  'INSENSITIVE',
  'INSERT',
  'INSTEAD',
  'INTERSECT',
  'INTO',
  'IS',
  'ISNULL',
  'ISSUE',
  'JOIN',
  'KEY',
  'LANGUAGE',
  'LARGE',
  'LAST',
  'LATERAL',
  'LEADING',
  'LEFT',
  'LIKE',
  'LIKE_REGEX',
  'LIMIT',
  'LOCAL',
  'MATCH',
  'MATERIALIZED',
  'MAX',
  'MEMBER',
  'MERGE',
  'METHOD',
  'MIN',
  'MINUTE',
  'MODIFIES',
  'MODULE',
  'MONTH',
  'NATURAL',
  'NEW',
  'NO',
  'NONE',
  'NOT',
  'NOTHING',
  'NOTNULL',
  'NULL',
  'NULLIF',
  'NULLS',
  'OF',
  'OFFSET',
  'OLD',
  'ON',
  'ONLY',
  'OPEN',
  'OR',
  'ORDER',
  'ORDINAL',
  'ORGANIZATION',
  'OTHERS',
  'OUT',
  'OUTER',
  'OVER',
  'OVERLAPS',
  'PARAMETER',
  'PARSE_BIGNUMERIC',
  'PARSE_NUMERIC',
  'PARTITION',
  'PLAN',
  'PRAGMA',
  'PRECEDING',
  'PREPARE',
  'PRIMARY',
  'PROCEDURE',
  'QUALIFY',
  'QUERY',
  'RAISE',
  'RANGE',
  'READS',
  'RECURSIVE',
  'REF',
  'REFERENCES',
  'REFERENCING',
  'REGEXP',
  'REINDEX',
  'RELEASE',
  'RESULT',
  'RETURN',
  'RETURNS',
  'REVOKE',
  'RIGHT',
  'RLIKE',
  'ROLLBACK',
  'ROLLUP',
  'ROW',
  'ROWS',
  'SAFE_CAST',
  'SAFE_ORDINAL',
  'SAFE_OFFSET',
  'SAMPLE',
  'SAVEPOINT',
  'SCHEMA',
  'SCOPE',
  'SCROLL',
  'SEARCH',
  'SECOND',
  'SELECT',
  'SENSITIVE',
  'SESSION_USER',
  'SET',
  'SIMILAR',
  'SOME',
  'SPECIFIC',
  'SQLEXCEPTION',
  'SQLSTATE',
  'SQLWARNING',
  'START',
  'STATIC',
  'SUBMULTISET',
  'SUM',
  'SYMMETRIC',
  'SYSTEM',
  'SYSTEM_USER',
  'TABLE',
  'TABLESAMPLE',
  'TEMP',
  'TEMPORARY',
  'THEN',
  'TIES',
  'TIMEZONE_HOUR',
  'TIMEZONE_MINUTE',
  'TO',
  'TRAILING',
  'TRANSACTION',
  'TRANSLATION',
  'TREAT',
  'TRIGGER',
  'TRUE',
  'TRUNCATE',
  'TRY_CAST',
  'UESCAPE',
  'UNBOUNDED',
  'UNION',
  'UNIQUE',
  'UNKNOWN',
  'UNNEST',
  'UPDATE',
  'USER',
  'USING',
  'VACUUM',
  'VALUE',
  'VALUES',
  'VIEW',
  'VIRTUAL',
  'WHEN',
  'WHENEVER',
  'WHERE',
  'WINDOW',
  'WITH',
  'WITHIN',
  'WITHOUT',
  'YEAR',

  // Data types
  'ANY',
  'ARRAY',
  'BIGINT',
  'BINARY',
  'BIT',
  'BIT VARYING',
  'BLOB',
  'BOOL',
  'BOOLEAN',
  'BYTEINT',
  'CHAR',
  'CHARACTER',
  'CHARACTER VARYING',
  'DATE',
  'DATETIME',
  'DEC',
  'DECIMAL',
  'DOUBLE',
  'DOUBLE PRECISION',
  'ENUM',
  'FIXED',
  'FLOAT',
  'FLOAT4',
  'FLOAT8',
  'FLOAT64',
  'GEOGRAPHY',
  'GEOMETRY',
  'INT',
  'INT1',
  'INT2',
  'INT3',
  'INT4',
  'INT8',
  'INTEGER',
  'INTERVAL',
  'JSON',
  'JSONB',
  'LONGBLOB',
  'LONGTEXT',
  'MEDIUMBLOB',
  'MEDIUMINT',
  'MEDIUMTEXT',
  'MIDDLEINT',
  'NATIVE CHARACTER',
  'NCHAR',
  'NUMBER',
  'NUMERIC',
  'NVARCHAR',
  'OBJECT',
  'PRECISION',
  'REAL',
  'SMALLINT',
  'STRING',
  'TEXT',
  'TIME',
  'TIMESTAMP',
  'TIMESTAMP_LTZ',
  'TIMESTAMP_NTZ',
  'TIMESTAMP_TZ',
  'TIMESTAMPTZ',
  'TINYBLOB',
  'TINYINT',
  'TINYTEXT',
  'VARBINARY',
  'VARCHAR',
  'VARCHARACTER',
  'VARIANT',
  'VARYING',
  'VARYING CHARACTER',
  'XML',
  'ZONE',
];
