import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import expireIn from 'redux-persist-transform-expire-in';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import createMigrate from 'redux-persist/es/createMigrate';
import accessDialogSlice from '../slices/accessDialog.slice';
import catalogSlice from '../slices/catalog.slice';
import chartRendersSlice from '../slices/chartRenders.slice';
import chartspaceSlice from '../slices/chartspace.slice';
import contextReducer from '../slices/context.slice';
import dataAssistantSlice from '../slices/dataAssistant.slice';
import dataspaceSlice from '../slices/dataspace.slice';
import dataspaceTableSlice from '../slices/dataspaceTable.slice';
import dbBrowserSlice from '../slices/dbBrowser.slice';
import initialUtteranceReducer from '../slices/initial_utterance.slice';
import integrationsSlice from '../slices/integrations.slice';
import loadCardReducer from '../slices/loadCard.slice';
import nodesSlice from '../slices/nodes.slice';
import oauthReducer from '../slices/oauth.slice';
import pipelinerDatasetsSlice from '../slices/pipelinerDatasets/slice';
import profilePicturesSlice from '../slices/profilePictures.slice';
import recommendationsSlice from '../slices/recommendations.slice';
import sessionReducer from '../slices/session.slice';
import taskSlice from '../slices/task.slice';
import viewSqlSlice from '../slices/viewSql.slice';
import apikeysReducer from './apikeys.reducer';
import appsReducer from './apps.reducer';
import askAvaReducer from './askAva.reducer';
import authReducer from './auth.reducer';
import browserTypeReducer from './browserType.reducer';
import bulkChartsExportReducer from './bulk_charts_export.reducer';
import chartReducer from './chart.reducer';
import chartBuilderReducer from './chart_builder.reducer';
import chartSelectionReducer from './chart_selection.reducer';
import chatReducer from './chat.reducer';
import configReducer from './config.reducer';
import connectionReducer from './connection.reducer';
import contactFormReducer from './contact_form.reducer';
import credentialsReducer from './credentials.reducer';
import crossFilterReducer from './crossFilter.reducer';
import dashboardReducer from './dashboard.reducer';
import datasetReducer from './dataset.reducer';
import datasetCreatorReducer from './datasetCreator.reducer';
import dcplotReducer from './dcplot.reducer';
import dialogReducer from './dialog.reducer';
import drillThroughReducer from './drill_through.reducer';
import editorReducer from './editor.reducer';
import embedReducer from './embed.reducer';
import examplesReducer from './examples.reducer';
import explorerReducer from './explorer.reducer';
import expressionsReducer from './expressions.reducer';
import fileDownloadReducer from './file_download.reducer';
import fileManagerReducer from './file_manager.reducer';
import graphModeReducer from './graphMode.reducer';
import headerReducer from './header.reducer';
import homeScreenReducer from './home_screen.reducer';
import insightsBoardReducer from './insights_board.reducer';
import invoiceReducer from './invoice.reducer';
import licenseReducer from './license.reducer';
import menusReducer from './menus.reducer';
import messagesReducer from './messages.reducer';
import modelReducer from './model.reducer';
import notificationReducer from './notification.reducer';
import organizationReducer from './organization.reducer';
import pollingReducer from './poll.reducer';
import popoutReducer from './popout.reducer';
import replayControllerReducer from './replay_controller.reducer';
import serverStatusReducer from './server_status.reducer';
import settingsReducer from './settings.reducer';
import shareReducer from './share.reducer';
import snackbarsReducer from './snackbars.reducer';
import snapshotReducer from './snapshot.reducer';
import subscriptionCheckFreeTrialReducer from './subscriptionCheckFreeTrial.reducer';
import subscriptionTiersReducer from './subscriptionTiers.reducer';
import subscriptionsReducer from './subscriptions.reducer';
import suggestionsReducer from './suggestions.reducer';
import tableObjectReducer from './tableObject.reducer';
import toastReducer from './toast.reducer';
import uploadReducer from './upload.reducer';
import userFeedbackReducer from './userFeedback.reducer';
import utteranceReducer from './utterance.reducer';
import utteranceComposerReducer from './utterance_composer.reducer';
import utterancesPreviewReducer from './utterances_preview.reducer';
import viewLineageReducer from './viewSlicedWorkflow.reducer';
import windowReducer from './window.reducer';
import workspacev2Reducer from './workspacev2.reducer';

const SESSION_STORAGE = 'session';

/**
 * Returns either localStorage or sessionStorage depending on the client configuration.
 * Determined by environment variable set at build time.
 * TODO find a way to make this configurable at runtime. May be difficult
 * without significant refactor.
 *
 */
const getConfigStorage = () => (process.env.STORAGE === SESSION_STORAGE ? sessionStorage : storage);

const authPersistConfig = {
  key: 'auth',
  storage: getConfigStorage(),
  whitelist: [
    'user',
    'name',
    'accessToken',
    'accessExp',
    'refreshToken',
    'refreshExp',
    'email',
    'isAuthenticated',
  ],
};

// milliseconds*seconds*minutes*hours
const expireTime = 1000 * 60 * 60 * 48; // expire in 2 days
const expireKey = 'hasSeenWarning';

const browserTypePersistConfig = {
  key: 'browserType',
  storage: getConfigStorage(),
  whitelist: ['hasSeenWarning'],
  transforms: [expireIn(expireTime, expireKey, false)],
};

const chartPersistConfig = {
  key: 'chart',
  storage: getConfigStorage(),
  whitelist: ['persistedTabOrder'],
};

const subscriptionsPersistConfig = {
  key: 'subscriptionPopup',
  storage: getConfigStorage(),
  whitelist: [],
};

const graphModePersistConfig = {
  key: 'graphMode',
  storage: getConfigStorage(),
  whitelist: ['direction'],
};

const tableObjectPersistConfig = {
  key: 'tableObjectReducer',
  storage: getConfigStorage(),
};

const askAvaPersistConfig = {
  key: 'askAva',
  storage: getConfigStorage(),
  blacklist: ['requestInQueue', 'requestingGeneratedCode', 'generatingSolution'],
};

const sessionMigrations = {
  1: (state) => {
    // migration clear out device state
    return {
      ...state,
      navigationTab: {},
      detailPanelStatus: {},
    };
  },
};

const sessionPersistConfig = {
  key: 'session',
  storage: getConfigStorage(),
  whitelist: ['navigationTab', 'detailPanelStatus'],
  version: 1,
  migrate: createMigrate(sessionMigrations, { debug: false }),
};

const profilePicturesPersistConfig = {
  key: 'profilePictures',
  storage: getConfigStorage(),
  whitelist: ['profilePictures'],
};

const recommendationsPersistConfig = {
  key: 'recommendations',
  storage: getConfigStorage(),
  whitelist: ['recommendations', 'sessionId'],
};

const dataSpaceMigrations = {
  1: (state) => ({
    ...state,
    // This key was previously an array
    datasetOrder: {},
  }),
};
const dataSpacePersistConfig = {
  key: 'dataSpace',
  storage: getConfigStorage(),
  whitelist: ['datasetOrder'],
  version: 1,
  migrate: createMigrate(dataSpaceMigrations, { debug: false }),
};

const dataSpaceTablePersistConfig = {
  key: 'dataSpaceTable',
  storage: getConfigStorage(),
};

export default (routerReducer) =>
  combineReducers({
    accessDialog: accessDialogSlice,
    auth: persistReducer(authPersistConfig, authReducer), // Auth must always be first to ensure that authentication is persisted first.
    apikeys: apikeysReducer,
    apps: appsReducer,
    askAva: persistReducer(askAvaPersistConfig, askAvaReducer),
    browserType: persistReducer(browserTypePersistConfig, browserTypeReducer),
    bulkExport: bulkChartsExportReducer,
    catalog: catalogSlice,
    config: configReducer,
    connection: connectionReducer,
    contactForm: contactFormReducer,
    context: contextReducer,
    chart: persistReducer(chartPersistConfig, chartReducer),
    chartBuilder: chartBuilderReducer,
    chartRenders: chartRendersSlice,
    chartSelection: chartSelectionReducer,
    chartspace: chartspaceSlice,
    chat: chatReducer,
    credentials: credentialsReducer,
    crossFilter: crossFilterReducer,
    dashboard: dashboardReducer,
    dataAssistant: dataAssistantSlice,
    dataset: datasetReducer,
    dataspace: persistReducer(dataSpacePersistConfig, dataspaceSlice),
    dataspaceTable: persistReducer(dataSpaceTablePersistConfig, dataspaceTableSlice),
    dbBrowser: dbBrowserSlice,
    datasetCreator: datasetCreatorReducer,
    dcplot: dcplotReducer,
    dialog: dialogReducer,
    download: fileDownloadReducer,
    drillThrough: drillThroughReducer,
    editor: editorReducer,
    embed: embedReducer,
    examples: examplesReducer,
    explorer: explorerReducer,
    expressions: expressionsReducer,
    fileManager: fileManagerReducer,
    graphMode: persistReducer(graphModePersistConfig, graphModeReducer),
    header: headerReducer,
    homeScreen: homeScreenReducer,
    initialUtterance: initialUtteranceReducer,
    insightsBoard: insightsBoardReducer,
    integrations: integrationsSlice,
    invoice: invoiceReducer,
    license: licenseReducer,
    loadCard: loadCardReducer,
    menus: menusReducer,
    messages: messagesReducer,
    model: modelReducer,
    nodes: nodesSlice,
    notification: notificationReducer,
    organization: organizationReducer,
    oauth: oauthReducer,
    pipelinerDatasets: pipelinerDatasetsSlice.reducer,
    polling: pollingReducer,
    popout: popoutReducer,
    profilePictures: persistReducer(profilePicturesPersistConfig, profilePicturesSlice),
    recommendations: persistReducer(recommendationsPersistConfig, recommendationsSlice),
    replayController: replayControllerReducer,
    router: routerReducer,
    serverStatus: serverStatusReducer,
    session: persistReducer(sessionPersistConfig, sessionReducer),
    settings: settingsReducer,
    snackbars: snackbarsReducer,
    share: shareReducer,
    snapshot: snapshotReducer,
    subscriptions: persistReducer(subscriptionsPersistConfig, subscriptionsReducer),
    subscriptionCheckFreeTrial: subscriptionCheckFreeTrialReducer,
    subscriptionTiers: subscriptionTiersReducer,
    suggestions: suggestionsReducer,
    tableObject: persistReducer(tableObjectPersistConfig, tableObjectReducer),
    task: taskSlice,
    toast: toastReducer,
    upload: uploadReducer,
    userFeedback: userFeedbackReducer,
    utterance: utteranceReducer,
    utteranceComposer: utteranceComposerReducer,
    utterancesPreview: utterancesPreviewReducer,
    viewSlicedWorkflow: viewLineageReducer,
    viewSql: viewSqlSlice,
    window: windowReducer,
    workspacev2: workspacev2Reducer,
  });
