import { NOT_FOUND } from 'http-status-codes';
import isEmpty from 'lodash/isEmpty';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE, push, replace } from 'redux-first-history';
import { REHYDRATE } from 'redux-persist';
import { all, call, cancel, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { getSessionInfo } from '../../api/session.api';
import { HOME_PAGE } from '../../constants';
import { HOME_OBJECTS } from '../../constants/home_screen';
import { POP, PUSH, REPLACE, paths } from '../../constants/paths';
import { RequestStatus } from '../../types/databaseBrowser.types';
import { putAuthenticated } from '../../utils/authenticate';
import { shouldRedirectLoginToSnowparkLogin } from '../../utils/env';
import {
  areAllObjectsRefreshingOrReceived,
  decodeUrlSearchParam,
  getSearchFiltersFromURLSearchParams,
} from '../../utils/home_screen';
import { initializeAskAva } from '../actions/askAva.action';
import { GET_PERMISSIONS_SUCCESS } from '../actions/auth.actions';
import { getDatasetList, resetDatasets } from '../actions/dataset.actions';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import { resetEditor, setEditorObjectInformation } from '../actions/editor.actions';
import { getExamples } from '../actions/examples.action';
import { setLastUpdate } from '../actions/graphMode.actions';
import {
  GET_HOME_SCREEN_OBJECTS_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_SUCCESS,
  cancelHomeObjectsRefresh,
  clearSearch,
  getHomeScreenObjectsRequest,
  searchRequest,
  setObjectFilters,
  setTab,
  submitSearch,
} from '../actions/home_screen.actions';
import {
  RELOAD_MESSAGES_SUCCESS,
  clearScreen,
  reloadMessages,
  sendMessageRequest,
} from '../actions/messages.actions';
import {
  getSeenNotificationsRequest,
  getUnseenNotificationsRequest,
} from '../actions/notification.action';
import { restartPollingMechanism, stopPollingMechanism } from '../actions/poll.actions';
import {
  UPDATE_USER_CONFIG_FAILURE,
  UPDATE_USER_CONFIG_SUCCESS,
  updateUserConfigRequest,
} from '../actions/settings.actions';
import { getSubscriptionTiersRequest } from '../actions/subscriptionTiers.actions';
import { getSubscriptionCustomerIdRequest } from '../actions/subscriptions.actions';
import { clearUtteranceComposer } from '../actions/utterance_composer.actions';
import { clearUtterancesPreview } from '../actions/utterances_preview.actions';
import { selectIntegrationsRequestStatus } from '../selectors/integrations.selector';
import { selectAppViewId, selectSession } from '../selectors/session.selector';
import { clearAccessors } from '../slices/accessDialog.slice';
import { resetContext } from '../slices/context.slice';
import { getAskFeedbackRequest } from '../slices/dataAssistant.slice';
import { getIntegrationsRequest } from '../slices/integrations.slice';
import {
  SESSION_TYPES,
  getSessionNameFailure,
  getSessionNameRequest,
  getSessionNameSuccess,
  initializeSession,
  resetSession,
  setSessionAppView,
  startSessionSuccess,
} from '../slices/session.slice';
import { raceGenerator } from './home_screen.saga';
import {
  allPermissions,
  selectAccessToken,
  selectCurrentAction,
  selectCurrentLocation,
  selectCurrentQuery,
  selectIsAuthenticated,
  selectIsSearchSubmitted,
  selectMessages,
  selectObjectRequestStatus,
  selectSubscriptionCustomerId,
  selectSubscriptionTiers,
  selectUserConfig,
} from './selectors';
import { loadDataChatSessionHelper } from './session.saga';
import { reloadNotFoundAlert } from './utils/alert-channels';
import { setStartupContext, waitForRestingContext } from './utils/context';
import { messagesShowActivity } from './utils/messages';
import { loadUserLevelRequests } from './utils/user_requests';

function* refreshAnyPage() {
  const tiers = yield select(selectSubscriptionTiers);
  if (tiers === null) yield put(getSubscriptionTiersRequest());
}

// yield actions that refresh the data on the home screen
function* refreshHomeScreen() {
  yield all([put(clearUtterancesPreview()), put(clearAccessors())]);
  let permissions = yield select(allPermissions);
  if (isEmpty(permissions)) {
    yield take(GET_PERMISSIONS_SUCCESS);
    permissions = yield select(allPermissions);
  }
  yield* putAuthenticated(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.ALL }));
  yield* putAuthenticated(getExamples());
  yield* putAuthenticated(getUnseenNotificationsRequest());
  yield* putAuthenticated(getSeenNotificationsRequest());
  yield put(stopPollingMechanism());
}

function* refreshSearchPage() {
  // yield all the refresh data actions
  yield* refreshHomeScreen();
  // wait until all home screen object have been recieved.
  let allObjectsReceived = false;
  while (!allObjectsReceived) {
    yield take(GET_HOME_SCREEN_OBJECTS_SUCCESS);
    const objectRequestStatus = yield select(selectObjectRequestStatus);
    allObjectsReceived = yield call(areAllObjectsRefreshingOrReceived, objectRequestStatus);
  }
  // grab the query and get the clean search filters
  const currentQuery = yield select(selectCurrentQuery);
  const urlParams = new URLSearchParams(currentQuery);
  const searchFilters = yield call(getSearchFiltersFromURLSearchParams, urlParams);
  const searchTerm = urlParams.get('term');
  const decodedSearchTerm = decodeUrlSearchParam(searchTerm);
  // set the search filters and search term. Make a search request.
  yield put(
    setObjectFilters({ onSearchBar: true, pushToUrl: false, filtersObject: searchFilters }),
  );
  yield put(searchRequest({ value: decodedSearchTerm }));
  const { success } = yield race({
    failure: take(SEARCH_FAILURE),
    success: take(SEARCH_SUCCESS),
  });
  // if search request is successful, submit the search.
  if (success) yield put(submitSearch({ refreshResults: false }));
}

/**
 * Handles URL acceses to the index page.
 * If authenticated, redirects to the user's default home page (web or ask).
 * Otherwise, reroutes to the Login page.
 */
export function* onIndexEnter() {
  const isAuthenticated = yield select(selectIsAuthenticated);
  if (!isAuthenticated) {
    yield put(replace(paths.login));
  } else {
    const userConfig = yield select(selectUserConfig);
    if (userConfig.defaultHomePage === HOME_PAGE.ASK) {
      yield put(replace(paths.ask));
    } else {
      yield put(replace(paths.home));
    }
  }
}

/**
 * Handles URL acceses to the Web Home page
 */
export function* onHomeEnter() {
  const isAuthenticated = yield select(selectIsAuthenticated);
  if (!isAuthenticated) {
    // Refactor (#32712): this redirection is only needed for tests, because
    // authenticated paths are wrapped in RequireAuth component (see App.jsx), which
    // automatically renders the Login page instead of redirecting the user.
    yield put(replace(paths.login));
  } else {
    // Fetch user oAuths if they have not already been fetched
    const oAuthRequestStatus = yield select(selectIntegrationsRequestStatus);
    if (oAuthRequestStatus === RequestStatus.Unrequested) {
      yield put(getIntegrationsRequest());
    }
    yield* refreshHomeScreen();
    yield put(setTab({ tab: HOME_OBJECTS.ALL, pushToUrl: false }));
    const isSearchSubmitted = yield select(selectIsSearchSubmitted);
    if (isSearchSubmitted) yield put(clearSearch());
  }
}

export function* onHomeScreenTabEnter() {
  const currentAction = yield select(selectCurrentAction);
  const currentQuery = yield select(selectCurrentQuery);
  const urlParams = new URLSearchParams(currentQuery);
  const tab = urlParams.get('tab') || '';
  const objectType = decodeUrlSearchParam(tab);
  const validObjectType = Object.values(HOME_OBJECTS).some((obj) => obj === objectType);
  if (validObjectType) {
    if (currentAction === POP || currentAction === REPLACE) {
      // if a user navigates to tab-specific URL by refreshing or using browser back button
      if (objectType === HOME_OBJECTS.SEARCH) {
        // if the url is the search url, refresh the search
        yield* refreshSearchPage();
      } else {
        // otherwise just do a normal refresh of the home screen
        yield* refreshHomeScreen();
      }
      // set tab in redux but don't push to path to url (path is already set)
      yield put(setTab({ tab: objectType, pushToUrl: false }));
    }
    if (objectType !== HOME_OBJECTS.SEARCH) {
      // if user navigates away from the seach tab, clear the search
      const isSearchSubmitted = yield select(selectIsSearchSubmitted);
      if (isSearchSubmitted) yield put(clearSearch());
    }
  } else {
    yield put(replace(paths.home));
  }
}

/**
 * Handles URL accesses to the Chat page into a specific session
 * @param {Object} params Contains the appId and sessionId
 */
export function* onChatEnter(params) {
  // Clear any messages that may be left in redux store
  yield put(clearScreen());
  const isAuthenticated = yield select(selectIsAuthenticated);
  const currentAction = yield select(selectCurrentAction);
  if (isAuthenticated && currentAction === POP) {
    // only yield below actions when change/refresh urls or back/foward other urls
    yield all([
      put(resetDatasets()),
      put(resetContext()),
      put(resetSession()),
      put(clearUtteranceComposer()),
    ]);
  }
  const accessToken = yield select(selectAccessToken);
  const urlParams = new URLSearchParams(window.location.search);
  const utterance = urlParams.get('utt');
  const type = params.sessionType !== undefined ? params.sessionType : SESSION_TYPES.CHAT;
  const sessionId = params.session !== undefined ? params.session : urlParams.get('session');
  const appId = params.id;
  const startTime = new Date().getTime();
  if (!sessionId) {
    // Throw error as user is not expected to reach this url
    // It will then trigger an alert box reminding users the session id is missing
    throw new Error('Session ID is missing');
  }
  if (!isAuthenticated) {
    // We have not received the response from server about whether the user is properly authorized
    // Thus, stop. Later, after the saga worker related to the missing field has finished its job
    // The worker will invoke the locationChangeWorker again so that the normal flow continues.
    return;
  }
  // Validify the session Id
  // If session does not exist, error will be thrown
  let sn;
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield getSessionInfo(accessToken, sessionId);
    sn = response.data;
  } catch (error) {
    if (error.response.status === NOT_FOUND) {
      yield* reloadNotFoundAlert();
      return;
    }
    throw error;
  }

  // Begin session preparation
  yield call(setStartupContext);

  // Initialize the session if it has not already been done
  const currentSession = yield select(selectSession);
  if (!currentSession) {
    yield put(initializeSession({ session: sessionId, appId }));
    // Fetch and set session name if exists
    yield put(getSessionNameRequest({ sessionId }));
  }
  const currentView = yield select(selectAppViewId);
  if (!currentView && sn.appViewId) {
    yield put(setSessionAppView({ appViewId: sn.appViewId }));
  }
  // Reset the ask ava state (must follow initializeSession)
  yield put(initializeAskAva());
  // Initialize the graph mode's lastUpdate variable
  if (sn.created_date) {
    yield put(setLastUpdate(sn.created_date));
  }

  // Save current session - as a side effect, reactivate receive message queue
  yield put(startSessionSuccess({ sessionType: type, startTime }));
  // Reload messages
  yield put(reloadMessages({}));
  yield take(RELOAD_MESSAGES_SUCCESS);
  // start message polling - start to putting request into the queue periodically
  yield put(restartPollingMechanism());
  // load existing datasets for the session only if there has been activity
  const messages = yield select(selectMessages);
  if (messagesShowActivity(messages)) {
    yield put(getDatasetList());
  }

  // Send any utterance that go with the url
  if (utterance) {
    yield call(waitForRestingContext);
    yield put(sendMessageRequest({ message: utterance, sessionID: currentSession }));
  }
}

/**
 * Handles URL accesses to the DataChat V1 page for a specific session
 * @param {Object} params Contains the sessionId
 */
// NOTE - the logic in onSessionEnter is copied from onChatEnter. Some actions
// in onChatEnter are not dispatched in onSessionEnter because they are not
// relevant to the DataChat V1 page.
// when ChatApp component is removed, onChatEnter can be removed as well
export function* onSessionEnter(params) {
  // Clear any messages that may be left in redux store
  yield put(clearScreen());
  const isAuthenticated = yield select(selectIsAuthenticated);
  const currentAction = yield select(selectCurrentAction);
  if (isAuthenticated && currentAction === POP) {
    // only yield below actions when change/refresh urls or back/foward other urls
    yield all([
      put(resetDatasets()),
      put(resetContext()),
      put(resetSession()),
      put(clearUtteranceComposer()),
    ]);
  }
  const sessionId = params.id;
  const startTime = new Date().getTime();
  if (!sessionId) {
    // Throw error as user is not expected to reach this url
    // It will then trigger an alert box reminding users the session id is missing
    throw new Error('Session ID is missing');
  }
  if (!isAuthenticated) {
    // We have not received the response from server about whether the user is properly authorized
    // Thus, stop. Later, after the saga worker related to the missing field has finished its job
    // The worker will invoke the locationChangeWorker again so that the normal flow continues.
    return;
  }
  // Validate the session Id
  // If session does not exist, error will be thrown
  yield put(getSessionNameRequest({ sessionId }));
  const { success, failure } = yield race({
    success: take(getSessionNameSuccess.type),
    failure: take(getSessionNameFailure.type),
  });
  if (failure) {
    if (failure.error?.response?.status === NOT_FOUND) {
      yield call(reloadNotFoundAlert);
      return;
    }
  }

  // Begin session preparation
  yield call(setStartupContext);

  const { sessionData } = success.payload;

  const currentView = yield select(selectAppViewId);
  if (!currentView && sessionData?.appViewId) {
    yield put(setSessionAppView({ appViewId: sessionData.appViewId }));
  }

  // Initialize the session if it has not already been done
  const currentSession = yield select(selectSession);
  if (!currentSession) {
    yield put(initializeSession({ session: sessionId, appId: sessionData?.appId }));
  }

  // Save current session - as a side effect, reactivate receive message queue
  yield put(startSessionSuccess({ sessionType: SESSION_TYPES.CHAT, startTime }));
  // Reload messages
  yield put(reloadMessages({}));
  yield take(RELOAD_MESSAGES_SUCCESS);
  // get the feedback records for data assistant
  yield put(getAskFeedbackRequest({ sessionId }));
  // start message polling - start to putting request into the queue periodically
  yield put(restartPollingMechanism());

  yield call(loadDataChatSessionHelper);
}

/**
 * Handles actions to be fired upon entering the workflow editor
 *
 * @param {Object} params url parameters
 */
export function* onWorkflowEditorEnter({ objectType, objectId, autoSave }) {
  const isAuthenticated = yield select(selectIsAuthenticated);
  if (isAuthenticated) {
    // If entering the editor for the first time OR switching the workflow that is being displayed...
    // Ensure session, context, chat, etc. are reset
    yield all([
      put(clearScreen()),
      put(resetDatasets()),
      put(resetContext()),
      put(resetSession()),
      put(clearUtteranceComposer()),
      put(resetEditor()),
    ]);
    // Fetch user configurations
    yield* loadUserLevelRequests();
    // Fetch the specified workflow
    yield put(setEditorObjectInformation({ objectType, objectId, autoSave }));
  }
}

export function* checkDevMode() {
  const userConfig = yield select(selectUserConfig);
  const currentQuery = yield select(selectCurrentQuery);
  const urlParams = new URLSearchParams(currentQuery);
  const devMode = urlParams.get('dev_mode');
  if (devMode) {
    if (devMode.toLowerCase() === 'true') {
      const config = {
        ...userConfig,
        experimentFlag: true,
      };
      yield put(updateUserConfigRequest({ config }));
    } else {
      const config = {
        ...userConfig,
        experimentFlag: false,
      };
      yield put(updateUserConfigRequest({ config }));
    }
    // wait for a success or failure action before executing the remainder of
    // route specific actions. We do this because some requests are conditional
    // on the results of the user config.
    yield* raceGenerator(UPDATE_USER_CONFIG_SUCCESS, UPDATE_USER_CONFIG_FAILURE);
  }
}

/**
 * Redirects to the home screen if the user is authenticated.
 * If the user is not authenticated, they stay on the current page.
 */
export function* routeToHomescreenIfAuthenticated() {
  const isAuthenticated = yield select(selectIsAuthenticated);
  // Do not redirect if we are on the page origin/web/reset_password/confirm
  const currentLocation = yield select(selectCurrentLocation);
  if (isAuthenticated && currentLocation !== paths.resetPassword) {
    yield put(push(paths.index));
  } else if (shouldRedirectLoginToSnowparkLogin()) {
    yield put(push(paths.snowflakeLogin));
  }
}

/**
 * Filters between URLs acceses
 */
export function* locationChangeWorker() {
  const isAuthenticated = yield select(selectIsAuthenticated);
  // Upon authenticated entry of any route, perform user-specific initialization.
  const currentLocation = yield select(selectCurrentLocation);
  if (isAuthenticated) {
    const currentAction = yield select(selectCurrentAction);
    if (currentLocation !== paths.homeScreenTabs || currentAction !== PUSH) {
      // Don't run these actions if a user pushes to an object type tab.
      yield* loadUserLevelRequests();

      // Fetch and check for existing subscriber or new subscriber.
      const subscriptionCustomerId = yield select(selectSubscriptionCustomerId);
      if (!subscriptionCustomerId) {
        yield put(getSubscriptionCustomerIdRequest());
      }
    }
    yield call(checkDevMode);
  }
  const getMatch = (path) => matchPath({ path }, currentLocation);
  let match;
  let urlIsWebHome = false;
  try {
    yield* refreshAnyPage();

    /* eslint-disable no-cond-assign */
    if ((match = getMatch(paths.index))) {
      yield* onIndexEnter();
    } else if ((match = getMatch(paths.home))) {
      urlIsWebHome = true;
      yield* onHomeEnter();
    } else if ((match = getMatch(paths.homeScreenTabs))) {
      urlIsWebHome = true;
      yield* onHomeScreenTabEnter();
    } else if ((match = getMatch(paths.sessionSnapshot))) {
      // Do nothing
    } else if ((match = getMatch(`${paths.chat}/:id`))) {
      yield* onChatEnter(match.params);
    } else if ((match = getMatch(`${paths.dataChatSession}/:id`))) {
      yield* onSessionEnter(match.params);
    } else if ((match = getMatch(paths.login))) {
      yield* routeToHomescreenIfAuthenticated();
    } else if ((match = getMatch(paths.register))) {
      yield* routeToHomescreenIfAuthenticated();
    } else if ((match = getMatch(paths.emailResetToken))) {
      yield* routeToHomescreenIfAuthenticated();
    } else if ((match = getMatch(paths.resetPassword))) {
      yield* routeToHomescreenIfAuthenticated();
    } else if ((match = getMatch(paths.registerAsk))) {
      yield* routeToHomescreenIfAuthenticated();
    } else if ((match = getMatch(paths.verifyEmail))) {
      yield* routeToHomescreenIfAuthenticated();
    } else if ((match = getMatch(paths.editorView(':objectType', ':objectId')))) {
      const params = new URLSearchParams(window.location.search);
      const autoSave = params.get('autoSave') === 'true';
      yield* onWorkflowEditorEnter({ ...match.params, autoSave });
    }
    // if the route is not the home screen, stop refreshing home objects.
    if (!urlIsWebHome) {
      yield put(cancelHomeObjectsRefresh());
    }
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
  }
  /* eslint-enable no-cond-assign */
}

/**
 * Watches for URL changes
 */
export default function* apiWatcher() {
  yield take(REHYDRATE);
  const task = yield fork(locationChangeWorker);
  yield takeLatest(LOCATION_CHANGE, locationChangeWorker);
  yield take(LOCATION_CHANGE);
  yield cancel(task);
}
