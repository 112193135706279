import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosHeaders } from 'axios';
import { EventChannel } from 'redux-saga';
import { call, getContext, put, take } from 'typed-redux-saga';
import { ChartRenderSSEMessage } from '../../api/chart.api';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import { ChartRenderParams, Renderers } from '../../types/render.types';
import {
  getRender,
  getRenderFailure,
  getRenderSuccess,
  updateRenderProgress,
} from '../slices/chartRenders.slice';
import { getAccessToken } from './auth.saga';
import { takeEachRender } from './utils/takeEachRender';

/**
 * A helper saga that listens to an event channel to handle SSE chart render events.
 *
 * @param eventChannel - The channel to listen to.
 * @param dcChartId - The chart id.
 * @param renderer - The renderer name.
 * @param dimensions - An object containing width and height (always passed).
 *
 * @returns boolean - Returns true if a render was received and we exited early.
 */
export function* handleRenderChannel(
  eventChannel: EventChannel<ChartRenderSSEMessage>,
  dcChartId: string,
  renderer: Renderers,
  dimensions: { width?: number; height?: number } = {},
): Generator<any, boolean, any> {
  while (true) {
    const data = yield* take(eventChannel);

    if (data.progress) {
      yield* put(
        updateRenderProgress({
          dcChartId,
          renderer,
          progress: data.progress,
          ...dimensions,
        }),
      );
    }

    if (data.data) {
      yield* put(
        getRenderSuccess({
          dcChartId,
          renderer,
          render: data.data,
          ...dimensions,
        }),
      );
      return true;
    }
  }
}

export function* getChartRenderWorker({ payload }: PayloadAction<ChartRenderParams>) {
  const { dcChartId, renderer, width, height } = payload;
  const accessToken = yield* call(getAccessToken);

  const chartingService = (yield* getContext(
    API_SERVICES.CHART,
  )) as ReduxSagaContext[API_SERVICES.CHART];

  const headers: Partial<AxiosHeaders> = {
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  };

  let eventChannel: EventChannel<ChartRenderSSEMessage> | null = null;
  let getSuccess: boolean = false;

  // Attempt to get a render with a GET request
  try {
    eventChannel = yield* call([chartingService, 'getChartRender'], payload, headers);
    getSuccess = yield* call(handleRenderChannel, eventChannel, dcChartId, renderer, {
      width,
      height,
    });
  } catch {
    // eslint-disable-next-line no-console
    console.log('no render found, attempting to trigger a render');
  } finally {
    if (eventChannel) eventChannel.close();
  }

  // Exit if we received a render
  if (getSuccess) return;

  // If we didn't receive a render, try to trigger a render with a PUT request
  // Then re-subscribe to events
  try {
    yield* call([chartingService, 'putChartRender'], payload, headers);

    eventChannel = yield* call([chartingService, 'getChartRender'], payload, headers);
    getSuccess = yield* call(handleRenderChannel, eventChannel, dcChartId, renderer, {
      width,
      height,
    });
  } catch (error) {
    yield* put(
      getRenderFailure({
        dcChartId,
        renderer,
        error,
        width,
        height,
      }),
    );
  } finally {
    if (eventChannel) eventChannel.close();
  }
}

export default function* chartRendersSaga() {
  yield takeEachRender(getRender, getChartRenderWorker);
}
