import {
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { SESSION_ROOT_NODE } from '../../constants';
import {
  GET_PUBLIC_CONVERSATION_REQUEST,
  getPublicConversationFailure,
  getPublicConversationSuccess,
} from '../actions/avaPublic.action';
import {
  GET_BASE_DATASETS_REQUEST,
  GET_DATASET_LIST_REQUEST,
  SAMPLE_DATASET_REQUEST,
  SAMPLE_SESSION_DATASET_REQUEST,
  getBaseDatasetsRequest,
  getDatasetList,
  resetDatasets,
} from '../actions/dataset.actions';
import { getNodesFailure, getNodesRequest, getNodesSuccess } from '../slices/nodes.slice';
import { getSessionNameRequest, initializeSession, resetSession } from '../slices/session.slice';
import {
  getBaseDatasetsRequestWorker,
  getDatasetListWorker,
  sampleSessionDatasetWorker,
} from './dataset.saga';
import { selectIsAuthenticated } from './selectors';
import { getSessionNameRequestWorker } from './session.saga';
import { selectDatasetRequest } from './utils/dataset';
import { takeEachDataset } from './utils/takeEachDataset';

// set correct redux state and make relevant api requests to get the public conversation
// for a given session id
export function* getPublicConversationRequestWorker({ sessionId }) {
  try {
    // reset the session reducer
    yield put(resetSession());
    // reset the datasets reducer
    yield put(resetDatasets());
    yield put(initializeSession({ session: sessionId }));
    yield put(getBaseDatasetsRequest());
    yield put(getSessionNameRequest({ sessionId }));
    // need to call the nodes api directly here instead of calling
    // getNodesRequest, because the nodes saga is not part of teh public saga watcher list.
    yield put(getNodesRequest({ sessionId, nodeId: SESSION_ROOT_NODE, offset: 0 }));
    const { failure } = yield race({
      success: take(getNodesSuccess),
      failure: take(getNodesFailure),
    });
    if (failure) throw new Error('Failed to get conversation');
    yield put(getPublicConversationSuccess());
    yield put(getDatasetList());
  } catch (error) {
    yield put(getPublicConversationFailure());
  }
}

// handle a getBaseDatasets request from a public conversation
// if the user is authenticated, do nothing (handled by dataset.saga.js)
export function* getBaseDatasetsFromPublicConversationWorker() {
  const isAuthenticated = yield select(selectIsAuthenticated);
  if (isAuthenticated) return;
  yield call(getBaseDatasetsRequestWorker);
}

// handle a select dataset request from a public conversation
// if the user is authenticated, do nothing (handled by dataset.saga.js)
export function* publicSelectDatasetRequestWorker(payload) {
  const isAuthenticated = yield select(selectIsAuthenticated);
  if (isAuthenticated) return;
  yield call(selectDatasetRequest, { ...payload });
}

// handle a sample dataset request from a public conversation
// if the user is authenticated, do nothing (handled by dataset.saga.js)
export function* publicSampleSessionDatasetWorker(payload) {
  const isAuthenticated = yield select(selectIsAuthenticated);
  if (isAuthenticated) return;
  yield call(sampleSessionDatasetWorker, { ...payload });
}

// handle a getSessionName request from a public conversation
// if the user is authenticated, do nothing (handled by session.saga.js)
export function* publicGetSessionNameWorker({ payload: action }) {
  const { sessionId } = action;
  const isAuthenticated = yield select(selectIsAuthenticated);
  if (isAuthenticated) return;
  yield call(getSessionNameRequestWorker, { payload: { sessionId } });
}

export function* publicGetDatasetListWorker(payload) {
  const isAuthenticated = yield select(selectIsAuthenticated);
  if (isAuthenticated) return;
  yield call(getDatasetListWorker, { ...payload });
}

// watch these actions regardless of whether a user is logged in.
export default function* () {
  yield takeLeading(GET_PUBLIC_CONVERSATION_REQUEST, getPublicConversationRequestWorker);
  yield takeEachDataset(SAMPLE_DATASET_REQUEST, publicSelectDatasetRequestWorker);
  yield takeEvery(SAMPLE_SESSION_DATASET_REQUEST, publicSampleSessionDatasetWorker);
  yield takeLatest(GET_BASE_DATASETS_REQUEST, getBaseDatasetsFromPublicConversationWorker);
  yield takeLatest(getSessionNameRequest.type, publicGetSessionNameWorker);
  yield takeLatest(GET_DATASET_LIST_REQUEST, publicGetDatasetListWorker);
}
