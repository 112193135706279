import StopIcon from '@mui/icons-material/Stop';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CANCELLABLE_CONTEXTS } from '../../../constants';
import { selectContext, selectIsInterrupting } from '../../../store/selectors/context.selector';
import {
  selectCancellingCurrentTask,
  selectCurrentTask,
} from '../../../store/selectors/task.selector';
import { cancelCurrentTask } from '../../../store/slices/task.slice';
import { CANCELLABLE_TASK_STATUSES } from '../../../types/task.types';
import './CancelButton.scss';

/** The Data Assistant's cancel button. */
const CancelButton = () => {
  const dispatch = useDispatch();
  const context = useSelector(selectContext);
  const currentTask = useSelector(selectCurrentTask);
  const isInterrupting = useSelector(selectIsInterrupting);
  const isCancellingCurrentTask = useSelector(selectCancellingCurrentTask);

  /** Whether context can be cancelled. */
  const isCancellableContext = CANCELLABLE_CONTEXTS.includes(context);

  /**
   * Whether the current task can be cancelled.
   *
   * The current task can be cancelled if any of the following are true:
   * - If there is no current task, it cannot be cancelled.
   * - The current task must be in a cancellable state.
   * */
  const canCancelCurrentTask = currentTask
    ? CANCELLABLE_TASK_STATUSES.includes(currentTask.taskStatus)
    : false;

  /**
   * Whether this button should be disabled.
   *
   * The button should be disabled if any of the following are true:
   * - If we are interrupting a skill, disable the button.
   * - If we are cancelling the current task, disable the button.
   * - If the context is not cancellable, disable the button.
   * - If the current task cannot be cancelled, disable the button.
   * */
  const disabled =
    isInterrupting || isCancellingCurrentTask || !isCancellableContext || !canCancelCurrentTask;

  /** Handles clicking the button. */
  const handleClick = () =>
    // dispatch action to cancel the current task
    dispatch(cancelCurrentTask());

  return (
    <Tooltip title="Cancel">
      <span>
        <Fab
          aria-label="Data Assistant Cancel Button"
          data-testid="CancelButton"
          disabled={disabled}
          color="primary"
          className="CancelButton"
          onClick={handleClick}
        >
          <StopIcon />
        </Fab>
      </span>
    </Tooltip>
  );
};

export default CancelButton;
