import React, { useEffect, useRef } from 'react';

interface SafeHTMLRendererProps {
  htmlString: string;
}

const SafeHTMLRenderer: React.FC<SafeHTMLRendererProps> = ({ htmlString }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.innerHTML = htmlString;
    }
  }, [htmlString]);

  return <div ref={divRef} />;
};

export default SafeHTMLRenderer;
