/**
 * @fileoverview Dataset compute saga
 *
 * Contains functions for handling pushing dataset compute requests to the backend.
 */

import isEmpty from 'lodash/isEmpty';
import { call, cancelled, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { filterDisabled, formatAggregate, formatFilter } from 'translate_dc_to_echart';
import { API_SERVICES } from '../../constants/api';
import {
  sampleSessionlessDatasetFailure,
  sampleSessionlessDatasetSuccess,
} from '../actions/chart.actions';
import {
  COMPUTE_DATASET_REQUEST,
  sampleSessionDatasetFailure,
  sampleSessionDatasetSuccess,
} from '../actions/dataset.actions';
import { selectSession } from '../selectors/session.selector';
import {
  selectAccessToken,
  selectSessionDatasetStorage,
  selectSessionlessDatasetStorage,
} from './selectors';
import { callWithPolling } from './utils/saga_utils';

/**
 * Perform a backend compute call for a dataset containing aggregates, bins, and/or filters
 * Currently supports ONLY aggregation
 *
 * @param {Function} callback Callback function to trigger on success
 * @param {Object} computeSpec The compute spec for the dataset:
 * { aggregate, bins, transforms }
 * @param {String} insightsBoardId (Sessionless) The insights board ID
 * @param {String} pipelinerDatasetId Pipeline ID of the dataset
 * @param {String} publicationId (Sessionless) The publication ID
 */
export function* computeDatasetWorker({
  callback,
  computeSpec,
  insightsBoardId,
  pipelinerDatasetId,
  publicationId,
  referenceString,
  workspaceUuid,
  useCache,
}) {
  const sessionId = yield select(selectSession);

  // Exit without required params
  if (pipelinerDatasetId == null || isEmpty(computeSpec)) {
    if (sessionId != null) {
      yield put(
        sampleSessionDatasetFailure({ computeSpec, pipelinerDatasetId, usedCompute: true }),
      );
    } else if (insightsBoardId != null || publicationId != null) {
      yield put(
        sampleSessionlessDatasetFailure({ computeSpec, pipelinerDatasetId, usedCompute: true }),
      );
    }
    return;
  }

  // Check if we have already stored the computed data within the reducer
  const sessionDatasetStorage = yield select(selectSessionDatasetStorage);
  const sessionlessDatasetStorage = yield select(selectSessionlessDatasetStorage);

  // Get the current data from the storage
  const currentData = sessionId
    ? { ...sessionDatasetStorage[referenceString] }
    : { ...sessionlessDatasetStorage[referenceString] };
  const currRowNum = currentData?.rows?.length ?? 0;

  // Determine if we already have the full dataset in the reducer state
  const totalRowCount = currentData?.totalRowCount;
  const hasFullDataset = totalRowCount && currRowNum >= totalRowCount;

  // If we already the whole dataset, then we don't need to request
  if (hasFullDataset) {
    if (sessionId != null)
      yield put(
        sampleSessionDatasetSuccess({
          computeSpec,
          pipelinerDatasetId,
          result: currentData,
          usedCompute: true,
        }),
      );
    else
      yield put(
        sampleSessionlessDatasetSuccess({
          computeSpec,
          pipelinerDatasetId,
          result: currentData,
          usedCompute: true,
        }),
      );
    if (callback) yield call(callback);
    return;
  }

  // Pull out the variables from the compute spec
  const { aggregate, bins, transforms } = computeSpec;

  // Get an abortController to cancel the api call
  const abortController = new AbortController();
  const accessToken = yield select(selectAccessToken);

  try {
    // Call for the computed dataset
    const datasetService = yield getContext(API_SERVICES.DATASET);
    const response = yield call(callWithPolling, {
      accessToken,
      fn: datasetService.computedDataset,
      signal: abortController.signal,
      args: {
        aggregate: formatAggregate(aggregate),
        bins: bins?.length ? filterDisabled(bins) : null,
        filters: formatFilter(transforms),
        pipelinerDatasetId,
        sessionId,
        insightsBoardId,
        publicationId,
        workspaceUuid,
        useCache,
      },
      retryOpts: { enabled: true },
    });

    const { data } = response;
    if (data?.rows) {
      // Call for success
      if (sessionId)
        yield put(
          sampleSessionDatasetSuccess({
            computeSpec,
            pipelinerDatasetId,
            result: data,
            usedCompute: true,
          }),
        );
      else
        yield put(
          sampleSessionlessDatasetSuccess({
            computeSpec,
            pipelinerDatasetId,
            result: data,
            usedCompute: true,
          }),
        );
      // Trigger the callback function if it exists
      if (callback) yield call(callback);
    } else if (data.Error) {
      // Throw an error if the endpoint returned one
      throw new Error('Backend Internal Error!');
    }
  } catch (error) {
    // Catch any errors and put a failure action
    if (sessionId)
      yield put(
        sampleSessionDatasetFailure({
          computeSpec,
          error,
          pipelinerDatasetId,
          usedCompute: true,
        }),
      );
    else
      yield put(
        sampleSessionlessDatasetFailure({
          computeSpec,
          error,
          pipelinerDatasetId,
          usedCompute: true,
        }),
      );
  } finally {
    // Cancel the api call if the saga was cancelled
    if (yield cancelled()) abortController.abort('Operation canceled');
  }
}

export default function* () {
  yield takeEvery(COMPUTE_DATASET_REQUEST, computeDatasetWorker);
}
