import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ViewSQLPayload, ViewSQLState } from '../../types/getSql.types';

const initialState: ViewSQLState = {
  isSqlModalOpen: false,
  isSqlDataLoading: false,
  sqlQuery: null,
  error: null,
};

const viewSqlSlice = createSlice({
  name: 'viewSql',
  initialState,
  reducers: {
    openViewSQL: {
      reducer: (state) => {
        state.isSqlModalOpen = true;
        state.isSqlDataLoading = true;
      },
      prepare: (payload: ViewSQLPayload = {}) => ({ payload }),
    },
    closeViewSQL: (state) => {
      state.isSqlModalOpen = false;
      state.sqlQuery = null;
    },
    retrieveSQLDataSuccess: (state, action: PayloadAction<{ compiled_query: string }>) => {
      state.sqlQuery = action.payload.compiled_query;
      state.isSqlDataLoading = false;
    },
    retrieveSQLDataFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.isSqlModalOpen = false;
      state.isSqlDataLoading = false;
      state.error = payload.error.message;
    },
    revokeSqlJobFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.error = payload.error.message;
    },
  },
});

export const {
  openViewSQL,
  closeViewSQL,
  retrieveSQLDataSuccess,
  retrieveSQLDataFailure,
  revokeSqlJobFailure,
} = viewSqlSlice.actions;

export default viewSqlSlice.reducer;
