import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST_ERROR, TOAST_LONG } from '../../constants/toast';
import { addToast } from '../../store/actions/toast.actions';
import { closeViewSQL } from '../../store/slices/viewSql.slice';
import { selectIsViewSQLLoading, selectViewSQLData } from '../../store/typedSelectors';
import { formatSQLQueryPreview } from '../../utils/formatting/sql_query_formatting';
import Modal from '../Modal/Modal';
import SafeHTMLRenderer from './SafeHTMLRenderer';
import './ViewSQLModal.scss';

const ViewSQLModal: React.FC = () => {
  const dispatch = useDispatch();
  const sqlQuery = useSelector(selectViewSQLData);
  const isLoading = useSelector(selectIsViewSQLLoading);
  const [copied, setCopied] = React.useState(false);
  const [formattedSQL, setFormattedSQL] = useState<string>('');

  useEffect(() => {
    if (!sqlQuery) return;
    const formatted = formatSQLQueryPreview(sqlQuery);
    // Show error toast if formatting failed
    setFormattedSQL(formatted.query || sqlQuery);
    if (formatted.error) {
      dispatch(
        addToast({
          toastType: TOAST_ERROR,
          length: TOAST_LONG,
          message: `Failed to format SQL query: ${formatted.error.message}`,
        }),
      );
    }
  }, [sqlQuery, dispatch]);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(sqlQuery);
  };

  return (
    <Modal
      whiteBackground
      title="View SQL Query"
      closeProps={{
        onClick: () => dispatch(closeViewSQL()),
        label: 'Close',
        button: true,
      }}
    >
      <div className="ViewSQL-Form">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div className="ViewSQL-Body">
            <div className="ViewSQL-Query">
              <SafeHTMLRenderer htmlString={formattedSQL} />
            </div>
            <Tooltip
              title={copied ? 'Copied!' : 'Copy to clipboard'}
              leaveDelay={copied ? 1000 : 0}
            >
              <IconButton
                className="ViewSQL-CopyButton"
                onClick={handleCopy}
                size="small"
                data-testid="copy-icon-button"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewSQLModal;
