import { AxiosResponse } from 'axios';
import { call, delay } from 'redux-saga/effects';
import { makePercentageBackoff } from '../../../utils/backoff_calculations';
import { callAPIWithRetry, CallAPIWithRetryArgs } from './retry';

type PollingFnArgs = [accessToken: string, signal: AbortSignal, args: object];
export type RetryOpts = {
  enabled?: boolean;
  // These type argument are only used in the properties we're omitting
  args?: Omit<CallAPIWithRetryArgs<any, any>, 'apiFn' | 'args'>;
};
type CallWithPollingParams<T = any> = {
  accessToken: string;
  // The API (function) to call
  fn: (accessToken: string, signal: AbortSignal, args: any) => Promise<AxiosResponse<T>>;
  // The abort signal for request cancellation
  signal: AbortSignal;
  // The arguments to pass to the function (fn)
  args: object;
  retryOpts?: RetryOpts;
};

type CallWithPollingResponse<T = any> = Generator<object, AxiosResponse<T>, AxiosResponse<T>>;

/**
 * Convenience function to call an API with or without retrying.
 * This is useful to make retrying optional in a call effect.
 *
 * @param retryOpts - if retry is enabled, and options passed to callAPIWithRetry
 * @param fn - function to call
 * @param args - args to pass to the function
 * @returns the result of the function call
 */
export function* callConditionalRetry<T, Args extends PollingFnArgs>(
  retryOpts: RetryOpts,
  fn: (...args: Args) => Promise<AxiosResponse<T>>,
  ...args: Args
): Generator<object, AxiosResponse<T>, AxiosResponse<T>> {
  return yield retryOpts.enabled
    ? call(callAPIWithRetry, { apiFn: fn, args, ...retryOpts.args })
    : call(fn, ...args);
}

/**
 * Calls an API (fn). Polls if the response is 202.
 */
export function* callWithPolling<T = any>(
  params: CallWithPollingParams<T>,
): CallWithPollingResponse<T> {
  const { accessToken, fn, signal, args, retryOpts = { enabled: false } } = params;
  const newArgs: PollingFnArgs = [accessToken, signal, args];

  let response = yield* callConditionalRetry(retryOpts, fn, ...newArgs);

  const percentageBackoff = makePercentageBackoff();
  while (response.status === 202) {
    yield delay(percentageBackoff());
    response = yield* callConditionalRetry(retryOpts, fn, ...newArgs);
  }

  return response;
}
