export const INITIALIZE_ASK_AVA = 'askAva/INITIALIZE_ASK_AVA';
export const RESET_ASK_AVA = 'askAva/RESET_ASK_AVA';
export const CLOSE_ASK_AVA_ALERT = 'askAva/CLOSE_ASK_AVA_ALERT';
export const SET_ASK_AVA_SESSION_CONTEXT = 'askAva/SET_ASK_AVA_SESSION_CONTEXT';
export const SUBMIT_ASK_AVA_QUERY_REQUEST = 'askAva/SUBMIT_ASK_AVA_QUERY_REQUEST';
export const SUBMIT_ASK_AVA_QUERY_SUCCESS = 'askAva/SUBMIT_ASK_AVA_QUERY_SUCCESS';
export const SUBMIT_ASK_AVA_QUERY_FAILURE = 'askAva/SUBMIT_ASK_AVA_QUERY_FAILURE';
export const SET_ASK_AVA_SELECTED_DATASETS = 'askAva/SET_ASK_AVA_SELECTED_DATASETS';
export const GENERATE_ASK_AVA_SOLUTION_SUCCESS = 'askAva/GENERATE_ASK_AVA_SOLUTION_SUCCESS';
export const SHOW_ASK_AVA_STEPS = 'askAva/SHOW_ASK_AVA_STEPS';
export const HIDE_ASK_AVA_STEPS = 'askAva/HIDE_ASK_AVA_STEPS';
export const SET_ASK_AVA_UPDATED_GEL = 'askAva/SET_ASK_AVA_UPDATED_GEL';
export const SET_ASK_AVA_CACHED_GEL = 'askAva/SET_ASK_AVA_CACHED_GEL';
export const SET_ASK_AVA_SOLUTION_FAILED = 'askAva/SET_ASK_AVA_SOLUTION_FAILED';
export const SET_ASK_AVA_SOLUTION_COMPLETED = 'askAva/SET_ASK_AVA_SOLUTION_COMPLETED';

export const SUBMIT_ASK_AVA_USER_FEEDBACK_REQUEST = 'askAva/SUBMIT_ASK_AVA_USER_FEEDBACK_REQUEST';
export const SUBMIT_ASK_AVA_USER_FEEDBACK_SUCCESS = 'askAva/SUBMIT_ASK_AVA_USER_FEEDBACK_SUCCESS';
export const SUBMIT_ASK_AVA_USER_FEEDBACK_FAILURE = 'askAva/SUBMIT_ASK_AVA_USER_FEEDBACK_FAILURE';

export const ASK_QUESTION_FROM_GRID_REQUEST = 'askAva/ASK_QUESTION_FROM_GRID_REQUEST';
export const ASK_QUESTION_FROM_GRID_SUCCESS = 'askAva/ASK_QUESTION_FROM_GRID_SUCCESS';
export const ASK_QUESTION_FROM_GRID_FAILURE = 'askAva/ASK_QUESTION_FROM_GRID_FAILURE';
export const SET_ASK_QUESTION_PARAMS = 'askAva/SET_ASK_QUESTION_PARAMS';

export const EDIT_ASK_AVA_CACHE_REQUEST = 'askAva/EDIT_ASK_AVA_CACHE_REQUEST';
export const EDIT_ASK_AVA_CACHE_SUCCESS = 'askAva/EDIT_ASK_AVA_CACHE_SUCCESS';
export const EDIT_ASK_AVA_CACHE_FAILURE = 'askAva/EDIT_ASK_AVA_CACHE_FAILURE';

export const REDIRECT_TO_BILLING_PAGE_REQUEST = 'askAva/REDIRECT_TO_BILLING_PAGE_REQUEST';

// opens the Ask Ava module in grid mode
export const initializeAskAva = () => ({
  type: INITIALIZE_ASK_AVA,
});

// Submits a request to the LLM given a query and language Type
export const submitAskAvaQueryRequest = ({ query, selectedDatasets, sessionId, skipCache }) => ({
  type: SUBMIT_ASK_AVA_QUERY_REQUEST,
  query,
  selectedDatasets,
  sessionId,
  skipCache,
});

// adds results of LLM request to redux
export const submitAskAvaQuerySuccess = ({
  requestId,
  generatedCode,
  language,
  score,
  prompt,
  usedDatasets,
  selectedDatasets,
  apiCode,
  question,
  vectorId,
  summary,
}) => ({
  type: SUBMIT_ASK_AVA_QUERY_SUCCESS,
  requestId,
  generatedCode,
  language,
  score,
  prompt,
  usedDatasets,
  selectedDatasets,
  apiCode,
  question,
  vectorId,
  summary,
});

// error action when llm request fails
export const submitAskAvaQueryFailure = ({ error }) => ({
  type: SUBMIT_ASK_AVA_QUERY_FAILURE,
  error,
});

// reset the redux state for the ask ava module
export const resetAskAva = () => ({
  type: RESET_ASK_AVA,
});

// close the alert within the ask ava module
export const closeAskAvaAlert = () => ({
  type: CLOSE_ASK_AVA_ALERT,
});

// reset the redux state for the ask ava module
export const setAskAvaSessionContext = ({ sessionId }) => ({
  type: SET_ASK_AVA_SESSION_CONTEXT,
  sessionId,
});

// submit a user feedback request with the feedback (1 | 0)
// and language type
export const submitAskAvaUserFeedbackRequest = ({ vote, language } = {}) => ({
  type: SUBMIT_ASK_AVA_USER_FEEDBACK_REQUEST,
  vote,
  language,
});

// action yielded when user feedback request completes successfully
export const submitAskAvaUserFeedbackSuccess = ({ feedback, language } = {}) => ({
  type: SUBMIT_ASK_AVA_USER_FEEDBACK_SUCCESS,
  feedback,
  language,
});

// action yielded when user feedback request does NOT complete successfully
export const submitAskAvaUserFeedbackFailure = ({ error }) => ({
  type: SUBMIT_ASK_AVA_USER_FEEDBACK_FAILURE,
  error,
});

export const setAskAvaSelectedDatasets = ({ datasets }) => ({
  type: SET_ASK_AVA_SELECTED_DATASETS,
  datasets,
});

// run action when last utterance in Ask Ava solution has completed successfull
// sets solutionComplete to true and generatingSolution to false
export const generateAskAvaSolutionSuccess = () => ({
  type: GENERATE_ASK_AVA_SOLUTION_SUCCESS,
});

// action to show LLM generated solution
export const showAskAvaSteps = () => ({
  type: SHOW_ASK_AVA_STEPS,
});

// action to hide LLM generated solution
export const hideAskAvaSteps = () => ({
  type: HIDE_ASK_AVA_STEPS,
});

// action which updates the ask ava solution based on editor actions that have been dispatched
export const setAskAvaUpdatedGel = ({ code }) => ({
  type: SET_ASK_AVA_UPDATED_GEL,
  code,
});

export const setAskAvaCachedGel = ({ code }) => ({
  type: SET_ASK_AVA_CACHED_GEL,
  code,
});

export const redirectToBillingRequest = () => ({
  type: REDIRECT_TO_BILLING_PAGE_REQUEST,
});

// action to set the ask ava solution failed flags
export const setAskAvaSolutionFailed = (isEdited) => ({
  type: SET_ASK_AVA_SOLUTION_FAILED,
  isEdited,
});

// action to set the ask ava solution completed flags
export const setAskAvaSolutionCompleted = (isEdited) => ({
  type: SET_ASK_AVA_SOLUTION_COMPLETED,
  isEdited,
});

// update the vector db cache
export const editAskAvaCacheRequest = ({ vectorId, metadata }) => ({
  type: EDIT_ASK_AVA_CACHE_REQUEST,
  vectorId,
  metadata,
});

export const editAskAvaCacheSuccess = () => ({
  type: EDIT_ASK_AVA_CACHE_SUCCESS,
});

export const editAskAvaCacheFailure = ({ error }) => ({
  type: EDIT_ASK_AVA_CACHE_FAILURE,
  error,
});

export const askQuestionFromGridRequest = ({ query, selectedDatasets, skipCache = false }) => ({
  type: ASK_QUESTION_FROM_GRID_REQUEST,
  query,
  selectedDatasets,
  skipCache,
});

export const askQuestionFromGridSuccess = () => ({
  type: ASK_QUESTION_FROM_GRID_SUCCESS,
});

export const askQuestionFromGridFailure = ({ error }) => ({
  type: ASK_QUESTION_FROM_GRID_FAILURE,
  error,
});

export const setAskQuestionParams = ({ query, selectedDatasets }) => ({
  type: SET_ASK_QUESTION_PARAMS,
  query,
  selectedDatasets,
});
