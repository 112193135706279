import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { CLASS, REPLAY_MESSAGES } from '../../../../../constants';
import { NodeTypes } from '../../../../../constants/nodes';
import { type Node } from '../../../../../store/slices/nodes.slice';

export default function GeneralResponseIcon(props: { node: Node }): JSX.Element | null {
  // get the node from the props
  const { node } = props;

  // if the child node is not a GEL response, return no icon
  if (node?.node_type !== NodeTypes.GELResponse) return null;

  /** The icon to display next to this general response.  */
  let icon: JSX.Element | null = null;

  // get the child node's "execution_successful" property from its metadata
  const success = node.metadata.execution_successful;

  // if the child node has children and we don't know if the execution was successful or not, return no icon
  if (node?.children?.length > 0 && success === undefined) return null;

  // if success is defined, return icon based of its value
  if (success !== undefined)
    return (
      <div className="GeneralResponse-Icon start">
        {success ? <Check className="success" /> : <Close className="error" />}
      </div>
    );

  // ! success is undefined, check if the child node is replaying

  // get whether the child node has a `"data": "Replay started."` message
  const isReplaying = node.messages.some((msg) => msg.data === REPLAY_MESSAGES.START);

  // if child node is replaying, return loading spinner
  if (isReplaying)
    return (
      <div className="GeneralResponse-Icon start">
        <CircularProgress size="100%" />
      </div>
    );

  // ! we're not replaying, set icon based on if child has a "class": "ready" message

  // get whether the child node has a `"class": "ready"` message
  const hasReadyMessage = node.messages.some((msg) => msg.class === CLASS.READY);

  // if we have a ready message, but don't know the execution status assume it failed
  if (hasReadyMessage && success === undefined) icon = <Close className="error" />;

  // if the child node doesn't have a ready message, set the icon to a loading spinner
  if (!hasReadyMessage) icon = <CircularProgress size="100%" />;

  // return icon
  return <div className="GeneralResponse-Icon start">{icon}</div>;
}
