import { PayloadAction } from '@reduxjs/toolkit';
import { ActionPattern, fork, ForkEffect } from 'redux-saga/effects';
import { shouldUseBackendCompute } from 'translate_dc_to_echart';
import { cancel, SagaGenerator, take } from 'typed-redux-saga';
import { getDatasetReferenceString } from '../../../components/ChartData/dataUtils';

type Task = { numRows: number; task: any };
type TaskRecord = Record<string, Task>;

/** Timeout for collecting & grouping data requests */
export const RESET_TIMEOUT = 2000;

/**
 * Custom effect for selecting dataset requests, binned for charts & tables
 *
 * Supported for the action types:
 *   SAMPLE_DATASET_REQUEST
 *   SAMPLE_SESSION_DATASET_REQUEST
 *   SAMPLE_SESSIONLESS_DATASET_REQUEST
 *
 * If we get multiple requests at once, only submit one table and one chart request for each dataset
 * This allows us to get quick sample responses for tables and slower, full responses for charts
 * We should always cancel the request with less numRows
 *
 * Refer to an example custom takeLatest() implementation for more information:
 * https://redux-saga.js.org/docs/api/#takelatestpattern-saga-args
 *
 * @param pattern The action pattern
 * @param worker The saga worker that we're calling with this effect
 * @param args The arguments that we called the action with
 */
export function takeEachDataset<P extends ActionPattern>(
  pattern: P,
  worker: (action: PayloadAction<unknown>) => any,
  ...args: any
): ForkEffect {
  return fork(function* (): SagaGenerator<never, any> {
    let otherTasks: TaskRecord = {};
    let tableTasks: TaskRecord = {};

    // Reset 2s after the first request. This allows us to consolidate multiple requests
    // If they are sent all at once (ex. On refresh or Re-opening a session)
    const startTasksResetTimer = () => {
      if (Object.keys({ ...otherTasks, ...tableTasks }).length === 0) {
        setTimeout(() => {
          otherTasks = {};
          tableTasks = {};
        }, RESET_TIMEOUT);
      }
    };

    while (true) {
      const action = yield take(pattern);
      const { dcChartId, computeSpec, isTable, numRows, pipelinerDatasetId } = action;

      const referenceString = getDatasetReferenceString({
        dcChartId,
        computeSpec,
        pipelinerDatasetId,
        usedCompute: shouldUseBackendCompute({
          computeSpec,
          numRows,
        }),
      });

      const prevTask = isTable ? tableTasks[referenceString] : otherTasks[referenceString];
      const prevnumRows = prevTask?.numRows;
      const requestingMoreRows =
        prevTask && (numRows > prevnumRows || (numRows === undefined && prevnumRows !== undefined));

      if (!prevTask) {
        // If the request is for a new referenceString, fork it
        startTasksResetTimer();
        const task = yield fork(worker, ...args.concat(action));
        if (isTable) tableTasks[referenceString] = { numRows, task };
        if (!isTable) otherTasks[referenceString] = { numRows, task };
      } else if (requestingMoreRows) {
        // Otherwise, if we're requesting more rows than prev, cancel prev and fork a new request
        yield cancel(prevTask.task);
        const task = yield fork(worker, ...args.concat(action));
        if (isTable) tableTasks[referenceString] = { numRows, task };
        if (!isTable) otherTasks[referenceString] = { numRows, task };
      }
    }
  });
}
