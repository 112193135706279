import { AxiosResponse } from 'axios';
import { ErrorHandler } from '../../types/errorHandler.types';
import { Client4xxErrorHandler } from './handlers/Client4xxErrorHandler';
import { ConnectionErrorHandler } from './handlers/ConnectionErrorHandler';
import { DatabaseAuthErrorHandler } from './handlers/DatabaseAuthErrorHandler';
import { OAuthErrorHandler } from './handlers/OAuthErrorHandler';

export const appServiceErrorHandlers: ErrorHandler[] = [
  new OAuthErrorHandler(),
  new DatabaseAuthErrorHandler(),
  new Client4xxErrorHandler(),
  // Add more error handlers here as needed
];

/**
 * Handle an error with the given error handlers. Throws if not handled.
 *
 * @param error The error to handle
 * @param errorHandlers The error handlers to use
 * @throws The error if it was not handled
 */
export function handleAPIError(error: unknown, errorHandlers: ErrorHandler[]): void {
  for (const handler of errorHandlers) {
    if (handler.canHandleAPIError(error)) {
      handler.handleAPIError(error);
      return;
    }
  }
  throw error;
}

export const connectionErrorHandlers: ErrorHandler[] = [new ConnectionErrorHandler()];
export function handleAPIConnectionError(error: unknown): void {
  handleAPIError(error, connectionErrorHandlers);
}

export async function handleAPICall<T>(
  apiCall: () => Promise<AxiosResponse<T>>,
  errorHandlers: ErrorHandler[],
): Promise<AxiosResponse<T>> {
  try {
    return await apiCall();
  } catch (error) {
    handleAPIError(error, errorHandlers);
    throw error;
  }
}

export async function handleDCAppServiceCall<T>(
  apiCall: () => Promise<AxiosResponse<T>>,
): Promise<AxiosResponse<T>> {
  return handleAPICall(apiCall, appServiceErrorHandlers);
}
