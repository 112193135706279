import moment from 'moment';
import { push } from 'redux-first-history';
import {
  call,
  cancelled,
  delay,
  getContext,
  put,
  putResolve,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  getResetPasswordTokenVerification,
  postLogout,
  postOtpLink,
  postRefresh,
  postResetPassword,
  postResetPasswordConfirm,
  snowflakeLogin,
  updateUserProfile,
} from '../../api/auth.api';
import { postLogUserAction } from '../../api/log_user_action.api';
import { postSendVerificationEmail, postVerifyEmail } from '../../api/verify_email.api';
import { HOME_PAGE } from '../../constants';
import { API_SERVICES } from '../../constants/api';
import { AuthType } from '../../constants/auth';
import {
  OK_BUTTON_KEY,
  createOTPAccountCreatedAlert,
  createOTPEmailSentAlert,
  createPasswordResetAlert,
  createPasswordTokenExpiredAlert,
  createResetEmailSentAlert,
  createSendVerifyEmailSuccessAlert,
  createVerifyAccountSuccessAlert,
} from '../../constants/dialog.constants';
import { paths } from '../../constants/paths';
import { TOAST_ERROR, TOAST_LONG, TOAST_SUCCESS } from '../../constants/toast';
import { USER_ACTION_LOGOUT } from '../../constants/user_actions';
import { PasswordMismatchError } from '../../utils/errors';
import {
  AUTHENTICATION_ALERT,
  EMAIL_RESET_TOKEN_REQUEST,
  LOGIN_REGISTER_OATH_REQUEST,
  LOGIN_REQUESTING,
  LOGOUT_REQUESTING,
  OTP_REQUESTING,
  REFRESH_ERROR,
  REFRESH_REQUESTING,
  REFRESH_SUCCESS,
  REGISTER_REQUESTING,
  RESET_PASSWORD_REQUEST,
  SEND_EMAIL_VERIFY_REQUEST,
  SNOWFLAKE_LOGIN_REQUEST,
  UPDATE_PROFILE_REQUESTING,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_PASSWORD_TOKEN_REQUEST,
  emailResetTokenFailure,
  emailResetTokenSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  logout,
  logoutFailure,
  logoutRequest,
  logoutSuccess,
  otpLinkFailure,
  otpLinkSuccess,
  refreshError,
  refreshRequest,
  refreshSuccess,
  registerFailure,
  registerRequest,
  registerSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  sendEmailVerifyFailure,
  sendEmailVerifyRequest,
  sendEmailVerifySuccess,
  snowflakeLoginFailure,
  snowflakeLoginSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  verifyAccountFailure,
  verifyAccountRequest,
  verifyAccountSuccess,
} from '../actions/auth.actions';
import { closeAllDialog, closeDialog } from '../actions/dialog.actions';
import { addToast } from '../actions/toast.actions';
import { selectHasDatasetEdits } from '../selectors/catalog.selector';
import { selectIsEditingDataset } from '../selectors/dataspace.selector';
import { unsavedDatasetChangesDialog } from './dataspace.saga';
import {
  selectAccessToken,
  selectAuth,
  selectIsAuthenticated,
  selectIsRequestingRefresh,
  selectRefreshToken,
} from './selectors';
import {
  createAlertChannel,
  loginErrorWorker,
  registerErrorWorker,
  resetPasswordErrorWorker,
  sendVerifyEmailErrorWorker,
  unauthenticatedAlert,
  verifyAccountErrorWorker,
} from './utils/alert-channels';
import { loadUserLevelRequests } from './utils/user_requests';

// inject store so we can access redux from this file
// ----------------------------------------------------
let persistor;
export const injectPersistorToAuthSaga = (_persistor) => {
  persistor = _persistor;
};
// ----------------------------------------------------

/**
 * Sends a POST request to the login API to get authenticated.
 * Throws authentication or server errors if any.
 *
 * @param {import('../constants/auth')AuthType} authType
 * @param {import('../constants/auth').AuthParams} [loginParams]
 */
function* loginHelper({ authType, loginParams }) {
  const authService = yield getContext(API_SERVICES.AUTH);
  const response = yield call(authService.getLogin, authType, loginParams);
  const { name, email: backendEmail } = response.data;
  const {
    user,
    access_token: accessToken,
    access_exp: accessExp,
    refresh_token: refreshToken,
    refresh_exp: refreshExp,
  } = response.data.token_package;
  yield putResolve(
    loginSuccess({
      user,
      name,
      accessToken,
      accessExp,
      refreshToken,
      refreshExp,
      email: backendEmail,
    }),
  );
  // Redirect to the app list page only if the link in the browser is the same as login
  if (window?.location?.pathname === paths.login) {
    yield put(push(paths.index));
  } else {
    yield put(push(window?.location?.pathname + window?.location?.search));
  }
}

function* snowflakeLoginRequestWorker() {
  try {
    // wait for 1 second
    yield delay(1000);
    const res = yield call(snowflakeLogin);
    const { name, email: backendEmail } = res.data;
    const {
      user,
      access_token: accessToken,
      access_exp: accessExp,
      refresh_token: refreshToken,
      refresh_exp: refreshExp,
    } = res.data.token_package;
    yield put(snowflakeLoginSuccess());
    yield putResolve(
      loginSuccess({
        user,
        name,
        accessToken,
        accessExp,
        refreshToken,
        refreshExp,
        email: backendEmail,
      }),
    );
    yield put(push(paths.index));
  } catch (error) {
    yield put(snowflakeLoginFailure({ error }));
  }
}

/**
 * Handles errors from the login helper.
 * @param {import("../../constants/auth").LoginRequest} params
 */
function* loginErrorHelper(error, { authType, loginParams, redirect }) {
  // Mitigates DoS attacks
  yield delay(500);
  yield put(loginFailure({ error }));
  yield* loginErrorWorker(error, loginRequest({ authType, loginParams, redirect }));
}

/**
 * Sends a POST request to the register API to get authenticated.
 * handles errors if any.
 * @param {import("../../constants/auth").LoginRequest} params
 */
export function* loginRequestWorker({ authType, loginParams }) {
  try {
    yield loginHelper({ authType, loginParams });
  } catch (error) {
    // Get the redirect path before handling error
    const { location } = yield select((state) => state.router);
    const redirectTo =
      location?.state?.from === '/web/oauth/consent' ? paths.oauthConsent : paths.login;
    yield loginErrorHelper(error, { authType, loginParams, redirect: redirectTo });
  } finally {
    // Logout if the login request got cancelled for some reason.
    if (yield cancelled()) {
      yield putResolve(logoutRequest());
    }
  }
}

export function* logoutRequestWorker() {
  // If the user is editing a dataset, prompt them to save their changes before logging out.
  const isEditingDataset = yield select(selectIsEditingDataset);
  const hasAnnotationEdits = yield select(selectHasDatasetEdits);
  if (isEditingDataset || hasAnnotationEdits) {
    yield call(unsavedDatasetChangesDialog);
    return;
  }
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(postLogUserAction, USER_ACTION_LOGOUT, null, accessToken);
    yield call(postLogout, accessToken);
    yield putResolve(logoutSuccess());
  } catch (error) {
    yield put(logoutFailure({ error }));
  } finally {
    // logout should happen on the client side regardless of an error from the server
    yield put(logout());
    yield persistor.flush();
    // window.location.pathname triggers the before:unload event for testing purposes
    // Also clears any state from logging in.  TODO: Clean this up so we don't need to refresh.
    window.location.pathname = paths.login;
  }
}

export function* refreshRequestWorker() {
  try {
    // get refresh token
    const refreshToken = yield select(selectRefreshToken);

    // request refreshed authentication tokens
    const response = yield postRefresh(refreshToken);

    // get tokens and their expiration times from the response
    const {
      access_token: accessToken,
      access_exp: accessExp,
      refresh_exp: refreshExp,
    } = response.data;

    // put success action with the new tokens
    yield put(
      refreshSuccess({
        accessToken,
        accessExp,
        refreshExp,
      }),
    );
  } catch (error) {
    // put error
    yield put(refreshError({ error }));
  }
}

/**
 * @param {import('../../constants/auth').RegisterRequest} params
 */
export function* registerRequestWorker({
  authType,
  key,
  registerParams,
  loginOnRegister,
  defaultHomePage,
  adAttribution,
}) {
  try {
    const authService = yield getContext(API_SERVICES.AUTH);
    switch (authType) {
      case AuthType.PASSWORD: {
        // destructure the registerParams as PasswordRegisterAuthParams
        const { name, email, password, confirmationPassword } = registerParams;
        if (password !== confirmationPassword) throw new PasswordMismatchError();
        yield call(authService.postRegister, {
          authType,
          key,
          email,
          name,
          password,
          defaultHomePage,
          adAttribution,
        });
        break;
      }
      case AuthType.AUTH0: {
        const { accessToken } = registerParams;
        yield call(authService.postRegister, {
          authType,
          key,
          defaultHomePage,
          authToken: accessToken,
          adAttribution,
        });
        break;
      }
      case AuthType.GOOGLE: {
        const { idToken } = registerParams;
        yield call(authService.postRegister, {
          authType,
          key,
          defaultHomePage,
          authToken: idToken,
          adAttribution,
        });
        break;
      }
      case AuthType.OTP: {
        const { email, name } = registerParams;
        yield call(authService.postRegister, {
          authType,
          key,
          email,
          name,
          defaultHomePage,
          adAttribution,
        });
        break;
      }
      case AuthType.SAML:
      case AuthType.IAP:
        yield call(authService.postRegister, {
          authType,
          key,
          defaultHomePage,
          adAttribution,
        });
        break;
      default:
        throw new Error(`Unsupported auth type: ${authType}`);
    }
    yield put(registerSuccess()); // sets isRequesting to false
    if (loginOnRegister) {
      yield put(
        loginRequest({
          authType,
          loginParams: registerParams,
        }),
      );
    } else if (authType !== AuthType.OTP) {
      const { location } = yield select((state) => state.router);

      const searchParams = new URLSearchParams(location.search);
      const redirectTo = searchParams.get('redirect') ?? paths.login;
      const clientId = searchParams.get('client_id');
      const scope = searchParams.get('scope');
      const state = searchParams.get('state');
      const redirectUri = searchParams.get('redirect_uri');

      const path = `${paths.verifyEmail}?email=${registerParams.email}&redirect=${redirectTo}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`;

      yield put(
        push(path, {
          state: {
            from: location?.state?.from,
          },
        }),
      );
    }
    if (authType === AuthType.OTP) {
      // Show Alert on OTP email sent
      const alertChannel = yield createAlertChannel(createOTPAccountCreatedAlert());
      const keyChoice = yield take(alertChannel);
      // User picked OK. Close the pop up.
      if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
    }
  } catch (error) {
    // Mitigates DoS attacks
    yield delay(500);
    yield put(registerFailure({ error }));
    yield* registerErrorWorker(
      error,
      registerRequest({
        authType,
        registerParams,
        loginOnRegister,
        defaultHomePage,
        adAttribution,
      }),
    );
  } finally {
    // Logout if the register request got cancelled for some reason.
    if (yield cancelled()) {
      yield putResolve(logoutRequest());
    }
  }
}

export function* emailResetTokenRequestWorker({ origin, email, isSignedIn }) {
  try {
    yield postResetPassword(origin, email);
    yield putResolve(emailResetTokenSuccess());

    // Show Alert on reset email sent
    const alertChannel = yield createAlertChannel(createResetEmailSentAlert(isSignedIn));
    const keyChoice = yield take(alertChannel);
    // User picked OK. Close the pop up.
    if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
  } catch (error) {
    // Mitigates DoS attacks
    yield delay(500);
    yield put(emailResetTokenFailure({ error }));
    yield* resetPasswordErrorWorker(error);
  } finally {
    // Logout if the reset password request got cancelled for some reason.
    if (yield cancelled()) {
      yield putResolve(logoutRequest());
    }
  }
}

export function* otpLinkRequestWorker({ email }) {
  try {
    yield postOtpLink(email);
    yield putResolve(otpLinkSuccess());

    // Show Alert on OTP email sent
    const alertChannel = yield createAlertChannel(createOTPEmailSentAlert());
    const keyChoice = yield take(alertChannel);
    // User picked OK. Close the pop up.
    if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
  } catch (error) {
    // Mitigates DoS attacks
    yield delay(500);
    yield put(otpLinkFailure({ error }));
  }
}

export function* resetPasswordRequestWorker({ resetToken, password, confirmationPassword }) {
  try {
    if (password !== confirmationPassword) throw new PasswordMismatchError();
    yield postResetPasswordConfirm(resetToken, password);
    yield putResolve(resetPasswordSuccess());
    const isAuthenticated = yield select(selectIsAuthenticated);

    // Show Alert on successful reset
    const alertChannel = yield createAlertChannel(createPasswordResetAlert());
    const keyChoice = yield take(alertChannel);
    // User picked OK. Close the pop up.
    if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());

    if (isAuthenticated) {
      // The user will need to re-login after resetting their password
      yield put(logoutRequest());
    } else {
      yield put(push(paths.login));
    }
  } catch (error) {
    // Mitigates DoS attacks
    yield delay(500);
    yield put(resetPasswordFailure({ error }));
    yield* resetPasswordErrorWorker(error);
  } finally {
    // Logout if the reset password request got cancelled for some reason.
    if (yield cancelled()) {
      yield putResolve(logoutRequest());
    }
  }
}
/* eslint-disable */

export function* verifyPasswordTokenRequestWorker() {
  try {
    // TODO: URLSeatchParams is not compatible with IE. Add a warning
    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get('token');
    yield call(getResetPasswordTokenVerification, resetToken);
  } catch (error) {
    console.log(error);
    const alertChannel = yield createAlertChannel(createPasswordTokenExpiredAlert());
    const keyChoice = yield take(alertChannel);
    // User picked OK. Close the pop up.
    if (keyChoice === OK_BUTTON_KEY) {
      yield put(closeAllDialog());
      yield put(push(paths.emailResetToken));
    }
  }
}

export function* sendEmailVerifyRequestWorker(args) {
  const { email, redirect, clientId, scope, state, redirectUri } = args;
  try {
    yield postSendVerificationEmail(email, {
      redirect,
      client_id: clientId,
      scope,
      state,
      redirect_uri: redirectUri,
    });
    yield put(sendEmailVerifySuccess());

    // Alert the user that the verification email has been sent
    const alertChannel = yield createAlertChannel(createSendVerifyEmailSuccessAlert(email));
    const keyChoice = yield take(alertChannel);

    // User clicked OK. Close the pop up.
    if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());

    // Use provided redirect or get from URL params as fallback
    const redirectTo =
      redirect ||
      (() => {
        const params = new URLSearchParams(window.location.search);
        return params.get('redirect') || paths.login;
      })();

    yield put(push(redirectTo));
  } catch (error) {
    // Mitigates DoS attacks
    yield delay(500);
    yield put(sendEmailVerifyFailure({ error }));
    yield* sendVerifyEmailErrorWorker(error, sendEmailVerifyRequest(args));
  }
}

export function* verifyAccountRequestWorker({ email, token }) {
  try {
    yield postVerifyEmail(email, token);
    yield put(verifyAccountSuccess());

    // Alert the user that their email has been verified
    const alertChannel = yield createAlertChannel(createVerifyAccountSuccessAlert(email));
    const keyChoice = yield take(alertChannel);

    // User clicked Ok. Close the pop up.
    if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());

    const redirectTo = (() => {
      const params = new URLSearchParams(window.location.search);
      return params.get('redirect') || paths.login;
    })();

    // Redirect to login page (user is now email verified)
    yield put(push(redirectTo));
  } catch (error) {
    // Mitigates DoS attacks
    yield delay(500);
    yield put(verifyAccountFailure({ error }));
    yield* verifyAccountErrorWorker(email, error, verifyAccountRequest({ email, token }));
  }
}

export function* authenticationAlertWorker({ retryRequestAction }) {
  /** Whether a refresh is currently being requested. */
  const isRequestingRefresh = yield select(selectIsRequestingRefresh);

  // if we aren't already refreshing, request a refresh
  if (!isRequestingRefresh) yield putResolve(refreshRequest());

  // race the refresh's success or failure
  const { failure } = yield race({ success: take(REFRESH_SUCCESS), failure: take(REFRESH_ERROR) });

  // handle failure
  if (failure) {
    // alert user that they are not authenticated
    yield* unauthenticatedAlert();
    return;
  }

  // refresh succeeded, user is authenticated

  // if there is a retry request action, dispatch it
  if (retryRequestAction) yield put(retryRequestAction);

  // load the standard user-level actions.
  yield* loadUserLevelRequests();
}

export function* updateProfileWorker({ name }) {
  try {
    const accessToken = yield select(selectAccessToken);
    // send the request to update user profile (name)
    yield call(updateUserProfile, accessToken, { name });
    yield put(updateProfileSuccess({ name }));
    // add success toast
    yield put(
      addToast({
        toastType: TOAST_SUCCESS,
        length: TOAST_LONG,
        message: `User name updated.`,
      }),
    );
  } catch (error) {
    yield put(updateProfileFailure({ error }));
    // add error toast
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: `Failed to update user name.`,
      }),
    );
  }
}

/**
 * This worker handles the login and register requests regardless of whether or not they have signed up yet.
 *
 * This worker currently only supports Auth0 and Google OAuth.
 *
 * @param {import('../../constants/auth').LoginRegisterRequest} params
 */
export function* loginRegisterRequestWorker({ authType, loginParams, adAttribution }) {
  try {
    const supportLoginAndRegisterTypes = [AuthType.AUTH0, AuthType.GOOGLE];
    if (!supportLoginAndRegisterTypes.includes(authType)) {
      throw new Error(`Unsupported login and register type: ${authType}`);
    }
    try {
      yield loginHelper({ authType, loginParams });
    } catch (error) {
      // STATUS UNAUTHORIZED
      if (error.response.status === 401) {
        // if the login fails, try to register and sign in
        yield put(
          registerRequest({
            authType,
            registerParams: loginParams,
            defaultHomePage: HOME_PAGE.WEB,
            loginOnRegister: true,
            adAttribution,
          }),
        );
      } else {
        yield loginErrorHelper(error, { authType, email: '', password: '' });
      }
    }
  } catch (error) {
    yield put(loginFailure({ error }));
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: `Failed to sign in with ${authType}.`,
      }),
    );
  }
}

export function* getAccessToken() {
  // get auth
  let auth = yield select(selectAuth);

  // get token and its expiration
  const { accessToken: token, accessExp: expiration } = auth;

  // if either are missing, return token
  if (!token || !expiration) return token;

  // if expiration is in the far future (> 1 minute), return token
  if (moment(expiration).diff(moment(), 'minutes') > 1) return token;

  // else, call to refresh auth
  yield call(authenticationAlertWorker, { retryRequestAction: null });

  // get refreshed auth
  auth = yield select(selectAuth);

  // return refreshed token
  return auth.accessToken;
}

/**
 * The login watcher waits for the client to make a login action
 * and resets the application state if anything goes wrong.
 */
export default function* () {
  // Multiple simultaneous login and register requests are not allowed
  // and will cancel the worker and logout the user.
  yield takeLatest(REGISTER_REQUESTING, registerRequestWorker);
  yield takeLatest(LOGIN_REQUESTING, loginRequestWorker);
  yield takeLatest(OTP_REQUESTING, otpLinkRequestWorker);
  yield takeLatest(EMAIL_RESET_TOKEN_REQUEST, emailResetTokenRequestWorker);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordRequestWorker);
  yield takeLatest(SEND_EMAIL_VERIFY_REQUEST, sendEmailVerifyRequestWorker);
  yield takeLatest(VERIFY_ACCOUNT_REQUEST, verifyAccountRequestWorker);
  yield takeLatest(VERIFY_PASSWORD_TOKEN_REQUEST, verifyPasswordTokenRequestWorker);
  yield takeEvery(AUTHENTICATION_ALERT, authenticationAlertWorker); // take every alert, handle concurrent in worker
  yield takeLeading(LOGOUT_REQUESTING, logoutRequestWorker); // take leading to ensure all logout requests finish completely
  yield takeLeading(REFRESH_REQUESTING, refreshRequestWorker); // take leading to ensure all refresh requests finish completely
  yield takeLatest(UPDATE_PROFILE_REQUESTING, updateProfileWorker);
  yield takeLatest(LOGIN_REGISTER_OATH_REQUEST, loginRegisterRequestWorker);
  yield takeLatest(SNOWFLAKE_LOGIN_REQUEST, snowflakeLoginRequestWorker);
}
