import Box from '@mui/material/Box';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useContext, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import DataChatLogo from '../../../../assets/logos/favicon.svg';
import DataChatLogoAnimated from '../../../../assets/logos/favicon_animated_opacity.svg';
import { CONTACT_FORM_DEFAULTS, VISUAL_TYPES } from '../../../../constants';
import { AskPromptClass } from '../../../../constants/dataAssistant';
import { openContactForm } from '../../../../store/actions/contact_form.actions';
import { selectUserConfig } from '../../../../store/sagas/selectors';
import { selectChartById } from '../../../../store/selectors/chartspace.selector';
import { selectDatasetById } from '../../../../store/selectors/dataspace.selector';
import { selectSession } from '../../../../store/selectors/session.selector';
import { openViewSQL } from '../../../../store/slices/viewSql.slice';
import { USE_IN_VIEW_OPT } from '../../../../utils/charts';
import { isCopyLinkEnabled } from '../../../../utils/env';
import { circularStringify } from '../../../../utils/string';
import { ScreenSizeContext } from '../../../DataAssistant/DataAssistant';
import AvaResponseMessages from './AvaResponseMessages';
import AddToSpaceLink from './Components/AddToSpaceLink/AddToSpaceLink';
import AvaActionMenu from './Components/AvaActionMenu';
import ContactAction from './Components/AvaActions/ContactAction';
import CreateLinkAction from './Components/AvaActions/CreactLinkAction';
import FeedbackActions from './Components/AvaActions/FeedbackActions';
import ViewRecipeAction from './Components/AvaActions/ViewRecipeAction';
import ViewSQLAction from './Components/AvaActions/ViewSQLAction';
import AvaValidation from './Components/AvaValidation';
import './Conversation.scss';

/**
 * AvaResponse renders a collection of messages that make up a response to a question.
 * @param {string} id id of the message
 * @param {array} messages array of messages within a node to render
 * @param {object} metadata metadata for the node
 * @param {object} questionNode
 * @param {bool} isLastNode whether or not this is the last node in the conversation
 * @param {bool} isPublic whether or not the conversation is public
 */
const AvaResponse = ({ id, messages, metadata, questionNode, isLastNode, isPublic, bgColor }) => {
  const { vector_id: vectorId, ask_recipe: recipe, tabvisual_summary: tabvisualSummary } = metadata;
  const dispatch = useDispatch();
  const sessionId = useSelector(selectSession);
  const userConfig = useSelector(selectUserConfig);

  const { inView, ref: inViewRef } = useInView(USE_IN_VIEW_OPT);

  const [anchorEl, setAnchorEl] = useState(null);

  // handle when user clicks show recipe
  const handleClickRecipe = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Opens the contact form with appropriate default values for submitting
   * feedback on this response.
   */
  const handleContact = () => {
    dispatch(
      openContactForm({
        subject: 'Data Assistant Feedback',
        content: '',
        messageType: CONTACT_FORM_DEFAULTS.SUBJECT_FEEDBACK,
        hiddenDetails: circularStringify({ sessionId, metadata, messages, questionNode }),
      }),
    );
  };

  // handle when user clicks to close recipe
  const handleCloseRecipe = () => {
    setAnchorEl(null);
  };

  // handle when user clicks to view SQL
  const handleViewSQL = () => {
    dispatch(
      openViewSQL({
        dcDatasetId: metadata.dc_dataset_id,
      }),
    );
  };

  // keep track of the selected chart in the carousel
  // this will stay null if there is no carousel
  const [selectedChart, setSelectedChart] = useState(null);

  // small screen breakpoint
  const isSmallScreen = useContext(ScreenSizeContext);

  // renders the animated icon if the last message is an update
  const isLoading = useMemo(() => {
    return messages[messages.length - 1]?.ask_prompt === AskPromptClass.UPDATE;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length]);

  const askMessages = messages.filter((message) => message.ask_prompt);

  const { answerComplete, answerMessage, answerSuccess } = useMemo(() => {
    return askMessages.reduce(
      (acc, m) => {
        if (m.ask_prompt === AskPromptClass.ANSWER) {
          acc.answerComplete = true;
          acc.answerMessage = m;
        } else if (m.ask_prompt === AskPromptClass.FAILURE) acc.answerSuccess = false;
        return acc;
      },
      {
        answerComplete: false,
        answerMessage: null,
        answerSuccess: true,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askMessages.length]);

  /**
   * dc_dataset_id or dc_chart_id id from the answer message (if either exist)
   *
   * @type {string | undefined}
   */
  const answerObjectId = useMemo(() => {
    if (!answerMessage) return undefined;
    let objectId;
    if (answerMessage.type === VISUAL_TYPES.TABLE) {
      objectId = answerMessage?.additional_info?.dc_dataset_id;
    } else {
      objectId = answerMessage?.additional_info?.dc_chart_id;
    }
    return objectId || undefined;
  }, [answerMessage]);

  const isPivotTable = useMemo(() => {
    // check to see if one of the messages is a pivot table
    // only run this function when the messages change
    return askMessages.some((message) => message.type === VISUAL_TYPES.PIVOT);
  }, [askMessages]);

  const isTable = useMemo(() => {
    // Tab charts can contain table messages, but we filter them out later on. So if aksMessages contains a tab visual,
    // we can assume that the no tables will be displayed in this message.
    const isTabVisual = askMessages.some((message) => message.type === VISUAL_TYPES.TABVISUAL);
    // Tables are not included in tab visual responses
    return !isTabVisual && askMessages.some((message) => message.type === VISUAL_TYPES.TABLE);
  }, [askMessages]);

  // get the chart or dataset object from the answer object id to ensure the object is ready to be added to the space
  // there could be a race condition where the message store state arrives before the chart or dataset store state. See #51192
  const chartObj = useSelector((state) => selectChartById(state, answerObjectId));
  const datasetObj = useSelector((state) => selectDatasetById(state, answerObjectId));

  const answerObjectInSpace =
    (answerMessage?.type === VISUAL_TYPES.TABLE ? datasetObj : chartObj) != null;

  return (
    <div
      ref={inViewRef}
      key={id}
      id={id}
      data-testid={id}
      className={classNames('ConversationNode', 'AvaResponse', { SmallScreenSize: isSmallScreen })}
    >
      <div className={classNames('ConversationNode-ChatIcon', { SmallScreenSize: isSmallScreen })}>
        {isLoading ? <DataChatLogoAnimated /> : <DataChatLogo />}
      </div>
      <Box className="ConversationNode-ChatBubble AvaResponse" sx={{ backgroundColor: bgColor }}>
        <AvaResponseMessages
          nodeId={id}
          messages={askMessages}
          isLastNode={isLastNode}
          nodeMetadata={metadata}
          inView={inView}
          objectId={answerObjectId}
          setSelectedChart={setSelectedChart}
        />
      </Box>
      {answerComplete && answerMessage && answerObjectId && answerObjectInSpace ? (
        <AddToSpaceLink visualType={answerMessage?.type} id={answerObjectId} />
      ) : null}
      <AvaActionMenu>
        {answerComplete && metadata.dc_dataset_id && userConfig.viewSql ? (
          <ViewSQLAction id={`${id}-view-sql`} onClick={handleViewSQL} />
        ) : null}
        {recipe ? <ViewRecipeAction id={id} onClick={handleClickRecipe} /> : null}
        {vectorId && !isPublic ? (
          <FeedbackActions id={id} vectorId={vectorId} metadata={metadata} />
        ) : null}
        {!isPublic ? <ContactAction id={`${id}-contact`} handleClick={handleContact} /> : null}
        {isCopyLinkEnabled() &&
        answerComplete &&
        !isPublic &&
        !isPivotTable &&
        !isTable &&
        answerSuccess ? (
          <CreateLinkAction messages={messages} />
        ) : null}
      </AvaActionMenu>
      {recipe && (
        <AvaValidation
          recipe={recipe}
          tabvisualRecipe={tabvisualSummary?.[selectedChart]?.utterances}
          anchorEl={anchorEl}
          handleClose={handleCloseRecipe}
        />
      )}
    </div>
  );
};

AvaResponse.propTypes = {
  id: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  metadata: PropTypes.object,
  questionNode: PropTypes.object,
  isLastNode: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool,
  bgColor: PropTypes.string.isRequired,
};

AvaResponse.defaultProps = {
  metadata: {},
  questionNode: {},
  isPublic: false,
};

export default memo(AvaResponse);
