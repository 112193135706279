import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../configureStore';
import { AVA_IDS } from '../../../../../constants/dataAssistant';
import {
  selectDatasetById,
  selectDataspaceError,
  selectIsDataSpaceLoading,
} from '../../../../../store/selectors/dataspace.selector';
import SessionData from '../../../../ChartData/SessionData';
import Table from '../../../../DisplayPanel/Charts/Table/Table';
import { AVA_TABLE_EXPANSION_LIMIT } from '../../../../DisplayPanel/Charts/Table/constants';
import './TableMessage.scss';

export interface TableMessageProps {
  /** ID of the Dataset this table visualizes. */
  id: string;
  /** Whether the table is in view. */
  inView?: boolean;
  objectId: string;
  /** This table's message. */
  message: any;
}

export default function TableMessage({ id, inView = true, objectId, message }: TableMessageProps) {
  /** This table's dataset, if any. */
  const dataset = useSelector((state: RootState) => selectDatasetById(state, objectId));

  /** Whether the dataspace is loading. */
  const isDataspaceLoading = useSelector(selectIsDataSpaceLoading);

  /** Whether this table's dataset is loading. */
  const isDatasetLoading =
    dataset === null && // dataset is not in dataspace
    isDataspaceLoading; // dataspace is loading

  /** Dataspace's error message, if any. */
  const dataspaceError = useSelector(selectDataspaceError);

  /** Whether the dataspace has failed to load. */
  const hasDataspaceFailed = dataspaceError !== null;

  /** Whether this table's dataset has failed to load. */
  const hasDatasetFailed =
    dataset === null && // this table's dataset is not in dataspace
    hasDataspaceFailed; // the dataspace has failed to load

  /** Pipeliner dataset id of this table's dataset. */
  const pipelinerDatasetId = dataset?.pipeliner_dataset_id;

  return (
    <div className="TableMessage" data-testid={AVA_IDS.MSG_TABLE_WRAPPER(id)}>
      <SessionData key={id} message={message} objectId={objectId}>
        {(
          msg,
          sessionDataError,
          hasSessionDataFailed,
          isSessionDataLoading,
          rowCount,
          updateRowCount,
        ) => {
          /** Whether this table should show a loader. */
          const isLoading =
            isSessionDataLoading || // SessionData is loading
            msg.chart.data.rows === undefined || // this table's data is loading
            isDatasetLoading; // this table's dataset is loading

          /** Whether this table has failed to load. */
          const isFailed =
            hasSessionDataFailed || // SessionData has failed to load
            hasDatasetFailed; // this table's dataset has failed to load

          return (
            <Table
              data={msg.chart.data}
              id={id}
              inView={inView}
              isFailed={isFailed}
              isInsightsBoard={false}
              isLoading={isLoading}
              noRowsText="The answer to your question appears to be an empty dataset."
              pageSizeOptions={[AVA_TABLE_EXPANSION_LIMIT]}
              rowCount={rowCount}
              updateRowCount={updateRowCount}
              pipelinerDatasetId={pipelinerDatasetId}
            />
          );
        }}
      </SessionData>
    </div>
  );
}
