import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import {
  selectRender,
  selectRenderError,
  selectRenderProgress,
} from '../../store/selectors/chartRenders.selector';
import { getRender } from '../../store/slices/chartRenders.slice';
import { ChartRenderParams } from '../../types/render.types';

export function useChartRender({
  inView,
  sessionId,
  insightsBoardId,
  publicationId,
  workspaceUuid,
  height,
  width,
  renderer,
  dcChartId,
  apiKey,
  apiSecret,
}: ChartRenderParams & { inView: boolean }) {
  const dispatch = useDispatch();

  const render = useSelector((state: RootState) =>
    selectRender(state, { dcChartId, renderType: renderer, width, height }),
  );
  const progress = useSelector((state: RootState) =>
    selectRenderProgress(state, { dcChartId, renderType: renderer, width, height }),
  );
  const error = useSelector((state: RootState) =>
    selectRenderError(state, { dcChartId, renderType: renderer, width, height }),
  );

  useEffect(() => {
    if (inView && !render && !error && progress === null) {
      dispatch(
        getRender({
          dcChartId,
          renderer,
          width,
          height,
          sessionId,
          apiKey,
          apiSecret,
          publicationId,
          workspaceUuid,
          insightsBoardId,
        }),
      );
    }
  }, [
    inView,
    progress,
    dcChartId,
    renderer,
    width,
    height,
    sessionId,
    apiKey,
    apiSecret,
    publicationId,
    workspaceUuid,
    insightsBoardId,
    render,
    error,
    dispatch,
  ]);

  return {
    render,
    progress,
    error,
    isLoading: (progress ?? 0) < 100 && !error,
  };
}
