import { call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { createSqlJob, getSqlData, revokeSqlJob } from '../../api/get_sql.api';
import { TOAST_ERROR, TOAST_LONG, TOAST_SHORT } from '../../constants/toast';
import { CreateSqlJob, RevokeSqlJobAction, ViewSQLPayload } from '../../types/getSql.types';
import { addToast } from '../actions/toast.actions';
import { selectDatasetById } from '../selectors/dataspace.selector';
import { selectSession } from '../selectors/session.selector';
import {
  getDataspaceFailure,
  getDataspaceRequest,
  getDataspaceSuccess,
} from '../slices/dataspace.slice';
import {
  openViewSQL,
  retrieveSQLDataFailure,
  retrieveSQLDataSuccess,
  revokeSqlJobFailure,
} from '../slices/viewSql.slice';
import { selectAccessToken } from './selectors';
import { callWithPolling } from './utils/saga_utils';

export function* revokeSqlJobWorker(action: RevokeSqlJobAction) {
  try {
    const { jobData, pipelinerDatasetId } = action.payload;
    const accessToken: string = yield select(selectAccessToken);
    const sessionId: string = yield select(selectSession);
    yield call(revokeSqlJob, accessToken, jobData.job_queue, jobData.job_id, {
      sessionId,
      pipelinerDatasetId,
    });
  } catch (error) {
    if (error instanceof Error) {
      yield put(revokeSqlJobFailure({ error }));
    }
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'Oops, something went wrong when cancelling the SQL query.',
      }),
    );
  }
}

export function* retrieveSqlDataWorker({
  payload,
}: {
  payload: ViewSQLPayload;
}): Generator<any, void, any> {
  let jobData: CreateSqlJob | null = null;
  try {
    let { pipelinerDatasetId } = payload;
    const { dcDatasetId } = payload;
    const accessToken = yield select(selectAccessToken);
    const sessionId = yield select(selectSession);

    // If pipelinerDatasetId is not provided, retrieve it from the dcDatasetId
    if (!pipelinerDatasetId && dcDatasetId) {
      let dataset = yield select(selectDatasetById, dcDatasetId);
      // If the dataset is not available, fetch the dataset
      if (!dataset) {
        yield put(getDataspaceRequest());
        const { success, failure } = yield race({
          success: take(getDataspaceSuccess),
          failure: take(getDataspaceFailure),
        });
        if (failure || !success) {
          throw new Error('Failed to fetch datasets.');
        }
        dataset = yield select(selectDatasetById, dcDatasetId);
        if (!dataset) {
          throw new Error(`No dataset found with ID: ${dcDatasetId}`);
        }
      }
      pipelinerDatasetId = dataset.pipeliner_dataset_id;
      payload.pipelinerDatasetId = pipelinerDatasetId;
    }

    if (!pipelinerDatasetId) throw new Error('No Pipeliner dataset ID provided');

    // Create a SQL job
    const jobResponse = yield call(createSqlJob, accessToken, pipelinerDatasetId, {
      sessionId,
    });
    jobData = jobResponse.data as CreateSqlJob;
    // Get an AbortController to cancel the API call if necessary
    const abortController = new AbortController();
    // Call the API to retrieve the SQL data with polling
    const sqlResponse = yield call(callWithPolling, {
      accessToken,
      fn: getSqlData,
      signal: abortController.signal,
      args: {
        job_queue: jobData.job_queue,
        job_id: jobData.job_id,
        pipelinerDatasetId,
        sessionId,
      },
    });

    yield put(retrieveSQLDataSuccess(sqlResponse.data));
  } catch (error) {
    if (error instanceof Error) {
      yield put(retrieveSQLDataFailure({ error }));
    }
    // Only attempt to revoke the SQL job if jobData is available
    if (jobData && payload.pipelinerDatasetId) {
      yield fork(revokeSqlJobWorker, {
        payload: { jobData, pipelinerDatasetId: payload.pipelinerDatasetId },
      });
    }

    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: 'Oops, something went wrong when retrieving the SQL query. Please try again.',
      }),
    );
  }
}

export default function* viewSqlSaga() {
  yield takeLatest(openViewSQL.type, retrieveSqlDataWorker);
  yield takeLatest(revokeSqlJobFailure.type, revokeSqlJobWorker);
}
