import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';

export const selectCurrentTaskId = (state: RootState) => state.task.currentTaskId;

export const selectActiveTasks = (state: RootState) => state.task.activeTasks;

export const selectCurrentTask = createSelector(
  [selectCurrentTaskId, selectActiveTasks],
  (currentTaskId, activeTasks) => {
    if (activeTasks === undefined) return null;
    return activeTasks.find((task) => task.id === currentTaskId) ?? null;
  },
);

export const selectCancellingCurrentTask = (state: RootState) => state.task.cancellingCurrentTask;

export const selectTask = createSelector(
  [selectActiveTasks, (_state: RootState, taskId: string) => taskId],
  (activeTasks, taskId) => {
    return activeTasks?.find((t) => t.id === taskId) ?? null;
  },
);
