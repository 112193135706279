import axios from 'axios';
import { endpoints } from '../constants/endpoints';
import { Task, TaskStatus, TaskType } from '../types/task.types';
import {
  appServiceErrorHandlers,
  handleAPICall,
  handleDCAppServiceCall,
} from '../utils/errorHandling/errorHandlers.api';
import { TaskAlreadyStartedErrorHandler } from '../utils/errorHandling/handlers/TaskAlreadyStartedErrorHandler';

export const postTask = (
  accessToken: string,
  sessionId: string,
  taskType: TaskType = TaskType.BLOCKING,
  timeout?: number,
  parentTaskId?: string,
  description?: string,
) =>
  handleDCAppServiceCall(() =>
    axios.post<Task>(
      endpoints.tasks(sessionId),
      {
        taskType,
        timeout,
        parentTaskId,
        description,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ),
  );

export const getTasks = (
  accessToken: string,
  sessionId: string,
  statuses: TaskStatus[] = [],
  types: TaskType[] = [],
  parents: string[] = [],
) => {
  const params = new URLSearchParams();
  for (const status of statuses) {
    params.append('status', status);
  }
  for (const type of types) {
    params.append('type', type);
  }
  for (const parent of parents) {
    params.append('parent', parent);
  }
  return handleDCAppServiceCall(() =>
    axios.get<Task[]>(endpoints.tasks(sessionId), {
      headers: { Authorization: `Bearer ${accessToken}` },
      params,
    }),
  );
};

/**
 * Cancels a session's task by requesting DCAS
 * @param accessToken User's access token
 * @param sessionId Session ID that the task to cancel belongs to
 * @param taskId ID of the task to cancel
 */
export const postCancelTask = (accessToken: string, sessionId: string, taskId: string) => {
  return handleAPICall(
    () =>
      axios.post<string>(endpoints.cancelTask(sessionId, taskId), undefined, {
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    [new TaskAlreadyStartedErrorHandler(), ...appServiceErrorHandlers],
  );
};
