import axios from 'axios';
import { dcAppServiceEndpoints } from '../constants/endpoints';
import { CreateSqlJob, GetSqlData } from '../types/getSql.types';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

export const createSqlJob = (
  accessToken: string,
  pipelinerDatasetId: string,
  {
    sessionId,
    insightsBoardId,
    publicationId,
    workspaceUuid,
  }: {
    /**
     * The session ID to use (claims)
     */
    sessionId?: string;
    /**
     * The insights board ID to use (claims)
     */
    insightsBoardId?: string;
    /**
     * The publication ID to use (claims)
     */
    publicationId?: string;
    /**
     * The workspace UUID to use (claims)
     */
    workspaceUuid?: string;
  },
) => {
  return handleDCAppServiceCall(() =>
    axios.post<CreateSqlJob>(
      dcAppServiceEndpoints.getSqlJob,
      JSON.stringify({ pipeliner_dataset_id: pipelinerDatasetId }),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: {
          sessionId,
          insightsBoardId,
          publicationId,
          workspaceUuid,
        },
      },
    ),
  );
};

export const getSqlData = (
  accessToken: string,
  signal: AbortSignal,
  {
    job_queue,
    job_id,
    pipelinerDatasetId,
    sessionId,
    insightsBoardId,
    publicationId,
    workspaceUuid,
  }: {
    /**
     * The job queue to use
     */
    job_queue: string;
    /**
     * The job ID to use
     */
    job_id: string;
    /**
     * The pipeline ID to use
     */
    pipelinerDatasetId: string;
    /**
     * The session ID to use (claims)
     */
    sessionId?: string;
    /**
     * The insights board ID to use (claims)
     */
    insightsBoardId?: string;
    /**
     * The publication ID to use (claims)
     */
    publicationId?: string;
    /**
     * The workspace UUID to use (claims)
     */
    workspaceUuid?: string;
  },
) => {
  return handleDCAppServiceCall(() =>
    axios.get<GetSqlData>(dcAppServiceEndpoints.getSql(job_queue, job_id), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        pipelinerDatasetId,
        sessionId,
        insightsBoardId,
        publicationId,
        workspaceUuid,
      },
      signal,
      // allow statuses in the 200s and 501
      validateStatus: (status) => status === 501 || (status >= 200 && status < 300),
    }),
  );
};

export const revokeSqlJob = (
  accessToken: string,
  jobQueue: string,
  jobId: string,
  {
    pipelinerDatasetId,
    sessionId,
    insightsBoardId,
    publicationId,
    workspaceUuid,
  }: {
    /**
     * The pipeline ID to use
     */
    pipelinerDatasetId: string;
    /**
     * The session ID to use (claims)
     */
    sessionId?: string;
    /**
     * The insights board ID to use (claims)
     */
    insightsBoardId?: string;
    /**
     * The publication ID to use (claims)
     */
    publicationId?: string;
    /**
     * The workspace UUID to use (claims)
     */
    workspaceUuid?: string;
  },
) => {
  return handleDCAppServiceCall(() =>
    axios.delete(dcAppServiceEndpoints.getSql(jobQueue, jobId), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        pipelinerDatasetId,
        sessionId,
        insightsBoardId,
        publicationId,
        workspaceUuid,
      },
    }),
  );
};
