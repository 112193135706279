export enum FeatureTypes {
  PUBLIC_LINKS = 'public_links',
  READABLE_COLUMN_ANNOTATIONS = 'readable_column_annotations',
  DatabaseOAuth = 'database_oauth',
  SnowflakeOAuth = 'snowflake_oauth',
  VIEW_SQL = 'viewSql',
}

/** Maps the  */
export type FeatureFlags = Record<FeatureTypes, boolean>;
