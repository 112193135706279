import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../configureStore';
import { selectPipelinerDatasetDescription } from '../store/slices/pipelinerDatasets/selectors';
import { getDescription } from '../store/slices/pipelinerDatasets/slice';
import { AsyncResult } from '../types/AsyncResult.types';
import { DatasetClaim } from '../types/claim.type';
import {
  ColumnDescription,
  ColumnStatisticName,
  Description,
  DescriptionParameters,
} from '../types/dataset.types';

/**
 * Check if the requested statistics are missing from the columns.
 *
 * @param requestedStats - The statistics that were requested.
 * @param columns - The columns to check.
 */
export const isMissingStat = (
  requestedStats: ColumnStatisticName[],
  columns: Record<string, ColumnDescription> | null | undefined,
): boolean => {
  if (!columns || isEmpty(columns)) return true;

  for (const col of Object.values(columns)) {
    // If stats are missing entirely, return true
    if (!col.stats) return true;

    // check if any of the requested stats are missing for the current column
    for (const stat of requestedStats) {
      if (col.stats[stat] === undefined || col.stats[stat] === null) {
        return true;
      }
    }
  }

  return false;
};

export default function usePipelinerDatasetDescription(
  id: string,
  claim: DatasetClaim,
  params: DescriptionParameters = {},
): AsyncResult<Description> {
  const dispatch = useDispatch();

  // get the total row count from the store
  const description = useSelector((state: RootState) =>
    selectPipelinerDatasetDescription(state, id),
  );

  if (!id) return { kind: 'error', error: new Error('missing pipeliner dataset id') };

  // No parameters were specified, return an empty description
  if (!params.totalRowCount && (!params.stats || isEmpty(params.stats))) {
    return { kind: 'data', data: { columns: {} } };
  }

  // Fetch the description if total row count is requested, but not included
  // or if the description has not been requested yet
  if (
    !description ||
    /** Check if totalRowCount was requested and we do not have it */
    (description?.kind === 'data' &&
      params.totalRowCount &&
      description?.data?.totalRowCount === undefined) ||
    /** Check if a stat was requested, but it doesn't exist in a column */
    (description?.kind === 'data' &&
      params.stats &&
      isMissingStat(params.stats, description.data.columns))
  ) {
    dispatch(getDescription({ id, claim, params }));
    return { kind: 'loading' };
  }

  // if the total row count is in the store, return it
  return description;
}
