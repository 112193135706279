import axios, { type AxiosResponse } from 'axios';
import { endpoints, nl2codeEndpoints } from '../constants/endpoints';

export interface ClearContextResponse {
  error_code: string;
  message: string;
  status: number;
}

// check if a conversation context exists for a given session and dataset schema
export const sweepContext = ({
  accessToken,
  sessionId,
}: {
  accessToken: string;
  sessionId: string;
}): Promise<AxiosResponse<ClearContextResponse>> =>
  axios.delete(nl2codeEndpoints.sweepContext, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { sessionId },
  });

export interface CheckContextResponse {
  context_exists: boolean;
  error_code: string;
  message: string;
  status: number;
}

// flush the conversation context for a given session and dataset schema
export const checkContext = ({
  accessToken,
  sessionId,
}: {
  accessToken: string;
  sessionId: string;
}): Promise<AxiosResponse<CheckContextResponse>> =>
  axios.get(nl2codeEndpoints.checkContext, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { sessionId },
  });

export const executeNLQuery = ({
  accessToken,
  question,
  sessionId,
  datasets,
  utteranceTimeout,
  utteranceMetadata = {},
  taskId = '',
}: {
  accessToken: string;
  question: string;
  sessionId: string;
  datasets: { name: string; version: number }[];
  utteranceTimeout: number;
  utteranceMetadata?: { node_id?: string };
  taskId?: string;
}) =>
  axios.post(
    endpoints.executeNLQuery,
    {
      question,
      datasets,
      utteranceMetadata,
      taskId,
      sessionId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
      },
      params: {
        session: sessionId,
        sessionId,
        utterance_timeout: utteranceTimeout,
        ...(taskId ? { taskId } : {}), // send taskId to management service if it exists
      },
    },
  );
