import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import useTheme from '@mui/material/styles/useTheme';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HOME_OBJECTS, homeObjectIcon } from '../../constants/home_screen';
import {
  closeDatasetCreator,
  loadUploadedDatasets,
} from '../../store/reducers/datasetCreator.reducer';
import { selectDatasetCreatorIsOpen, selectUploadedDatasets } from '../../store/typedSelectors';
import { isFileUploadEnabled } from '../../utils/env';
import DatabaseBrowserContent from '../DatabaseBrowser/Content/DatabaseBrowserContent';
import Modal from '../Modal/Modal';
import UploadButton from '../UploadFileManager/UploadButton';
import './DatasetCreator.scss';

const tabs = {
  ...(isFileUploadEnabled() && { [HOME_OBJECTS.DATAFILE]: { label: 'Upload' } }),
  [HOME_OBJECTS.CONNECTION]: { label: 'Connect' },
};

const DatasetCreator = () => {
  const dispatch = useDispatch();
  const close = () => dispatch(closeDatasetCreator());
  const isOpen = useSelector(selectDatasetCreatorIsOpen);
  const uploadedDatasets = useSelector(selectUploadedDatasets);
  const [currentTab, setCurrentTab] = useState(
    isFileUploadEnabled() ? HOME_OBJECTS.DATAFILE : HOME_OBJECTS.CONNECTION,
  );
  const theme = useTheme();

  const primaryProps = useMemo(() => {
    if (currentTab === HOME_OBJECTS.DATAFILE && uploadedDatasets.length > 0) {
      return {
        label: `Load ${uploadedDatasets.length} dataset${uploadedDatasets.length > 1 ? 's' : ''}`,
        onClick: () => dispatch(loadUploadedDatasets()),
      };
    }
    return undefined;
  }, [dispatch, currentTab, uploadedDatasets]);

  if (!isOpen) return null;
  return (
    <Modal
      primaryProps={primaryProps}
      closeProps={{ onClick: close }}
      title="New Dataset"
      whiteBackground
      maxWidth="xl"
      theme={theme}
      dialogSx={{
        height: '100%', // needed for consistent height across tabs; will be bounded by `Dialog`'s `maxHeight` of (100vh - 64px)
      }}
    >
      <Box className="DatasetCreator">
        <Box className="DatasetCreator-Header">
          <Tabs
            value={currentTab}
            onChange={(_, newTab: string) => setCurrentTab(newTab)}
            className="DatasetCreator-Header-Tabs"
          >
            {Object.entries(tabs).map(([objectType, { label }]) => (
              <Tab
                className="DatasetCreator-Header-Tabs-Tab"
                key={objectType}
                value={objectType}
                icon={homeObjectIcon({ objectType })}
                iconPosition="start"
                label={label}
                data-testid={`datasetcreator-tab-${objectType}`}
              />
            ))}
          </Tabs>
        </Box>
        <Box
          className="DatasetCreator-CurrentTab"
          data-testid="datasetcreator-currenttab"
          data-current-tab={currentTab}
        >
          {isFileUploadEnabled() && (
            <Box className="DatasetCreator-FileUpload">
              <UploadButton acceptedFileTypes={['.csv', '.xlsx']} />
            </Box>
          )}
          <DatabaseBrowserContent />
        </Box>
      </Box>
    </Modal>
  );
};

export default DatasetCreator;
