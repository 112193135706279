import type { AnyAction } from '@reduxjs/toolkit';
import type { AxiosResponse } from 'axios';
import { LOCATION_CHANGE } from 'redux-first-history';
import {
  call,
  delay,
  getContext,
  put,
  race,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'typed-redux-saga';
import type { ReduxSagaContext } from '../../configureStore';
import { CONTEXTS, SKILL_EVENTS } from '../../constants';
import { API_SERVICES } from '../../constants/api';
import { AVA_TOAST_ERRORS, QUESTION_FAILURE_TIMEOUT } from '../../constants/dataAssistant';
import { MessageSourceType, MessageTypes, NodeTypes } from '../../constants/nodes';
import { NavigationItemStatus, NavigationTabs } from '../../constants/session';
import { TOAST_BOTTOM_RIGHT, TOAST_ERROR, TOAST_LONG } from '../../constants/toast';
import { FEEDBACK_CONTEXT } from '../../constants/userFeedback';
import { TaskStatus } from '../../types/task.types';
import { handleAPIConnectionError } from '../../utils/errorHandling/errorHandlers.api';
import { handleHttpError } from '../../utils/errorHandling/handleHttpError';
import { getMessageObjectFromText } from '../../utils/nodes';
import { editAskAvaCacheRequest } from '../actions/askAva.action';
import { closeDialog } from '../actions/dialog.actions';
import { SKILL_QUESTION } from '../actions/messages.actions';
import { restartPollingMechanism } from '../actions/poll.actions';
import { addToast } from '../actions/toast.actions';
import {
  EDIT_USER_FEEDBACK_FAILURE,
  EDIT_USER_FEEDBACK_SUCCESS,
  GET_USER_FEEDBACK_FAILURE,
  GET_USER_FEEDBACK_SUCCESS,
  SUBMIT_USER_FEEDBACK_FAILURE,
  SUBMIT_USER_FEEDBACK_SUCCESS,
  editUserFeedbackRequest,
  getUserFeedbackRequest,
  submitUserFeedbackRequest,
} from '../actions/userFeedback.action';
import { selectContext } from '../selectors/context.selector';
import { selectActiveDatasets, selectDatasetById } from '../selectors/dataspace.selector';
import { selectsIsOnDataChatSessionPage } from '../selectors/router.selector';
import { selectSession } from '../selectors/session.selector';
import { selectTask } from '../selectors/task.selector';
import {
  setCurrentChart,
  updateChartFailure,
  updateChartRequest,
  updateChartSuccess,
} from '../slices/chartspace.slice';
import {
  type AddToChartSpaceRequestPayload,
  type AddToDataSpaceRequestPayload,
  type AskQuestionRequestPayload,
  type DataAssistantFeedback,
  type EditAskFeedbackRequestPayload,
  type SendAskMessageRequestPayload,
  type SubmitAskFeedbackRequestPayload,
  addToChartSpaceFailure,
  addToChartSpaceRequest,
  addToChartSpaceSuccess,
  addToDataSpaceFailure,
  addToDataSpaceRequest,
  addToDataSpaceSuccess,
  askQuestionFailure,
  askQuestionRequest,
  askQuestionSuccess,
  checkContextFailure,
  checkContextRequest,
  checkContextSuccess,
  clearContextFailure,
  clearContextRequest,
  clearContextSuccess,
  editAskFeedbackFailure,
  editAskFeedbackRequest,
  editAskFeedbackSuccess,
  getAskFeedbackFailure,
  getAskFeedbackRequest,
  getAskFeedbackSuccess,
  queueQuestion,
  sendAskMessageRequest,
  submitAskFeedbackFailure,
  submitAskFeedbackRequest,
  submitAskFeedbackSuccess,
} from '../slices/dataAssistant.slice';
import {
  setCurrentDatasetFailure,
  setCurrentDatasetRequest,
  setCurrentDatasetSuccess,
} from '../slices/dataspace.slice';
import {
  insertMessagesFailure,
  insertMessagesRequest,
  insertMessagesSuccess,
} from '../slices/nodes.slice';
import { setCurrentNavigationTab } from '../slices/session.slice';
import { cancelCurrentTask, gotActiveTasks } from '../slices/task.slice';
import { sendMessageWorker } from './messages.saga';
import { selectAccessToken, selectUserConfig } from './selectors';
import { cancelCurrentTaskWorker, createTask } from './task.saga';
import { createAlertChannel } from './utils/alert-channels';
import { setRestingContext } from './utils/context';
import { callAPIWithRetry } from './utils/retry';

/**
 * Helper worker that adds an error toast to the toast queue.
 * Intended for lower-level errors (i.e. errors that don't affect the main functionality of Ava).
 * @param {string} message - The message to display in the toast.
 */
export function* addAvaErrorToast(message: string) {
  yield put(
    addToast({
      toastType: TOAST_ERROR,
      length: TOAST_LONG,
      message,
      position: TOAST_BOTTOM_RIGHT,
    }),
  );
}

export function* checkContextRequestWorker() {
  try {
    const accessToken = yield* select(selectAccessToken);
    const sessionId = yield* select(selectSession);
    if (!sessionId) throw new Error('No session id');

    const dataAssistantService = (yield* getContext(
      API_SERVICES.DATA_ASSISTANT,
    )) as ReduxSagaContext[API_SERVICES.DATA_ASSISTANT];
    const response = yield* callAPIWithRetry({
      apiFn: dataAssistantService.checkContext,
      args: [
        {
          accessToken,
          sessionId,
        },
      ],
    });
    yield* put(checkContextSuccess({ exists: response.data.context_exists }));
  } catch (error) {
    if (error instanceof Error) yield* put(checkContextFailure({ error }));
  }
}

export function* clearContextRequestWorker() {
  try {
    const accessToken = yield* select(selectAccessToken);
    const sessionId = yield* select(selectSession);
    if (!sessionId) throw new Error('No session id');

    const dataAssistantService = (yield* getContext(
      API_SERVICES.DATA_ASSISTANT,
    )) as ReduxSagaContext[API_SERVICES.DATA_ASSISTANT];
    yield* call(dataAssistantService.sweepContext, {
      accessToken,
      sessionId,
    });
    // add a topic event to the message store (to be displayed in data assistant)
    yield* put(
      insertMessagesRequest([
        {
          type: NodeTypes.TopicEvent,
          message: {
            src_type: MessageSourceType.Client,
            type: MessageTypes.Text,
            data: 'New topic started',
            additional_info: { timestamp: Date.now() },
            skill_event: SKILL_EVENTS.DONE,
          },
        },
      ]),
    );
    const { failure } = yield* race({
      _: take(insertMessagesSuccess),
      failure: take(insertMessagesFailure),
    });
    if (failure) throw new Error('Failed to start new topic.');
    yield* put(clearContextSuccess());
    yield* put(restartPollingMechanism());
  } catch (error) {
    yield* put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: 'Error clearing the current topic.',
      }),
    );
    if (error instanceof Error) yield* put(clearContextFailure({ error }));
  }
}

/**
 * Logic which promotes a dataset to the dataspace
 * 1. We update the node with the new dataset id (promotedId)
 * 2. add a SessionEvent node with the message that the dataset was added to the dataspace
 * 3. finally, we refresh the nodes from the nodeId provided
 *    (this is to ensure the FE is up to date with the BE)
 */
export function* addToDataSpaceRequestWorker({
  payload,
}: {
  payload: AddToDataSpaceRequestPayload;
}) {
  try {
    const errorMessage = 'Failed to add dataset to dataspace';
    const { dcDatasetId } = payload;

    // throw an error if the dcDatasetId or nodeId is not provided
    if (!dcDatasetId) throw new Error(errorMessage);

    // get the dataset from the store based on the id
    const dataset = yield* select(selectDatasetById, dcDatasetId);

    // throw an error if the dataset is not found
    if (!dataset) throw new Error(errorMessage);

    // set the dataset as current - this will also make it active in the dataspace
    yield* put(setCurrentDatasetRequest({ dcDatasetId }));
    const { failure } = yield* race({
      success: take(setCurrentDatasetSuccess),
      failure: take(setCurrentDatasetFailure),
    });
    if (failure) throw new Error(errorMessage);

    yield* put(addToDataSpaceSuccess());
  } catch (error) {
    let errMsg = 'Failed to add dataset to dataspace';
    if (error instanceof Error) {
      yield* put(addToDataSpaceFailure({ error }));
      errMsg += `: ${error.message}`;
    }
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: errMsg,
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
  }
}

export function* showMessageDialogHelper(message: string) {
  // parse the text and skill buttons from the message
  const { text, skillButtons } = yield* call(getMessageObjectFromText, message);

  // create the alert channel with the text and skill buttons
  const alertChannel = yield* call(createAlertChannel, {
    title: '',
    descriptions: [text],
    buttons: skillButtons,
    id: 'skill-question-dialog',
    dialogType: 'skill-question-dialog',
  });

  // wait for the user to click a button or navigate away
  const { userAction } = yield* race({
    navigate: take(LOCATION_CHANGE),
    userAction: take(alertChannel),
  });

  if (userAction)
    // send the answer bypassing FE message queue since we're in a working state
    yield* call(sendMessageWorker, { message: { data: userAction as string }, muted: true });

  // close the dialog after the user has clicked a button or navigated away
  yield* put(closeDialog());
}

export function* showSkillQuestionDialogWorker(action: AnyAction) {
  const isDataChatSession = yield* select(selectsIsOnDataChatSessionPage);
  // if we are not on the data chat session page, we don't want to show the dialog
  if (!isDataChatSession) return;
  const { data } = action;
  yield* call(showMessageDialogHelper, data);
}

export function* addToChartSpaceRequestWorker({
  payload,
}: {
  payload: AddToChartSpaceRequestPayload;
}) {
  const errorMessage = 'Failed to add chart to chartspace';
  try {
    // validate the payload
    const { dcChartId } = payload;
    if (!dcChartId) throw new Error(errorMessage);

    // request to update this chart's status to active
    yield* put(
      updateChartRequest({
        dcChartID: dcChartId,
        status: NavigationItemStatus.ACTIVE,
      }),
    );
    const { success } = yield* race({
      success: take(updateChartSuccess),
      failure: take(updateChartFailure),
    });
    if (!success) throw new Error(errorMessage);

    // set the updated chart as current
    yield* put(setCurrentChart({ dcChartId: success.payload.id }));

    // navigate to the chart space tab
    const sessionId = yield* select(selectSession);
    yield* put(setCurrentNavigationTab({ sessionId, tab: NavigationTabs.CHART_SPACE }));

    yield* put(addToChartSpaceSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(addToChartSpaceFailure({ error }));
    }
  }
}

export function* queueQuestionWorker({ payload: question }: { payload: string }) {
  try {
    const sessionID = yield* select(selectSession);
    if (!sessionID) throw new Error('No session id');
    yield* put(askQuestionRequest({ question }));
  } catch (error) {
    yield call(setRestingContext);
    const errorMessage =
      'DataChat was unable to complete the request. Refresh the page and try again.';
    yield* put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: errorMessage,
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
  }
}

// ask specific feedback get request
export function* getAskFeedbackRequestWorker({
  payload: { sessionId },
}: {
  payload: { sessionId: string };
}) {
  try {
    // call the generic get user feedback request
    // TODO: Rely only on vector id for feedback records.
    yield* put(getUserFeedbackRequest({ sessionId }));
    // wait for success or failure
    const { success } = yield* race({
      success: take(GET_USER_FEEDBACK_SUCCESS),
      failure: take(GET_USER_FEEDBACK_FAILURE),
    });

    if (!success) {
      throw new Error('Failed to get user feedback');
    }

    const successAction = success as AnyAction;
    const rawFeedbackList = successAction.feedbackList as Omit<DataAssistantFeedback, 'vectorId'>[];

    // Add the vectorId to the feedback records.
    const feedbackList = rawFeedbackList.map((feedback) => ({
      ...feedback,
      vectorId: feedback.content.vectorId,
    })) as DataAssistantFeedback[];

    yield* put(getAskFeedbackSuccess({ feedbackList }));
  } catch (error) {
    // fails silently, because feedback can still be given without displaying previous feedback
    if (error instanceof Error) {
      yield* put(getAskFeedbackFailure({ error }));
    } else {
      yield* put(getAskFeedbackFailure({ error: new Error('An unknown error occurred') }));
    }
  }
}

// ask specific feedback submit request
export function* submitAskFeedbackRequestWorker({
  payload: { vectorId, vote, metadata },
}: {
  payload: SubmitAskFeedbackRequestPayload;
}) {
  try {
    const question = metadata?.question;
    const recipe = metadata?.ask_recipe;
    const sessionId = yield* select(selectSession);
    // Call the generic submit user feedback request.
    // TODO: Rely only on vector db for feedback records.
    yield* put(
      submitUserFeedbackRequest({
        vote,
        context: FEEDBACK_CONTEXT.ASK_BUSINESS,
        content: { question, recipe, vectorId },
        sessionId,
      }),
    );

    // Wait for success or failure.
    const { success, failure } = yield* race({
      success: take(SUBMIT_USER_FEEDBACK_SUCCESS),
      failure: take(SUBMIT_USER_FEEDBACK_FAILURE),
    });

    if (failure) {
      throw new Error('Failed to submit user feedback');
    }

    const successAction = success as AnyAction;
    const rawFeedbackRecord = successAction.feedbackRecord as Omit<
      DataAssistantFeedback,
      'vectorId'
    >;

    // If vector id is present, update the vector db record (note: older answer messages do not contain vector id).
    if (vectorId) {
      yield* put(
        editAskAvaCacheRequest({
          vectorId,
          metadata: { vote },
        }),
      );
    }

    // Add the vectorId to the feedback record.
    const feedbackRecord = {
      ...rawFeedbackRecord,
      vectorId: rawFeedbackRecord.content.vectorId,
    } as DataAssistantFeedback;

    yield* put(submitAskFeedbackSuccess({ feedbackRecord }));
  } catch (error) {
    yield* call(addAvaErrorToast, AVA_TOAST_ERRORS.SUBMIT_FEEDBACK);
    if (error instanceof Error) {
      yield* put(submitAskFeedbackFailure({ error }));
    } else {
      yield* put(submitAskFeedbackFailure({ error: new Error('An unknown error occurred') }));
    }
  }
}

// ask specific feedback edit request
export function* editAskFeedbackRequestWorker({
  payload: { feedbackRecord, vectorId, vote },
}: {
  payload: EditAskFeedbackRequestPayload;
}) {
  try {
    // Call the generic edit user feedback request.
    // TODO: Rely only on vector db for feedback records.
    yield* put(editUserFeedbackRequest({ feedbackId: feedbackRecord.id, vote }));
    // wait for success or failure
    const { success, failure } = yield* race({
      success: take(EDIT_USER_FEEDBACK_SUCCESS),
      failure: take(EDIT_USER_FEEDBACK_FAILURE),
    });
    if (failure) {
      throw new Error('Failed to edit user feedback');
    }

    const successAction = success as AnyAction;
    const rawFeedbackRecord = successAction.feedbackRecord as Omit<
      DataAssistantFeedback,
      'vectorId'
    >;

    // If vector id is present, update the vector db record (note: older answer messages do not contain vector id).
    if (vectorId) {
      yield* put(
        editAskAvaCacheRequest({
          vectorId,
          metadata: { vote },
        }),
      );
    }
    // Add the vectorId to the feedback record.
    const editedFeedbackRecord = {
      ...rawFeedbackRecord,
      vectorId: rawFeedbackRecord.content.vectorId,
    } as DataAssistantFeedback;
    yield* put(editAskFeedbackSuccess({ feedbackRecord: editedFeedbackRecord }));
  } catch (error) {
    yield* call(addAvaErrorToast, AVA_TOAST_ERRORS.UPDATE_FEEDBACK);
    if (error instanceof Error) {
      yield* put(editAskFeedbackFailure({ error }));
    } else {
      yield* put(editAskFeedbackFailure({ error: new Error('An unknown error occurred') }));
    }
  }
}

// this worker executes the scheduling workflow
export function* askQuestionRequestWorker({
  payload: { question },
}: {
  payload: AskQuestionRequestPayload;
}) {
  try {
    const context = yield* select(selectContext);
    if (context !== CONTEXTS.REST && context !== CONTEXTS.PRE_TASK) return;

    // Create a new task
    const { success: createTaskSuccess, newTaskId } = yield call(
      createTask,
      `Question: ${question}`,
    );
    if (!createTaskSuccess) throw new Error('Failed to create task');

    // Use the active datasets
    const activeDatasets = yield* select(selectActiveDatasets);
    const datasets = activeDatasets.map((dataset) => ({
      name: dataset.name,
      version: dataset.version,
    }));

    // 2. At this point, the users request has been approved
    // Submit the user's question to the generate code endpoint
    yield* put(sendAskMessageRequest({ question, datasets, taskId: newTaskId }));
  } catch (error) {
    yield call(setRestingContext);
    if (error instanceof Error) {
      yield* put(askQuestionFailure({ error }));
    } else {
      yield* put(askQuestionFailure({ error: new Error('An unknown error occurred') }));
    }
  }
}

/** Insert nlq nodes for the message and response */
export function* insertAskNodes(question: string) {
  yield* put(
    insertMessagesRequest([
      {
        type: NodeTypes.NLQ,
        message: {
          type: MessageTypes.Text,
          data: question,
          display: question,
          src_type: MessageSourceType.Client,
        },
      },
      { type: NodeTypes.NLQResponse },
    ]),
  );

  const { success, failure } = yield* race({
    success: take(insertMessagesSuccess),
    failure: take(insertMessagesFailure),
  });

  if (failure) {
    yield put(cancelCurrentTask());
    throw failure.payload.error;
  }

  return success?.payload;
}

/** Call the execution endpoint */
export function* executeAskMessage(
  question: string,
  datasets: { name: string; version: number }[],
  taskId: string,
  utteranceMetadata?: { node_id?: string },
): Generator<unknown, void, AxiosResponse> {
  const accessToken = yield* select(selectAccessToken);
  const userConfig = (yield* select(selectUserConfig)) as { utteranceTimeout: number };
  const sessionId = yield* select(selectSession);
  if (!sessionId) throw new Error('No session id');

  const dataAssistantService = (yield* getContext(
    API_SERVICES.DATA_ASSISTANT,
  )) as ReduxSagaContext[API_SERVICES.DATA_ASSISTANT];
  try {
    yield* call(dataAssistantService.executeNLQuery, {
      accessToken,
      question,
      sessionId,
      datasets,
      utteranceTimeout: userConfig.utteranceTimeout,
      utteranceMetadata,
      taskId,
    });
  } catch (error) {
    // If not a connection error, will rethrow
    handleAPIConnectionError(error);

    // This connection does not need to stay open if we already started processing
    // the question. Therefore we can ignore this failure if the question has
    // already started processing. Polling will continue to get the result.

    // First get active tasks, which uses existing polling, but we will
    // timeout if we don't get a response in time
    const { timedOut } = yield* race({
      timedOut: delay(QUESTION_FAILURE_TIMEOUT),
      success: take(gotActiveTasks.type),
    });
    if (timedOut) throw error;

    // Verify that the task for this question has started/is done. If it hasn't,
    // this indicates a larger problem than just a connection error.
    const task = yield* select(selectTask, taskId);
    if (task?.taskStatus === TaskStatus.NEW) {
      // TODO: Could potentially retry instead of rethrowing
      throw error;
    }
  }
}

/**
 * This saga sends the user's question to an Ask endpoint for execution.
 * We will restart polling after successfully sending the question.
 *
 * @param question - The user's question.
 * @param datasets - The datasets to be used for the question.
 * @param taskId - The task ID for tracking the request.
 */
export function* sendAskMessageRequestWorker({
  payload: { question, datasets, taskId },
}: {
  payload: SendAskMessageRequestPayload;
}): Generator<unknown, void, any> {
  try {
    // 1. Insert nodes to the message store for the user's question & response
    const payload = yield call(insertAskNodes, question);

    /**
     * Set the node_id of the last-inserted node to the utteranceMetadata.
     * This will tell the worker which node it should write messages to
     */
    const utteranceMetadata = payload?.length ? { node_id: payload[payload.length - 1] } : {};

    // 2. Execute the user's question

    yield call(executeAskMessage, question, datasets, taskId, utteranceMetadata);
    // 3. Signal success
    yield* put(askQuestionSuccess());
  } catch (error) {
    try {
      yield* handleHttpError(error);
    } finally {
      // cancel the current task
      yield* call(cancelCurrentTaskWorker);
    }

    // Rethrow the error to be caught by the parent saga
    throw error;
  }
}

export default function* dataAssistantSaga() {
  yield* takeLatest(checkContextRequest, checkContextRequestWorker);
  yield* takeLatest(clearContextRequest, clearContextRequestWorker);
  yield* takeLatest(addToDataSpaceRequest, addToDataSpaceRequestWorker);
  yield* takeLatest(addToChartSpaceRequest, addToChartSpaceRequestWorker);
  yield* takeLatest(SKILL_QUESTION, showSkillQuestionDialogWorker);
  yield* takeLeading(queueQuestion, queueQuestionWorker);
  yield* takeLatest(getAskFeedbackRequest, getAskFeedbackRequestWorker);
  yield* takeLatest(submitAskFeedbackRequest, submitAskFeedbackRequestWorker);
  yield* takeLatest(editAskFeedbackRequest, editAskFeedbackRequestWorker);
  yield* takeLeading(sendAskMessageRequest, sendAskMessageRequestWorker);
}
